import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomainFrameworkService } from 'app/shared/domain-framework.service';

@Component({
  selector: 'cygov-domain-frameworks-modal',
  templateUrl: './domain-frameworks-modal.component.html',
  styleUrls: ['./domain-frameworks-modal.component.scss'],
})
export class DomainFrameworksModalComponent implements OnInit {
  entity: any;
  @Output() modalResult = new EventEmitter<boolean>();
  standardList: Array<any> = [];
  saving: boolean = false;
  riskFrameWorkOpt = [];
  domainId: string;
  frameworksData: any;
  isLoading = true;

  constructor(private toastr: ToastrService, private domainFrameworkService: DomainFrameworkService) {}

  ngOnInit(): void {
    this.frameworksData = { ...this.domainFrameworkService.frameworksData };
    this.domainId = this.domainFrameworkService.domainId;

    this.applyFrameworkSettings();
    this.isLoading = false;
  }

  applyFrameworkSettings(): void {
    const frameworkSettings = this.domainFrameworkService.domainSettingsData;

    // risk
    this.riskFrameWorkOpt = this.domainRestrictedFrameworks([...frameworkSettings.RISK_FRAMEWORKS]);

    // compliance
    this.standardList = [...frameworkSettings.COMPLIANCE_FRAMEWORKS];
  }

  async saveFrameworkSettings(): Promise<void> {
    this.saving = true;
    this.toastr.info('Saving Settings...');

    const updatedSettings = {
      RISK_FRAMEWORKS: this.riskFrameWorkOpt,
      COMPLIANCE_FRAMEWORKS: this.standardList,
    };

    const needToUpdate = true;
    if (needToUpdate) {
      this.domainFrameworkService.getSettingFromDomain = await this.domainFrameworkService.updateDomainSettings(
        this.domainId,
        updatedSettings
      );
      this.modalResult.emit(true);

      this.toastr.success('Framework settings saved successfully');
    } else {
      this.toastr.info('No Change detected');
      this.modalResult.emit(true);
    }
    this.saving = false;
  }

  domainRestrictedFrameworks(riskFrameWorkArr) {
    return riskFrameWorkArr.filter(framework => {
      const frameworkName = framework.name
        ? framework.name.toLowerCase()
        : this.domainFrameworkService.RiskFrameworkEnum[framework.key];
      return !this.domainFrameworkService.restrictedFrameworks.includes(frameworkName);
    });
  }

  findChangedFrameworks(existingSettings): Array<any> {
    const settings = JSON.parse(existingSettings.settings);
    // use this variable in belows filters
    let prev;
    // check if current framework exist or not
    const checkFrameworkExist = current => {
      // old data which contain underscore
      if (current.key && current.key.includes('_')) {
        return prev.key?.toLowerCase() === current.key?.toLowerCase();
      } else {
        // new data which contain space
        return prev.name?.toLowerCase() === current.key?.toLowerCase();
      }
    };
    // check current framework has been changed or not
    const checkFrameworkUpdate = current => {
      // old data which contain underscore
      if (current.key && current.key.includes('_')) {
        return prev.key?.toLowerCase() === current.key?.toLowerCase() && prev.checked !== current.status;
      } else {
        // new data which contain space
        return prev.name?.toLowerCase() === current.key?.toLowerCase() && prev.checked !== current.status;
      }
    };

    const changedCompliance = [
      ...this.standardList.filter(previous => {
        prev = previous;
        const frameworkNotExist = !settings.COMPLIANCE_FRAMEWORKS.find(checkFrameworkExist);
        // no current framework not exist in frameworkSetting, then we have to add it
        if (frameworkNotExist) {
          return true;
        } else {
          return settings.COMPLIANCE_FRAMEWORKS.find(checkFrameworkUpdate);
        }
      }),
    ];

    const changedRisks = [
      ...this.riskFrameWorkOpt.filter(previous => {
        prev = previous;
        const frameworkNotExist = !settings.RISK_FRAMEWORKS.find(checkFrameworkExist);
        // no current framework not exist in frameworkSetting, then we have to add it
        if (frameworkNotExist) {
          return true;
        } else {
          return settings.RISK_FRAMEWORKS.find(checkFrameworkUpdate);
        }
      }),
    ];

    return [...changedRisks, ...changedCompliance];
  }
}
