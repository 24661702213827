<div class="framework-settings-modal">
    <div class='abs-centre frameworkLoading' *ngIf="isLoading">
        <div *cygovLoader="isLoading"></div>
    </div>
    <ng-container *ngIf="!isLoading">
        <div class="upper-part">
            <div class="lato-16-n-vw uppercase header-name heading">
                Domain Framework settings
            </div>
            <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'"
                (click)="modalResult.emit(false);">
            </div>
        </div>
        <div class="middle-part">
            <div class="text-container certification w-100 align-in-row">

                <span class="key color-blue full-width mt-1">Risk Frameworks: </span>
                <div class="toggle-container align-in-row-component"
                    *ngFor="let riskFramework of riskFrameWorkOpt; let ir = index">
                    <div class="toggle-text">
                        <span class="value">{{ riskFramework.name }}</span>
                    </div>

                    <div class="toggle-btn-container">
                        <cygov-toggle-button [isChecked]="riskFramework.status"
                            (changed)="riskFramework.status = $event" [id]="'risk'+ir">
                        </cygov-toggle-button>
                    </div>
                </div>

                <span class="key color-blue full-width mt-3">Compliance : </span>
                <div class="toggle-container align-in-row-component"
                    *ngFor="let standards of standardList; let ic = index">
                    <div class="toggle-text">
                        <span class="value">{{ standards.name }}</span>
                    </div>

                    <div class="toggle-btn-container">
                        <cygov-toggle-button [isChecked]="standards.status"
                            (changed)="standards.status = $event" [id]="'compliance'+ic">
                        </cygov-toggle-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="end-part">
            <cygov-button class="save-settings-btn" [buttonText]="'SAVE'" [buttonWidth]="'5.2vw'"
                [buttonHeight]="'3.22vh'" [buttonColor]="'#29A9EB'" [hoverColor]="'#0B76B9'"
                (click)="saveFrameworkSettings()">
            </cygov-button>
        </div>
    </ng-container>
</div>