export enum ClientSubMenuEnum {
  CLIENTS = 'clients',
  ENTERPRISE = 'enterprise',
  DIVISION = 'division',
  REGION = 'region',
  BREAKDOWN = 'breakdown',
  USERSETTINGS = 'user-settings',
}

export enum ClientsSubMenuPriority {
  CLIENTS = 0,
  ENTERPRISE = 1,
  DIVISION = 2,
  REGION = 3,
  BREAKDOWN = 4,
}

export enum ClientsScreenTitles {
  ENTERPRISE = 'ENTERPRISE',
  DIVISION = 'DIVISION SUMMARY',
  REGION = 'REGION SUMMARY',
  BREAKDOWN = 'REGION BREAKDOWN',
}
export enum WizardNumStringStepsEnum {
  ORGANIZATIONAL = 1,
  FINANCIAL = 2,
  FRAMEWORKS = 3,
  DATA_SCOPE = 4,
  TECHNICAL = 5,
  RISK_SCENARIOS = 6,
  ACCOUNT_DETAILS = 7,
  CURRENT_YEAR = 8,
}

export enum ClientWizardNumStringStepsEnum {
  DATA_SCOPE = 1,
  APPLICANT_INFO = 2,
  REVENUE_DETAILS = 3,
  DATA_INVENTORY = 4,
}
export enum MidMarketWizardNumStringStepsEnum {
  ORGANIZATIONAL = 1,
  ACCOUNT_DETAILS = 2,
  DATA_SCOPE = 3,
}

export enum MidMarketWizardStringStepsEnum {
  ORGANIZATIONAL = 'Organizational',
  FINANCIAL = 'Financial',
  FRAMEWORKS = 'Frameworks',
  DATA_SCOPE = 'Data Scope',
  TECHNICAL = 'Technical',
  RISK_SCENARIOS = 'Risk Scenarios',
  ACCOUNT_DETAILS = 'Account Details',
  CURRENT_YEAR = 'Current/Previous Year',
}

export enum WizardStringStepsEnum {
  ORGANIZATIONAL = 'Organizational',
  FINANCIAL = 'Financial',
  FRAMEWORKS = 'Frameworks',
  DATA_SCOPE = 'Data Scope',
  TECHNICAL = 'Technical',
  RISK_SCENARIOS = 'Risk Scenarios',
  ACCOUNT_DETAILS = 'Account Details',
  CURRENT_YEAR = 'Current/Previous Year',
  APPLICANT_INFO = 'Applicant Info',
  REVENUE_DETAILS = 'Revenue Details',
  DATA_INVENTORY = 'Data Inventory',
}

export enum NistBBRiskTitleEnum {
  BB_RISK_1 = 'BB Risk 1',
  BB_RISK_2 = 'BB Risk 2',
  BB_RISK_3 = 'BB Risk 3',
}

export enum IndustryEnumBeecher {
  UTILITIES = 'UTILITIES',
  CONSTRUCTION = 'CONSTRUCTION',
  MANUFACTURING = 'MANUFACTURING',
  WHOLESALE = 'WHOLESALE',
  RETAIL = 'RETAIL',
  FINANCIAL_SERVICES = 'FINANCIAL SERVICES',
  REAL_ESTATE = 'REAL ESTATE',
  EDUCATION = 'EDUCATION',
  HEALTHCARE = 'HEALTHCARE',
  HOSPITALITY_RESTAURANT_ENTERTAINMENT = 'HOSPITALITY/RESTAURANT/ENTERTAINMENT',
  AGRI_CONST_TRANSPORT_WAREHOUSING = 'AGRI/CONST/TRANSPORT/WAREHOUSING',
  OTHER_SERVICES = 'OTHER SERVICES',
  PUBLIC_ADMIN = 'PUBLIC ADMIN',
  PUBLISHING = 'PUBLISHING',
  TELECOM = 'TELECOM',
  IT = 'IT',
  LEGAL_SERVICES = 'LEGAL SERVICES',
  OTHER_PROFESSIONAL_AND_TECHNICAL_SERVICES = 'OTHER PROFESSIONAL AND TECHNICAL SERVICES',
  OTHER_BUSINESS_SUPPORT_ADMIN = 'OTHER BUSINESS SUPPORT ADMIN',
  COLLECTION_AGENCIES = 'COLLECTION AGENCIES',
  CREDIT_BUREAUS = 'CREDIT BUREAUS',
}
export enum IndustryEnum {
  FINANCE = 'FINANCE',
  HEALTHCARE = 'HEALTHCARE',
  EDUCATION = 'EDUCATION',
  GOVERNMENT = 'GOVERNMENT',
  RETAIL = 'RETAIL',
  ENERGY = 'ENERGY',
}

export enum MidMarketEnum {
  MIDMARKETAPPLICATION = 'MID_MARKET_APPLICATION',
  MIDMARKETBASIC = 'MID_MARKET_BASIC',
  MIDMARKETBASICOT = 'MID_MARKET_BASIC_OT',
  MIDMARKETBASICTECH = 'MID_MARKET_BASIC_TECH',
}
