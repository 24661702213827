<div class="support">
  <div class="support-icon-wrapper">
    <div class="support-icon" cygovSvgIcon [svgName]="'support-icon'"
      (click)="openModal(supportModal)">
    </div>

  </div>

  <ng-template #supportModal>
    <div class="modal-body" [ngClass]="{'confirmation-body':confirmationModal}">
      <div class="modal-header" [ngClass]="{'confirmation-header':confirmationModal}">
        <div *ngIf="!confirmationModal" class="support-icon-title-container">
          <div class="support-icon" cygovSvgIcon [svgName]="'support-icon'">
          </div>&nbsp;
          <div class='support-title-text d-flex align-items-center'>
            <span class="lato-24-n-vw message">SUPPORT TICKET</span>
          </div>
        </div>
        <div class="close-icon" cygovSvgIcon [svgName]="'x-icon-type2'" (click)="closeModal()">
        </div>
      </div>
      <div class="support-confirmation-body" *ngIf="confirmationModal ; else inputFields">

        <div class="support-icon" cygovSvgIcon [svgName]="'support-ticket-sent-icon'"></div>
        <div class="confirmation-message">
          <div class="lato-22-n-vw txt-blue"> We appreciate your patience</div>
          <div class="lato-18-n-vw">Our team will get back to you as soon as possible via email
          </div>
        </div>
      </div>
      <ng-template #inputFields>
        <div class="input-box">
          <label class="lato-18-n-vw capitalize input-label" for="name">Name
            <span class="required-asterisk">*</span>
          </label>
          <input type="text" class="form-control input-field" aria-label="Name"
            [(ngModel)]="newTicket.name" id="name" placeholder="Name" spellcheck="true" />
        </div>
        <div class="input-box">
          <label class="lato-18-n-vw capitalize input-label" for="email">Email
            <span class="required-asterisk">*</span>
          </label>
          <input type="text" class="form-control input-field" aria-label="Email"
            [(ngModel)]="newTicket.email" id="email" placeholder="Email" spellcheck="true" />
        </div>
        <div class="input-box">
          <label class="lato-18-n-vw capitalize input-label mb0"
            for="description">Description</label>
          <textarea class="form-control input-field " aria-label="Description" id="description"
            placeholder="Please describe the issue you have encountered. Please include the previous steps that were taken prior to encountering this issue (this will help our team identify your issue faster)."
            [(ngModel)]="newTicket.description" spellcheck="true"></textarea>
        </div>
        <div class="attachment-container">
          <div class="lato-14-n-vw text">
            Please attach a screenshot and other artifacts that can help us understand your issue
          </div>
          <div class="icons-section">
            <cygov-existing-files class="abs-centre custom-sizing" [iconColor]="'#29A9EB'"
              [filesList]="uploadedFiles" [filePath]="filePath" [showDownload]="false"
              (updateDeletedFiles)="removeFileFromZip($event)"></cygov-existing-files>
            <div class="icon" cygovSvgIcon [svgName]="'upload_collection'" placement="top"
              ngbTooltip="Upload files" (click)="openUploadModal()"></div>
          </div>
        </div>
        <div class="seperator"></div>
      </ng-template>
      <div class="action-btns">
        <div *ngIf="confirmationModal ; else sendButtons">
          <button class="standard-btn" (click)="closeModal()">OK</button>
        </div>
        <ng-template #sendButtons>
          <button class="standard-btn" (click)="sendTicket()">Send</button>
          <button class="standard-btn-transparent" (click)="closeModal()">Cancel</button>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>