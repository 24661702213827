<div class="cygov-header" *cygovLoader="loading">
  <div class="left" [ngClass]="{'welcome-board': isWelcome}">
    <div class="logo-wrapper" (click)="goHomeByRole()">
      <div *ngIf="!isBnb" class="logo" cygovSvgIcon [svgName]="mainLogoName"></div>
      <img class="logo-img" *ngIf="isBnb" [src]="bnbLogoPath" alt="Brown and Brown" />
    </div>
  </div>

  <div class="right" [ngClass]="{'welcome-board': isWelcome}">
    <!-- <cygov-select *ngIf="isAdmin && isBoard" class="entity-dropdown" [standardSelect]="false"
      [isImageControl]="true" [items]="entityData" [placeholder]="'Select Root Entity Name'"
      [isTextRequired]="true" [(ngModelValue)]="selectedRootEntity" [clearable]="false"
      [searchable]="true" (ngModelValueChange)="setEntity()">
    </cygov-select> -->

    <div *ngIf="isWelcome" class="guide">
      <div class="colored-circle" cygovSvgIcon [svgName]="'colored-circle2'" placement="left">
      </div>
      <div class="colored-arrow" cygovSvgIcon [svgName]="'colored-arrow'" placement="left">
      </div>
      <div class="lato-18-n-vw text">
        Dedicated short video guide for every page you are on in the platform
      </div>
    </div>
    <cygov-walkthrough-videos [currentRoute]="currentRoute">
    </cygov-walkthrough-videos>

    <cygov-support [ngClass]="{'apply-opacity': isWelcome}"></cygov-support>

    <!-- <div class="megaphone-wrapper">
      <div class="megaphone" cygovSvgIcon [svgName]="'megaphone'"></div>
      <span class="lato-12-n num-count">{{notificationCount}}</span>
    </div> -->

    <div ngbDropdown class="menu-dropdown">
      <button class="btn-type-link user-menu" [ngClass]="{'apply-opacity': isWelcome}"
        id="dropdownConfig" ngbDropdownToggle>
        <cygov-username-icon [username]="user.name" [height]="(5).toString()"></cygov-username-icon>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownConfig">
        <button class="dropdown-item capitalize" ngbDropdownItem (click)="signOut()">
          Sign Out
        </button>
        <button class="dropdown-item capitalize" ngbDropdownItem *ngIf="isAdmin"
          (click)="navigateTo('admin/entity-list')">
          Entity List
        </button>
        <button class="dropdown-item capitalize" ngbDropdownItem *ngIf="isAdmin"
          (click)="openGeneratorModal()">
          Generator
        </button>
        <button class="dropdown-item capitalize" ngbDropdownItem *ngIf="isAdmin"
          (click)="navigateTo('binary-edge')">
          Binary Edge Services
        </button>
        <button class="dropdown-item capitalize" ngbDropdownItem *ngIf="isAdmin"
          (click)="openFrameworksSettings()">
          Framework Settings
        </button>
      </div>
    </div>
  </div>
</div>