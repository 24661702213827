import { ActivatedRoute } from '@angular/router';
import { EntityService } from 'app/shared/entity.service';
import { EnbdDomainPopupComponent } from 'app/third-party/enbd-domain-popup/enbd-domain-popup.component';
import { FrequencySelectboxEnum } from 'app/shared/enums/frequencyOptions';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbDateStruct, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { FileService } from 'app/shared/file.service';
import { UtilsService } from 'app/shared/utils.service';
import Papa from 'papaparse';
import { EnbdVendorConstant } from 'app/third-party/add-vendor/add-new-vendor-modal/enbd-vendor.constant';
import { FrameworkLevelFrontend } from 'app/shared/enums/frameworksLevels.enum';
import { ThirdPartyService } from 'app/third-party/third-party.service';

@Component({
  selector: 'cygov-framework-frequency-section',
  templateUrl: './framework-frequency-section.component.html',
  styleUrls: ['./framework-frequency-section.component.scss'],
})
export class FrameworkFrequencySectionComponent implements OnInit {
  // STATIC DATA
  isENBD: boolean = UtilsService.isENBD;
  isInformatica: boolean = UtilsService.isInformatica;

  isEnbdVendorRisk: boolean = false;
  @Input() assessmentData: any;

  @Output() assessmentDataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() isBulkUpload: boolean = false;
  @Input() isFromDefaultSettings: boolean = false;
  @Input() entity: any;
  @Input() defaultSelectedDomains: string[] = [];

  riskList: any;
  currentClient = null;
  showAdvanceSettings: boolean = false;
  staticFrequencyCount: number;
  levelOptions = ['Full', 'High', 'Moderate', 'Light'];
  informaticaLevelOptions = ['Tier 1', 'Tier 2', 'Tier 3'];
  vendorCategories = [
    {
      name: 'Mandatory for all identified suppliers',
      numberOfQuestions: 0,
      isQualify: true,
      defaultSelected: true,
      id: 1,
    },
  ];

  enbdDomainList: any = [];

  artifactOptions = [
    {
      label: 'Suggested',
      value: 'suggested',
    },
    {
      label: 'Optional',
      value: 'optional',
    },
  ];
  commentOptions = [
    {
      label: 'Suggested',
      value: 'suggested',
    },
    {
      label: 'Mandatory',
      value: 'mandatory',
    },
    {
      label: 'Optional',
      value: 'optional',
    },
  ];
  frequencyOpt = [
    { name: 'Automatic Reminder', checked: false },
    { name: 'Automatic Re-assessment', checked: false },
  ];
  monthList = ['Annually', 'Bi-Annually', 'Quarterly'];

  timelineCheckBox = {
    AUTOMATIC_REMINDER: 'automatic reminder',
    AUTOMATIC_RE_ASSESSMENT: 'Automatic re-assessment',
  };
  // LOCAL MAIN VARIABLES
  selectedRiskIndex = 1;
  selectedFramework = null;
  selectedLevel = '';
  selectedArtOpt = '';

  // calendar options
  selectedStartDate: NgbDateStruct;
  selectedNextStartDateMin: NgbDateStruct;
  tempStartDate: NgbDateStruct;
  selectedEndDate: NgbDateStruct;
  endCalendar: NgbInputDatepicker;
  startCalendar: NgbInputDatepicker;
  startingDate: Date;
  deadlineDate: Date;
  DEADLINE_DATE = 'deadlineDate';
  ASSESSMENT_DATE = 'startingDate';
  showArtifactsToolTip: boolean = true;
  VendorRiskFrameworkEnum: any;
  modalRef = null;
  selectedDomains: any[] = [];
  requiredHeaders = ['category', 'subCategory', 'questionId'];
  parsedCSVData: any[];
  cachedDomain: any[] = [];
  allDomainList: any[] = [];
  isAdnoc: boolean = UtilsService.isAdnoc;
  maxFrequencyDays: number = 1826;
  assessmentObject: any;
  constructor(
    private fileService: FileService,
    private modalService: NgbModal,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private tpService: ThirdPartyService
  ) {
    this.VendorRiskFrameworkEnum = this.fileService.importFrameworkEnumsFromS3(
      FrameworkEnumTypes.VENDOR_RISK_FRAMEWORK_ENUM
    );
  }

  async ngOnInit(): Promise<void> {
    this.tpService?.previousAssessment$.subscribe((circle: any) => {
      this.assessmentObject = circle;
    });
    // if we don't open enbd popup it will pass default enbd domains to assessment data
    if (this.isENBD) {
      if (!this.entity) {
        const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
        this.entity = await this.entityService.getEntity(entityId);
      }
      // This code was showing those domains those are selected from default settings
      // but for ENBD everytime domain will be selected on basis of categories so commented this code

      // this.enbdDomainList = this.entity?.defaultSetting?.enbdDomain
      //   ? this.entity?.defaultSetting?.enbdDomain
      //   : this.enbdDomainList;

      this.enbdDomainList = this.defaultSelectedDomains;
      // this line is for bulk upload vendor and default settings
      // where we cannot filter domain on basis of categories
      // so we are showing only default selected domains
      this.enbdDomainList = this.enbdDomainList.length
        ? this.enbdDomainList
        : EnbdVendorConstant.categories[0].defaultDomains;

      // if we have added domains and we move across modal pages
      // don't let new selected domains got un-selected
      this.enbdDomainList = this.assessmentData?.enbdDomains?.length
        ? this.assessmentData.enbdDomains
        : this.enbdDomainList;

      this.defaultSelectedDomains = this.assessmentData?.enbdDomains?.length
        ? this.assessmentData.enbdDomains
        : this.defaultSelectedDomains;

      this.cachedDomain = this.enbdDomainList;

      this.enbdDomainList?.forEach(domain => {
        this.selectedDomains.push(domain?.name ? domain.name : domain);
      });
      // only store selected domains when we land on this page for the first time
      this.assessmentData.enbdDomains = this.assessmentData?.enbdDomains?.length
        ? this.assessmentData.enbdDomains
        : this.selectedDomains;
    }
    this.frequencyOpt.forEach(frequency => {
      frequency.checked =
        frequency.name.toLowerCase() === this.timelineCheckBox.AUTOMATIC_REMINDER.toLowerCase()
          ? this.assessmentData?.isAutoReminder
          : this.assessmentData?.isAutoReassessment;
    });
    this.showAdvanceSettings =
      this.assessmentData?.frequencyName?.toLowerCase() === FrequencySelectboxEnum.ADVANCE.toLowerCase();
    this.selectedFramework = this.VendorRiskFrameworkEnum.VENDOR_RISK;
    this.riskList = Object.values(this.VendorRiskFrameworkEnum).map(val => val);
    // this.filterRiskFrameworksByDomain();
    // getting the initial default dates
    const localStart = new Date();
    const localEnd = new Date();

    localEnd.setDate(localStart.getDate() + 1);
    this.selectedStartDate = this.assessmentData?.startDate
      ? this.assessmentData.startDate
      : UtilsService.getDateInNgbDateStructFormat(new Date());
    this.selectedEndDate = this.assessmentData?.endDate
      ? this.assessmentData.endDate
      : UtilsService.getDateInNgbDateStructFormat(localEnd);
    this.startingDate = JSON.parse(JSON.stringify(this.selectedStartDate));
    const dateObject = UtilsService.ngbDateStructToDate(this.selectedStartDate);
    const nextDateObject = new Date(dateObject);
    nextDateObject.setDate(dateObject.getDate() + 1);
    this.selectedNextStartDateMin = UtilsService.getDateInNgbDateStructFormat(nextDateObject);
    this.tempStartDate = JSON.parse(JSON.stringify(this.selectedStartDate));
    this.deadlineDate = JSON.parse(JSON.stringify(this.selectedEndDate));
    this.assessmentData.startDate = JSON.parse(JSON.stringify(this.selectedStartDate));
    this.assessmentData.endDate = JSON.parse(JSON.stringify(this.selectedEndDate));
    // below emit will be used to update the data in the main modal.
    this.assessmentDataChange.emit(this.assessmentData);

    if (this.isENBD) {
      this.isEnbdVendorRisk = this.assessmentData?.frameworkKey === 'VENDOR_RISK_ENBD';
      await this.changeFrameWork(this.assessmentData?.frameworkKey, true);
    }

    this.getRiskList();
  }

  getRiskList() {
    switch (true) {
      case UtilsService.isInformatica:
        this.currentClient = 'INFORMATICA';
        this.riskList = Object.keys(FrameworkLevelFrontend.INFORMATICA).map(fName => fName);
        break;

      case UtilsService.isHowden:
        this.currentClient = 'HOWDEN';
        this.riskList = Object.keys(FrameworkLevelFrontend.HOWDEN).map(fName => fName);
        break;

      case UtilsService.isITC:
        this.currentClient = 'ITC';
        this.riskList = Object.keys(FrameworkLevelFrontend.ITC).map(fName => fName);
        break;

      case UtilsService.isCRB:
        this.currentClient = 'CRB';
        this.riskList = Object.keys(FrameworkLevelFrontend.CRB).map(fName => fName);
        break;

      case UtilsService.isSSMHealth:
        this.currentClient = 'SSM_HEALTH';
        this.riskList = Object.keys(FrameworkLevelFrontend.SSM_HEALTH).map(fName => fName);
        break;

      case UtilsService.isENBD:
        this.currentClient = 'ENBD';
        this.riskList = Object.keys(FrameworkLevelFrontend.ENBD).map(fName => fName);
        break;

      case UtilsService.isAdnoc:
        this.currentClient = 'ADNOC';
        this.riskList = Object.keys(FrameworkLevelFrontend.ADNOC).map(fName => fName);
        break;

      case UtilsService.isMoelis:
        this.currentClient = 'MOELIS';
        this.riskList = Object.keys(FrameworkLevelFrontend.MOELIS).map(fName => fName);
        break;

      // case UtilsService.isDBU:
      //   this.currentClient = 'DBU';
      //   this.riskList = Object.keys(FrameworkLevelFrontend.DBU).map(fName => fName);
      //   break;

      default:
        this.currentClient = 'REGULAR_INSTANCES';
        this.riskList = Object.keys(FrameworkLevelFrontend.REGULAR_INSTANCES).map(fName => fName);
        break;
    }
    const commonFrameworks = Object.keys(FrameworkLevelFrontend.COMMON).map(fName => fName);
    this.riskList = [...this.riskList, ...commonFrameworks];
    // setting data on initial load
    const mergedLevel = { ...FrameworkLevelFrontend[this.currentClient], ...FrameworkLevelFrontend.COMMON };
    this.levelOptions = mergedLevel[this.riskList[0]];
    if (Object.entries(this.assessmentObject).length !== 0) {
      this.updateAssessmentData('framework', this.assessmentObject.frameworkName);
      this.updateAssessmentData('level', this.assessmentObject.level);
    } else {
      this.updateAssessmentData(
        'framework',
        this.riskList.length ? this.riskList[0] : this.assessmentData.frameworkName
      );
      this.updateAssessmentData('level', this.levelOptions.length ? this.levelOptions[0] : this.assessmentData.level);
    }
  }

  async changeFrameWork(frameworkKey: string = '', isFirstTime: boolean = false): Promise<void> {
    const frameworkFile = await this.getFrameworkFromS3(frameworkKey);
    this.parsedCSVData = this.parseCSV(frameworkFile);

    const domains = [];
    this.parsedCSVData?.forEach(data => {
      if (!domains.includes(data?.category)) {
        domains.push(data?.category);
      }
    });

    if (!isFirstTime && frameworkKey !== 'ENBD_VENDOR_RISK') {
      this.cachedDomain = domains;
      this.enbdDomainList = this.cachedDomain;

      this.enbdDomainList?.map(domain => {
        return domain?.toLowerCase();
      });
      this.cachedDomain = this.cachedDomain.map((str, index) => {
        return {
          name: str,
          id: index + 1,
          isQualify: this.enbdDomainList?.includes(str?.toLowerCase()) ? true : false,
          defaultSelected: this.enbdDomainList?.includes(str?.toLowerCase()) ? true : false,
        };
      });
    } else {
      this.enbdDomainList =
        this.isBulkUpload || this.isFromDefaultSettings ? this.enbdDomainList : this.defaultSelectedDomains;
    }

    this.allDomainList = domains;
    // this.cachedDomain = this.enbdDomainList; //check again
  }

  async getFrameworkFromS3(frameworkKey: string): Promise<any> {
    try {
      const filePath = `FRAMEWORKS/${frameworkKey}.csv`;
      return this.fileService.getTextFileFromS3(filePath, { download: true, level: 'public' });
    } catch (err) {
      console.log('ERR: getFrameworkFromS3 - :', err);
      return null;
    }
  }

  parseCSV(csv) {
    const parsedData = [];

    Papa.parse(csv, {
      header: true,
      step: (results, parser) => {
        if (results && results.errors && !!results.errors.length) {
          if (results.errors.find(e => e.code?.toLowerCase() === 'toofewfields')) {
            return false;
          }
        }

        if (results && results.data && !!Object.keys(results.data).length) {
          const headerKeys = Object.keys(results.data);
          const requiredHeadersExist = this.requiredHeaders.every(key =>
            headerKeys.some(k => key.toLowerCase() === k.toLowerCase())
          );
          if (requiredHeadersExist) {
            if (!Object.values(results.data).join('').trim().length) {
              return false;
            }
            parsedData.push({ ...(results.data as any) });
          } else {
            parser.abort();
          }
        }
      },
    });

    return parsedData;
  }

  openDomainPopUp(): void {
    const modalComponent = EnbdDomainPopupComponent;
    this.modalRef = this.modalService.open(modalComponent, {
      centered: true,
      size: 'lg',
      windowClass: 'advanced-impact-popup-window',
      backdropClass: 'new-vendor-popup-backdrop-modal',
    });
    const compInstance = this.modalRef.componentInstance;
    compInstance.domainList = this.allDomainList;
    const enbdDomainList = [];
    this.enbdDomainList?.forEach(domain => {
      enbdDomainList.push(domain.name ? domain.name : domain);
    });

    let selectedDomains = this.defaultSelectedDomains.length
      ? [...this.selectedDomains, ...this.defaultSelectedDomains]
      : [...this.selectedDomains, ...enbdDomainList];

    selectedDomains = [...new Set(selectedDomains)];

    compInstance.selectedDomains = selectedDomains;

    this.modalRef.componentInstance.domain.subscribe((domainList: any) => {
      this.enbdDomainList = domainList?.filter(domain => {
        return domain?.isQualify;
      });
      this.selectedDomains = [];
      this.enbdDomainList?.forEach(domain => {
        if (domain.isQualify) {
          this.selectedDomains.push(domain.name);
        }
      });
      this.assessmentData.enbdDomains = this.selectedDomains;

      this.assessmentDataChange.emit(this.assessmentData);

      this.modalRef.close();
    });
    this.modalRef.componentInstance.closeModal.subscribe(() => {
      this.modalRef.close();
    });
  }
  updateSelectedLevel(): void {}
  /**
   * Setting the startingDate or deadlineDate in this function
   * @param date The actual NGB Date Struct from calendar
   * @param dateType The Date type , either Starting Date or Deadline Date
   */
  dateChanged(date, dateType: string): void {
    this[dateType] = date;
    if (dateType === this.ASSESSMENT_DATE) {
      this.assessmentData.startDate = date;
    } else {
      this.assessmentData.endDate = date;
    }
    this.assessmentDataChange.emit(this.assessmentData);
  }

  decrementor(): void {
    if (this.assessmentData.frequencyDays > 1) {
      this.assessmentData.frequencyDays = this.assessmentData.frequencyDays - 1;
    }
  }
  decrementorReviewDate(): void {
    if (this.assessmentData.nextFrequencyDate < 1) {
      this.assessmentData.nextFrequencyDate = 1;
      return;
    }

    if (this.assessmentData.nextFrequencyDate > 1) {
      this.assessmentData.nextFrequencyDate = this.assessmentData.nextFrequencyDate - 1;
    }
  }

  incrementorReviewDate() {
    this.assessmentData.nextFrequencyDate = this.assessmentData.nextFrequencyDate + 1;
    if (this.assessmentData.nextFrequencyDate > this.assessmentData.frequencyDays) {
      this.assessmentData.nextFrequencyDate = this.assessmentData.frequencyDays;
    }
  }
  incrementorFrequencyDate() {
    this.assessmentData.frequencyDays = this.assessmentData.frequencyDays + 1;
    if (this.assessmentData.frequencyDays > this.maxFrequencyDays) {
      this.assessmentData.frequencyDays = this.maxFrequencyDays;
    }
  }

  focusOutHandler($event) {
    if (this.assessmentData.nextFrequencyDate > this.assessmentData.frequencyDays) {
      this.assessmentData.nextFrequencyDate = this.assessmentData.frequencyDays - 1;
    } else {
      if (this.assessmentData.nextFrequencyDate < 1) {
        this.assessmentData.nextFrequencyDate = 1;
      }
    }
  }
  focusOutHandlerInput($event) {
    if (this.assessmentData.frequencyDays > this.maxFrequencyDays) {
      this.assessmentData.frequencyDays = this.maxFrequencyDays;
    } else {
      if (this.assessmentData.frequencyDays < 1) {
        this.assessmentData.frequencyDays = 1;
      }
    }
  }
  selectFrequency(event): any {
    this.showAdvanceSettings = event?.toLowerCase() === 'advance';
    this.assessmentData.frequencyName = event;
    this.assessmentDataChange.emit(this.assessmentData);
  }

  updateAssessmentData(type: string, value: any, updateLevel = false): void {
    if (type === 'framework') {
      const mergedLevel = { ...FrameworkLevelFrontend[this.currentClient], ...FrameworkLevelFrontend.COMMON };
      this.levelOptions = mergedLevel[value];
      this.assessmentData.level = updateLevel ? null : this.assessmentData.level;
      this.assessmentData.level = this.assessmentData.level ? this.assessmentData.level : this.levelOptions[0];
      this.assessmentData.frameworkName = value;
      this.assessmentData.frameworkKey = UtilsService.getEnumKey(this.VendorRiskFrameworkEnum, value);
      this.tpService?.addPreviousAssessment(this.assessmentData);
      this.assessmentData[type] = value;
      this.assessmentDataChange.emit(this.assessmentData);
      if (this.isENBD) {
        this.isEnbdVendorRisk = this.assessmentData?.frameworkKey === 'VENDOR_RISK_ENBD';
      }
      return;
    }

    if (type === 'level') {
      this.assessmentData.level = value;
    }
    if (type === 'artifactOption') {
      this.assessmentData.artifactOption = value;
    }
    this.tpService?.addPreviousAssessment(this.assessmentData);
    this.assessmentDataChange.emit(this.assessmentData);
  }

  filterRiskFrameworksByDomain(): void {
    // hotfix for filtering out risk frameworks base on domain
    // eventually add them from framework setting, but need discussion with client.
    const riskNames = [
      this.VendorRiskFrameworkEnum.VENDOR_RISK_CRB,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_FULL,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_CYBER_360,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_RANSOMWARE,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_ITC,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_BA_HIPAA,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_SSM_HEALTH,
      this.VendorRiskFrameworkEnum.ITC_CUSTOM,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD_SAQ_LITE,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD_SOC2,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ_V2,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ_V2,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_1,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_2,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_3,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_MOELIS,
      this.VendorRiskFrameworkEnum.VENDOR_RISK_OSAE,
      // this.VendorRiskFrameworkEnum.VENDOR_RISK_DBU_HECVAT_LITE,
    ];
    const removedRiskNames = riskNames.filter(name => {
      // add names if we want to remove them from riskList
      switch (name) {
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_ITC:
        case this.VendorRiskFrameworkEnum.ITC_CUSTOM:
          return !UtilsService.isITC;
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_FULL:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_CYBER_360:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN_RANSOMWARE:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_HOWDEN:
          return !UtilsService.isHowden;
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_BA_HIPAA:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_SSM_HEALTH:
          return !UtilsService.isSSMHealth;
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_CRB:
          return !UtilsService.isCRB;
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD_SAQ_LITE:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD_SOC2:
          return !UtilsService.isENBD;
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ_V2:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ_V2:
          return !UtilsService.isAdnoc;
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_1:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_2:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_3:
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_OSAE:
          return !UtilsService.isInformatica && !UtilsService.isInformaticaUat;
        case this.VendorRiskFrameworkEnum.VENDOR_RISK_MOELIS:
          return !UtilsService.isMoelis;
        // case this.VendorRiskFrameworkEnum.VENDOR_RISK_DBU_HECVAT_LITE:
        //   return !UtilsService.isDBU;
      }
    });
    // if removed list has less names it means one risk not going to remove. which means we are in specific client domain
    // then add VENDOR_RISK in this list as well, to remove it from riskList variable
    // SSM Health wants our VENDOR_RISK in addition to their
    if (removedRiskNames.length < riskNames.length && !UtilsService.isSSMHealth) {
      removedRiskNames.push(this.VendorRiskFrameworkEnum.VENDOR_RISK);
    }

    // Removed from all domains
    removedRiskNames.push(this.VendorRiskFrameworkEnum.TOS);
    removedRiskNames.push(this.VendorRiskFrameworkEnum.SMA_OCD);

    if (removedRiskNames && removedRiskNames.length) {
      this.riskList = this.riskList.filter(rl => {
        return !removedRiskNames.includes(rl);
      });
    }

    this.prioritizeRisks();
  }

  prioritizeRisks(): void {
    const arrayMove = (arr, oldIndex, newIndex) => {
      if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
      return arr; // for testing
    };

    let index: string;
    switch (true) {
      case UtilsService.isENBD:
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD);
        arrayMove(this.riskList, index, 0); // move to the top the array
        this.updateAssessmentData('framework', this.VendorRiskFrameworkEnum.VENDOR_RISK_ENBD); // selected risk framework
        break;
      case UtilsService.isAdnoc:
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ);
        arrayMove(this.riskList, index, 0); // move to the top the array
        this.updateAssessmentData('framework', this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ); // selected risk framework
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_PRESAQ_V2);
        arrayMove(this.riskList, index, 1); // move VENDOR_RISK_PRESAQ_V2 framework as second
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ);
        arrayMove(this.riskList, index, 2); // move VENDOR_RISK_SAQ framework as third
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_SAQ_V2);
        arrayMove(this.riskList, index, 3); // move VENDOR_RISK_SAQ_V2 framework as forth
        break;
      case UtilsService.isInformatica || UtilsService.isInformaticaUat:
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_1);
        arrayMove(this.riskList, index, 0); // move to the top the array
        this.updateAssessmentData('framework', this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_1); // selected risk framework
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_2);
        arrayMove(this.riskList, index, 1); // move VENDOR_RISK_INFORMATICA_2 framework as second
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_INFORMATICA_3);
        arrayMove(this.riskList, index, 2); // move VENDOR_RISK_INFORMATICA_3 framework as third
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_OSAE);
        arrayMove(this.riskList, index, 3); // move VENDOR_RISK_OSAE framework as forth
        break;
      case UtilsService.isMoelis:
        index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_MOELIS);
        arrayMove(this.riskList, index, 0); // move to the top the array
        this.updateAssessmentData('framework', this.VendorRiskFrameworkEnum.VENDOR_RISK_MOELIS); // selected risk framework
        break;
      // case UtilsService.isDBU:
      //   index = this.riskList.indexOf(this.VendorRiskFrameworkEnum.VENDOR_RISK_DBU_HECVAT_LITE);
      //   arrayMove(this.riskList, index, 0); // move to the top the array
      //   this.updateAssessmentData('framework', this.VendorRiskFrameworkEnum.VENDOR_RISK_DBU_HECVAT_LITE); // selected risk framework
      //   break;
    }
  }

  /**
   * this function will update the values of auto reminder and auto re-assessment
   * @param checkboxOpt an object having name of option and boolean to tell if its selected or not
   * @return void
   */
  checkboxSelection(checkboxOpt: any): void {
    checkboxOpt.checked = !checkboxOpt?.checked;
    if (checkboxOpt?.name.toLowerCase() === this.timelineCheckBox?.AUTOMATIC_REMINDER?.toLowerCase()) {
      this.assessmentData.isAutoReminder = checkboxOpt.checked;
    } else {
      this.assessmentData.isAutoReassessment = checkboxOpt.checked;
    }
    this.assessmentDataChange.emit(this.assessmentData);
  }
}
