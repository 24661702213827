import { StringToNumberPipe } from './string-to-numer.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyNameSortPipe } from './agency-name-sort.pipe';
import { KeysPipe } from './keys.pipe';
import { PercentPipe } from './percent.pipe';
import { EllipsisPipe } from './ellipsis.pipe';
import { ReversePipe } from './reverse.pipe';
import { CutStrPipe } from './cut-str.pipe';
import { CapAndRemoveDashesPipe } from './cap-and-dashes.pipe';
import { NormalizeScorePipe } from './normalize-score.pipe';
import { CapitalizeFirstPipe } from './capitalize-first.pipe';
import { ReplacePipe } from './replace.pipe';
import { CapitalizationRulePipe } from './capitalization-rule.pipe';
import { LargeNumberShort } from './large-number-short.pipe';
import { FilterArrayPipe } from 'app/shared/pipes/filter-array.pipe';
import { AppendPreZeros } from './append-pre-zeros.pipe';
import { FilterRemoveValuePipe } from './filter-remove-value.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    EllipsisPipe,
    AgencyNameSortPipe,
    KeysPipe,
    PercentPipe,
    ReversePipe,
    CutStrPipe,
    CapAndRemoveDashesPipe,
    NormalizeScorePipe,
    StringToNumberPipe,
    CapitalizeFirstPipe,
    CapitalizationRulePipe,
    ReplacePipe,
    LargeNumberShort,
    FilterArrayPipe,
    AppendPreZeros,
    FilterRemoveValuePipe,
  ],
  providers: [EllipsisPipe, AgencyNameSortPipe, KeysPipe, PercentPipe, ReversePipe, StringToNumberPipe],
  exports: [
    EllipsisPipe,
    AgencyNameSortPipe,
    KeysPipe,
    PercentPipe,
    ReversePipe,
    CutStrPipe,
    CapAndRemoveDashesPipe,
    NormalizeScorePipe,
    StringToNumberPipe,
    CapitalizationRulePipe,
    CapitalizeFirstPipe,
    ReplacePipe,
    LargeNumberShort,
    FilterArrayPipe,
    AppendPreZeros,
    FilterRemoveValuePipe,
  ],
})
export class PipesModule {}
