import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, OnDestroy } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Animations } from './animation';
import {
  FileTypeEnum,
  UpdateEntityWizardInput,
  CreateEntityWizardInput,
  EntityWizard,
  CreateUserInput,
  RoleEnum,
  MidMarketEnum,
} from 'app/API.service';
import { IndustryEnum, WizardStringStepsEnum } from '../enums/brownAndBrown.enum';
import { AuthService } from 'app/auth/auth.service';
import { step8Data } from 'app/upperdeck/upperdeck.constants';
import { Auth } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { DomainFrameworkService } from '../domain-framework.service';
import { FormulaConstants } from '../entity-wizard-pop-up/formulaDictionary.constant';
import { EntityService } from '../entity.service';
import {
  IndustryEnumBeecher,
  ClientSubMenuEnum,
  ClientWizardNumStringStepsEnum,
  MidMarketWizardNumStringStepsEnum,
  MidMarketWizardStringStepsEnum,
} from '../enums/brownAndBrown.enum';
import { FrameworkEnumTypes } from '../enums/framework-enum-types.enum';
import { FileService } from '../file.service';
import { ModalTemplateComponent } from '../modal-template/modal-template.component';
import { UtilsService } from '../utils.service';
import jStat from 'jstat';
import { v4 as uuid } from 'uuid';
import { FrameworkModalComponent } from '../framework-modal/framework-modal.component';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { MidMarketFileUploadComponent } from '../mid-market-file-upload/mid-market-file-upload.component';
import { Subscription } from 'rxjs';
import { UserService } from '../user.service';
import { AppLevelRoleEnum } from '../enums/appLevelRoles.enum';
import { ClientLambdaService } from '../client-lambda.service';
import { environment } from 'environments/environment';

/**
 * Broker Interface
 * Used in Mid Market Account Details
 */
interface _Broker {
  name: string;
  email: string;
}

@Component({
  selector: 'cygov-midmarket-wizard-pop-up',
  templateUrl: './midmarket-wizard-pop-up.component.html',
  styleUrls: ['./midmarket-wizard-pop-up.component.scss'],
  animations: [
    Animations.fadeInOutAnimation,
    Animations.fadeInOutAnimationDelayed,
    Animations.domainHeightAnimation,
    Animations.fadeInOnly,
  ],
})
export class MidmarketWizardPopUpComponent implements OnInit, OnDestroy {
  @Input() wizardData: any = null;
  @Input() frameworkList = [];
  @Input() modalInstance: any = null;
  @Input() updateWizard: boolean = false;
  @Input() isUpperdeck: boolean = false;
  @Input() isClient: boolean = false;
  @Input() brokers = [];
  @Input() fromUpperDeck: boolean = false;
  @Input() fromClientScreen: boolean = false;
  @Output() modalResult: EventEmitter<any> = new EventEmitter<any>();
  @Output() completeClient: EventEmitter<any> = new EventEmitter<any>();
  @Output() draftSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteCalled: EventEmitter<any> = new EventEmitter<any>();
  isNextClicked: boolean = false;

  newObj: any = {
    step_1: [
      [
        {
          asterisk: true,
          title: 'Client Name',
          value: '',
          disabled: false,
        },
        {
          asterisk: true,
          title: 'Primary PoC Name',
          value: '',
          disabled: this.isUpperdeck,
        },
        {
          asterisk: true,
          title: 'Primary PoC Email',
          value: '',
          disabled: this.isUpperdeck,
        },
      ],
      [
        {
          title: 'Active Scan',
          domains: [],
          isActive: false,
        },
      ],
      [
        {
          asterisk: true,
          displayValue: '2,000',
          showDollar: true,
          title: 'Cost Per Employee',
          value: 2000,
        },
        {
          asterisk: true,
          displayValue: '15,000',
          showDollar: true,
          title: 'Cost Per Server',
          value: 15000,
        },
        {
          asterisk: true,
          displayValue: '2,000',
          showDollar: true,
          title: 'Cost Per Workstation',
          value: 2000,
        },
        {
          asterisk: true,
          displayValue: '20,149',
          title: 'PR and Response Expenses',
          value: 20149,
        },
      ],
      [
        {
          frameworks: [],
          asterisk: true,
        },
      ],
    ],
    step_2: [
      [
        {
          asterisk: false,
          isCalendar: false,
          isDropDownOpened: false,
          list: ['Fee', 'Commission', 'Other'],
          title: 'Account Type',
          value: '',
        },
        {
          asterisk: false,
          isCalendar: true,
          isCalendarOpen: false,
          title: 'Renewal Date',
          value: null,
        },
        {
          asterisk: false,
          isCalendar: true,
          isCalendarOpen: false,
          title: 'Submission Target Date',
          value: null,
        },
        {
          asterisk: false,
          isCalendar: true,
          isCalendarOpen: false,
          title: 'Pre-Submission Kickoff Target',
          value: null,
        },
        {
          asterisk: false,
          isCalendar: false,
          isDropDownOpened: false,
          title: 'Producer',
          value: null,
        },
      ],
      [
        // {
        //   asterisk: false,
        //   isCalendar: false,
        //   isDropDownOpened: false,
        //   title: 'Broker # 1',
        //   value: null,
        // },
        // {
        //   asterisk: false,
        //   isCalendar: false,
        //   isDropDownOpened: false,
        //   title: 'Broker # 2',
        //   value: null,
        // },
        {
          asterisk: false,
          isCalendar: false,
          isDropDownOpened: false,
          title: 'Risk Analyst Email',
          value: null,
        },
        {
          asterisk: false,
          isCalendar: true,
          title: 'Notes',
          value: null,
        },
      ],
      [
        {
          current: {
            displayValue: '',
            value: '',
          },
          previous: {
            displayValue: '',
            value: '',
          },
          title: 'Compensation Amount',
          type: 'number',
        },
        {
          current: {
            displayValue: null,
            value: '',
          },
          previous: {
            displayValue: null,
            value: '',
          },
          title: 'Compensation Type',
          type: 'string',
        },
        {
          current: {
            displayValue: null,
            value: '',
          },
          previous: {
            displayValue: null,
            value: '',
          },
          title: 'Primary Carrier',
          type: 'string',
        },
        {
          current: {
            displayValue: '',
            value: '',
          },
          previous: {
            displayValue: '',
            value: '',
          },
          title: 'Total Limit',
          type: 'number',
        },
        {
          current: {
            displayValue: '',
            value: '',
          },
          previous: {
            displayValue: '',
            value: '',
          },
          title: 'Total Premium',
          type: 'number',
        },
        {
          current: {
            displayValue: '',
            value: '',
          },
          previous: {
            displayValue: '',
            value: '',
          },
          title: 'Primary Limit',
          type: 'number',
        },
        {
          current: {
            displayValue: '',
            value: '',
          },
          previous: {
            displayValue: '',
            value: '',
          },
          title: 'Primary Premium',
          type: 'number',
        },
        {
          current: {
            displayValue: null,
            value: '',
          },
          previous: {
            displayValue: null,
            value: '',
          },
          title: 'Retention',
          type: 'string',
        },
        {
          current: {
            displayValue: '',
            value: '',
          },
          previous: {
            displayValue: '',
            value: '',
          },
          title: 'RW Sublimit',
          type: 'number',
        },
        {
          current: {
            displayValue: '',
            value: '',
          },
          previous: {
            displayValue: '',
            value: '',
          },
          title: 'RW Co-Insurance',
          type: 'number',
        },
      ],
    ],
    step_3: [
      [
        {
          asterisk: true,
          displayValue: '',
          title: 'Annual Revenue',
          value: null,
          isChecked: true,
          showDollar: true,
        },
        {
          asterisk: true,
          displayValue: '',
          title: 'Annual Cost of Goods and/or Services',
          value: null,
          isChecked: true,
          showDollar: true,
        },
        {
          asterisk: true,
          displayValue: '',
          title: 'Number of Servers',
          value: null,
          isChecked: true,
        },
        {
          asterisk: true,
          displayValue: '',
          title: 'Number of Workstations',
          value: null,
          isChecked: true,
        },
      ],
      [
        // {
        //   asterisk: true,
        //   isChecked: true,
        //   title: 'Name of Broker',
        //   value: null,
        // },
        // {
        //   asterisk: true,
        //   isChecked: true,
        //   title: 'Broker#1 Email',
        //   value: null,
        // },
        // {
        //   asterisk: false,
        //   isChecked: true,
        //   title: 'Broker#2 Email',
        //   value: null,
        // },
        {
          asterisk: false,
          isChecked: true,
          title: 'Risk Analyst Email',
          value: null,
        },
      ],
    ],
  };

  wizardId = null;
  stepsNumEnum = {};
  stepsStringEnum = WizardStringStepsEnum;
  showTextTooltip = false;
  showAdditionalDetails = false;
  displayAdd = null;
  dataScopeNames = ['PII', 'PHI', 'PIFI', 'PCI', 'AR', 'IDT', 'TRN'];
  otherDataScopeNames = {
    AR: 'Annual Revenue',
    IDT: 'Industry',
    TRN: 'Total Records Without PII',
  };

  steps = [
    { num: 'first', title: 'Administrative', active: true, completed: false },
    { num: 'Second', title: 'Account Details', active: false, completed: false },
  ];
  clientSteps = [
    { num: 'first', title: '1', active: true, completed: false },
    { num: 'second', title: '2', active: false, completed: false },
    { num: 'third', title: '3', active: false, completed: false },
    { num: 'forth', title: '4', active: false, completed: false },
  ];
  innerSteps = [
    { num: '1', title: 'Organizational', active: true, completed: false, svgName: 'bnb-organizational-icon' },
    { num: '2', title: 'Financial', active: false, completed: false, svgName: 'bnb-financial-icon' },
    { num: '3', title: 'Frameworks', active: false, completed: false, svgName: 'bnb-frameworks-icon' },
    { num: '4', title: 'Data Scope', active: false, completed: false, svgName: 'bnb-data-scope-icon' },
    { num: '5 ', title: 'Technical', active: false, completed: false, svgName: 'bnb-technical-icon' },
    { num: '6', title: 'Risk Scenarios', active: false, completed: false, svgName: 'bnb-risk-scenarios-icon' },
  ];
  colorScheme = {
    lightBlue: '#29a9eb',
    blue: '#0B517C',
    buttonBlue: '#29a9eb',
    buttonHover: '#0F92E3',
    brightPurple: '#A52FF5',
    dullPurple: '#a242e3',
  };

  // main local variables
  stepNumber: number = 1;
  wizardProgress = 10;
  // step 1 local vars
  newActiveDomains = [{ value: '' }];
  oldExistingDomains = [];
  activeScan = false;
  newLogoUploaded = false;
  s3Input = null;
  uploadedLogo = null;
  uploadingStart = false;
  cachedImage = null;
  // step 6 local vars
  detailOpened = 0;
  showTotalRisk = false;
  // step 3 local vars
  frameworkFilterBy = 'All';
  timerDelay = null;
  searchKeyword = '';

  // main Step Data Objects
  organizational = null;
  financial = null;
  frameworks = null;
  dataScope = null;
  applicantInfo = null;
  revenueDetails = null;
  technical = null;
  dataInventory = null;
  riskScenarios = null;
  projectedInherent = 0;
  accountDetails = null;
  currentYear = null;
  stepKeywordProps = ['organizational', 'financial', 'frameworks', 'dataScope', 'technical', 'riskScenarios'];

  // static listings
  businessList = ['Commercial Lines', 'Property & Casualty', 'Services', 'Benefits', 'Other'];

  customBusinessList = [];
  thirdStepDrop = ['All', 'On', 'Off'];
  industryOpt = Object.keys(IndustryEnum).map(key =>
    UtilsService.capitalizeFirstLetter(IndustryEnum[key].toLowerCase())
  );
  industryOptBeecher = Object.keys(IndustryEnumBeecher).map(key => IndustryEnumBeecher[key]);
  allCompList: any;
  complianceList: any;
  customCompList = [];

  ngbModalDeleteWizard: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont bnb-wizard-popup-window',
    backdropClass: 'bnb-wizard-popup-backdrop-modal md-modal-template-backdrop',
    backdrop: 'static',
  };
  ngbModalFrameworkModal: NgbModalOptions = {
    size: 'sm',
    windowClass: 'framework-modal-content',
    backdropClass: 'framework-backdrop-class',
    backdrop: 'static',
  };
  ngbModalDeleteConfirmation: NgbModalOptions = {
    size: 'sm',
    windowClass: 'delete-wizard-confirm-modal delete-collection-framework-modal',
    backdropClass: 'delete-wizard-confirm-backdrop',
    backdrop: 'static',
  };
  ngbModalSendClient: NgbModalOptions = {
    size: 'sm',
    // modal-template-cont, modal-template-cont-backdrop must classes for standard design of popup
    windowClass: 'modal-template-cont bnb-wizard-popup-window send-to-client-pop-up',
    backdropClass: 'mid-market-popup-backdrop-modal md-modal-template-backdrop',
    backdrop: 'static',
  };
  modalFileUploadScreen: NgbModalOptions = {
    windowClass: 'file-upload-content-modal',
    backdropClass: 'file-upload-content-modal-backdrop',
    centered: true,
  };
  deleteModalInstance = null;
  isLoading = false;
  editingFrames = [];
  disableLoaderFader = true;

  // formula variables
  numConstant = FormulaConstants.NUM_CONSTANT;
  medicalConstant = FormulaConstants.MEDICAL_FRAUD_MONITORING;
  titleConst = FormulaConstants.RISK_SCENARIO_TITLES;
  initialConst = FormulaConstants.INITIAL_IMPACT;

  // Screenshot variables
  stepImgList = [];
  takingScreenshot = false;
  lister = [
    {
      name: MidMarketWizardStringStepsEnum.ORGANIZATIONAL,
      stepNum: 1,
      progress: 30,
    },
    {
      name: MidMarketWizardStringStepsEnum.ACCOUNT_DETAILS,
      stepNum: 2,
      progress: 60,
    },
    {
      name: MidMarketWizardStringStepsEnum.DATA_SCOPE,
      stepNum: 3,
      progress: 90,
    },
  ];

  selectedBrokers: _Broker[] = []; //* array to keep track of selected brokers
  domainScanTimes = [];
  isDropDownOpened: boolean = false;
  riskFrameWorkOpt = [];
  isBeecher: boolean = true;
  selectedRiskFramework: string;
  isNewClicked: boolean = false;
  newBusinessLine: string = null;
  totalSteps: number = 3;
  showCurrentYearPopup: boolean = false;
  showIndustryDropDown: boolean = false;
  leftDropDown: boolean = false;
  rightDropDown: boolean = false;
  dropDownIndex: number = -1;
  current_previous_const = step8Data;
  accountTypeList = ['Fee', 'Commission', 'Other'];
  oldAccountTypeList = ['Expense', 'Liability', 'Revenue'];
  existingDate = null;
  minDate = null;
  maxDate = null;
  parsedInherentValues: string[] = [];
  StandardEnum: any;
  allFrameworks: any = [];
  orignamFrameworkList: any[];
  enableClientBtn: boolean = true;
  organizationalScreen: boolean = true;
  accountsScreen: boolean = false;
  uploadLogoScreen: boolean = false;
  applicantInfoScreen: boolean = false;
  dataScopeScreen: boolean = false;
  revenueDetailScreen: boolean = false;
  dataInventoryScreen: boolean = false;
  userId: string = '';
  revenueDetailsIndustry: string = '';
  nextButton: string = 'NEXT';
  savedFiles: any = [];
  removedFiles: any = [];
  draftValid = false;
  subscription: Subscription[] = [];
  constructor(
    private authService: AuthService,
    private entityService: EntityService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private fileService: FileService,
    private domainFrameworkService: DomainFrameworkService,
    private customApi: CUSTOMAPIService,
    private userService: UserService,
    private lambdaService: ClientLambdaService
  ) {
    this.StandardEnum = this.fileService.importFrameworkEnumsFromS3(FrameworkEnumTypes.STANDARD_ENUM);
  }

  async ngOnInit(): Promise<void> {
    this.stepsNumEnum = this.isClient ? ClientWizardNumStringStepsEnum : MidMarketWizardNumStringStepsEnum;
    // this.nextButton = this.isClient ? 'COMPLETE' : 'NEXT';

    //* Setting checked property to false, otherwise after selecting brokers
    //* broker is displayed checked because of cache in user select component
    this.brokers = this.brokers.map(obj => ({ ...obj, checked: false }));

    this.newObj.step_1[3][0].frameworks = this.frameworkList;
    this.allCompList = Object.keys(this.StandardEnum).map(key => this.StandardEnum[key]);
    this.complianceList = Object.keys(this.StandardEnum).map(key => this.StandardEnum[key]);
    this.isLoading = true;
    // for beecher..preparing the frameworks

    if (this.isClient) {
      this.steps = this.clientSteps;
      this.totalSteps = 4;
    }
    this.addAdditionalStepsDataBeecher();

    this.industryOptBeecher.sort();
    this.initWizardData(this.wizardData);
    // handling the case for older entities. If the organizational portion has line of business in string,
    // it needs to be converted into array.
    if (this.wizardData) {
      this.updateOldDataWizard();
      // removing 'Other Contacts' from object
      if (this.isUpperdeck || this.fromClientScreen || this.organizational[0].length > 3) {
        this.organizational[0] = this.organizational[0].slice(0, 3);
      }
    }

    this.customCompList = await this.getCustomFrameworksFromS3();
    if (this.customCompList && this.customCompList.length) {
      this.customCompList.forEach(comp => {
        if (!this.allCompList.includes(comp)) {
          this.allCompList.push(comp);
        }
      });
      this.complianceList = [...this.allCompList];
    }
    // Line of business is only enabled for BNB
    this.customBusinessList = await this.getCustomBusinessOfLinesFromS3();
    if (this.customBusinessList && this.customBusinessList.length) {
      this.customBusinessList.forEach(comp => {
        if (!this.businessList.includes(comp)) {
          this.businessList.push(comp);
        }
      });
    }
    // verify the selected line of business has options in drop down
    if (!this.isClient && this.organizational[1][0]?.value?.length) {
      const cacheBusiness = [];
      this.organizational[1][0]?.value.forEach(bus => {
        if (this.businessList.includes(bus)) {
          cacheBusiness.push(bus);
        }
      });
      this.organizational[1][0].value = cacheBusiness;
    }

    this.disableLoaderFader = false;
    this.isLoading = false;
    this.orignamFrameworkList = [...this.frameworkList];
    if (!this.wizardData) {
      // if wizard is not created select the first framework from framework list
      this.frameworkList.forEach((framework, index) => (framework.selected = !index));
      this.selectedRiskFramework = this.frameworkList[0].key;
    } else {
      // TODO: remove comments once client pass it.
      // if wizard is create swap that framework index with first index of framework list
      // const checkedIndex = this.frameworkList.findIndex(item => item.key === this.wizardData.riskFramework);
      // if (checkedIndex !== -1) {
      // Bring the selected framework to the top of the list
      //   [this.frameworkList[0], this.frameworkList[checkedIndex]] = [
      //     this.frameworkList[checkedIndex],
      //     this.frameworkList[0],
      //   ];
      // }
    }
    this.enableClientButton();

    this.subscription.push(
      this.entityService.wizardDataUpdated.subscribe(obj => {
        if (this.wizardData?.id === obj?.wizardData?.id) {
          const stepKeywordProps = [
            'organizational',
            'financial',
            'dataScope',
            'technical',
            'riskScenarios',
            'accountDetails',
            'applicantInfo',
            'revenueDetails',
            'dataInventory',
          ];
          this.wizardData = obj.wizardData;
          stepKeywordProps.forEach(stepName => {
            this[stepName] = JSON.parse(this.wizardData[stepName]);
          });
          if (this.wizardData?.updatedBy === 'admin' && this.isClient) {
            this.toastr.info('Admin Updated Some Data, Refreshing To Get Updated Data');
            location.reload();
          }
          if (this.wizardData?.updatedBy === 'client' && !this.isClient) {
            this.toastr.info('Client Updated Some Data, Please Refresh');
            this.closeModal();
          }
        }
      })
    );

    // convert objects to new form

    // if (this.wizardData) {
    //   this.convertToNewForm();
    // }
  }

  // this function will handle the wizards thats been already created and then we got the change requests
  // convertToNewForm(): void {
  //   // at the begining we have titles broker 1 , broker 2 and we didn't have the broker name input field
  //   // then in a CR we have to add broker name and change broker 1 and broker 2 to broker emails
  //   this.accountDetails[1]?.forEach(element => {
  //     if (element?.title?.toLowerCase() === 'broker# 1') {
  //       element.title = 'Broker# 1 Email';
  //     }
  //     if (element?.title?.toLowerCase() === 'broker# 2') {
  //       element.title = 'Broker# 2 Email';
  //     }
  //   });

  //   const hasBrokerName = this.accountDetails[1].some(obj => obj.title?.toLowerCase() === 'name of broker');
  //   if (!hasBrokerName) {
  //     this.accountDetails[1].unshift({
  //       asterisk: false,
  //       isCalendar: false,
  //       isDropDownOpened: false,
  //       title: 'Name of Broker',
  //       value: null,
  //     });
  //   }
  // }

  /**
   * Helper function to add brokers to the list
   * Removes the broker if already in the list
   * @param selectedUser output from select user drop down
   */
  addSelectedBroker(selectedUser: any) {
    if (selectedUser) {
      const addIndex = this.selectedBrokers.findIndex(broker => broker.email === selectedUser.email);

      if (addIndex !== -1) {
        // Selected user already exists, remove it from selectedBrokers
        this.selectedBrokers.splice(addIndex, 1);
      } else {
        // Selected user doesn't exist, add it to selectedBrokers
        this.selectedBrokers.push({ name: selectedUser.name, email: selectedUser.email });
      }
    }
  }

  /**
   * Functions adds Brokers to account Details
   */
  addBrokersToAccountDetails() {
    if (this.selectedBrokers.length > 0) {
      const newObjects = this.selectedBrokers.map((broker, index) => ({
        asterisk: false,
        isCalendar: false,
        isDropDownOpened: false,
        title: `Broker# ${index + 1} Email`,
        name: broker.name,
        value: broker.email,
      }));
      // * Remove the brokers to avoid duplication and it doesn't affect the last two indices
      this.accountDetails[1] = this.accountDetails[1].slice(-2);
      this.accountDetails[1].unshift(...newObjects);
    }
  }

  /**
   * Function gets selected brokers from account details object
   */
  getBrokersFromAccountDetails() {
    this.selectedBrokers = [];
    //* slicing last 2 elements risk Analyst email etc
    //* Brokers are from 0th index to ownwards except fro last two indexes
    this.accountDetails[1].slice(0, -2).forEach(brokerObj => {
      this.selectedBrokers.push({ name: brokerObj.name, email: brokerObj.value });
    });
  }

  /**
   * Helper function to populate the frameworks list for popup. in case of beecher.
   */
  applyFrameworkSettings(): void {
    let frameworkSettings = this.domainFrameworkService.domainSettingsData;
    frameworkSettings = frameworkSettings?.RISK_FRAMEWORKS.filter(riskFramework => !!riskFramework.status);
    // temporarily putting the nist_csf_bb framework in the second position.
    // first will be paragon but only for Beecher domain
    frameworkSettings = this.moveToFirstIndex(frameworkSettings, 'NIST_CSF_BB');

    frameworkSettings = this.moveToFirstIndex(frameworkSettings, 'PARAGON_BASIC');
    frameworkSettings = this.moveToFirstIndex(frameworkSettings, 'PARAGON_FULL');
    frameworkSettings = this.moveToFirstIndex(frameworkSettings, 'BEAZLEY');
    frameworkSettings = this.moveToFirstIndex(frameworkSettings, 'BEAZLEY_OT');
    frameworkSettings = this.moveToFirstIndex(frameworkSettings, 'B_APP_RSWOT');
    this.riskFrameWorkOpt = frameworkSettings?.map(fs => {
      return { key: fs.key, name: this.domainFrameworkService.getFrameworkName(fs.key) };
    });
  }

  moveToFirstIndex(frame: any, name: string): void {
    const obj = frame?.find(risk => risk.key === name);
    const obj_index = frame?.findIndex(risk => risk.key === name);
    if (obj) {
      frame.splice(obj_index, 1);
      frame.unshift(obj);
    }
    return frame;
  }

  /**
   * Function to add additional steps in case of Beecher
   */
  addAdditionalStepsDataBeecher() {
    // Upper steps
    if (!this.isClient) {
      this.steps.push({ num: 'Third', title: 'Customer Info', active: false, completed: false });
    }
    // inner steps
    this.innerSteps.push(
      {
        num: '7',
        title: 'Account Details',
        active: false,
        completed: false,
        svgName: 'bnb-account-details',
      },
      {
        num: '8',
        title: 'Current/Previous Year',
        active: false,
        completed: false,
        svgName: 'bnb-current-previous-year',
      }
    );
    // Lister object for progress
    // props Object
    this.stepKeywordProps.push('accountDetails', 'currentYear', 'applicantInfo', 'revenueDetails', 'dataInventory');
  }

  closeModal(): void {
    if (this.modalInstance) {
      this.modalInstance.close();
    }
  }

  updateOldDataWizard(): void {
    // checking For Organizational Business Line and change the old data of single string selection to an array of strings
    if (
      this.organizational &&
      this.organizational[1]?.[0]?.value &&
      typeof this.organizational[1][0].value === 'string'
    ) {
      this.organizational[1][0].value = this.organizational[1]?.[0]?.value.split(',');
    }

    // changing the old title data of Finance Leader Name in Organizational Step in Wizard
    if (this.organizational && this.organizational[0]?.[3]?.title === 'Finance Leader Name') {
      this.organizational[0][3].title = 'Accounting Leader Name';
    }

    // updating the organizational required data.
    if (
      this.organizational &&
      this.organizational.length &&
      this.organizational[0]?.length &&
      this.organizational[1]?.length
    ) {
      [...this.organizational[0], ...this.organizational[1]].forEach(intObj => {
        if (
          intObj.title === 'Number of Customers' ||
          intObj.title === 'Number of Vendors' ||
          intObj.title === 'Number of Employees' ||
          intObj.title === 'Profit Center Leader'
        ) {
          intObj.asterisk = false;
        }
        if (intObj.title === 'Profit Center Leader' || intObj.title === 'Primary Risk Management Contact') {
          intObj.title = 'Primary Risk Management Contact';
          intObj.asterisk = true;
        }
        if (intObj.title === 'Entity Name') {
          intObj.title = 'Client Name';
        }
        if (intObj.title === 'Number of Employees') {
          intObj.title = 'Number of Teammates';
        }
      });
    }
    // updating the financial required data. Title name change for Old Data
    if (this.financial && this.financial.length && this.financial[0]?.length && this.financial[1]?.length) {
      [...this.financial[0], ...this.financial[1]].forEach(intObj => {
        if (intObj.title !== 'Annual Revenue' && intObj.title !== 'Annual Cost of Services') {
          intObj.asterisk = false;
        }
        if (intObj.title === 'Annual Cost of Services') {
          intObj.title = 'Annual Cost of Goods and/or Services';
        }
      });
    }
    // updating the technical required data. Making some fields not required
    if (this.technical && this.technical.length && this.technical[1].length) {
      this.technical[1].forEach(intObj => {
        if (intObj.title !== 'PR and Response Expenses') {
          intObj.asterisk = false;
        }
      });
      this.technical[0].forEach(intObj => {
        if (intObj.title === 'Cost Per Employee' || intObj.title === 'Cost Per Teammate') {
          intObj.asterisk = true;
          if (intObj.title === 'Cost Per Employee') {
            intObj.title = 'Cost Per Teammate';
          }
        }
      });
    }

    // updating Band Card Resistance to Bank Card Reissuance
    if (this.riskScenarios?.length) {
      this.riskScenarios.forEach(rsk => {
        rsk.moreChildren.forEach(title => {
          if (title.name === 'Bank Card Resistance') {
            title.name = 'Bank Card Reissuance';
          }
        });
      });

      // beecher hybrid risk scenarios titles
      if (this.riskScenarios[2].moreChildren.length === 16) {
        this.riskScenarios[2].moreChildren = this.beecherHybridTitles();
      }
      // removing security remediation from Beecher Case
      const idx = this.riskScenarios[0].moreChildren?.findIndex(title => title.name === 'Security Remediation');
      if (idx > -1) {
        this.riskScenarios[0].moreChildren.splice(idx, 1);
      }

      // resetting Account Type value if old title selected
      if (this.accountDetails?.length && this.accountDetails[0].length) {
        if (this.accountTypeList.includes(this.accountDetails[0][0].value)) {
          this.accountDetails[0][0].value = '';
        }
        this.accountDetails[0][0].list = this.oldAccountTypeList;
      }
    }
  }

  async completeWizardProcess(screenShot: boolean = true) {
    let isValid = true;
    let obj = [];
    if (!this.isClient) {
      isValid = this.checkValidation(this.stepNumber);
    }
    if (!isValid) {
      return;
    } else {
      obj = this.createWizardObj();
    }
    this.isLoading = true;
    if (screenShot && isValid) {
      this.disableLoaderFader = true;
      this.frameworkFilterBy = 'On';
      this.takingScreenshot = true;
      this.frameworkFilterChange();
      this.showTotalRisk = true;
      setTimeout(() => {
        this.screenShotProcess();
      }, 0);
    }
    const newWizard = (await this.saveWizardDraft(false, obj)) as EntityWizard;

    // invoking binary edge scan lambda only once
    if (newWizard) {
      if (this.activeScan) {
        this.scanAllDomains(newWizard.id);
      }
      let currentUser = this.authService.getCurrentUserSync();
      try {
        if (!currentUser) {
          currentUser = await this.authService.getCurrentUser();
        }
        await this.entityService.createWizardRootEntity(
          this.organizational,
          newWizard,
          currentUser.id,
          this.wizardData
        );
        if (this.wizardData && this.wizardData.rootEntity) {
          this.toastr.success('Entity Updated Successfully!');
        } else {
          this.toastr.success('Entity Created Successfully!');
          this.sendNotification();
        }
        this.modalResult.emit(newWizard);
        // await this.sendToClient(true);

        this.isLoading = false;
        this.closeModal();
      } catch (e) {
        console.log(e);
        this.toastr.error('Error - Could Not Create Entity');
      }
    } else {
      this.toastr.error('Error - While Updating Wizard');
    }
  }

  async nextClicked(screenShot = true): Promise<void> {
    if (this.stepNumber !== this.totalSteps) {
      this.saveWizardDraft(true, {}, false, true);
    }
    if (screenShot) {
      let cacheStep = this.stepNumber;
      let isValid = false;
      if (cacheStep === this.totalSteps && (this.isClient || this.isUpperdeck)) {
        isValid = this.isClient ? this.clientValidation(this.stepNumber) : this.checkValidation(this.stepNumber);
        if (isValid) {
          this.isLoading = true;
          await this.completeWizardProcess(screenShot);
          this.completeClient.emit(true);
          this.closeModal();
          return;
        }
      } else {
        if (this.totalSteps !== cacheStep) {
          cacheStep++;
        }
      }
      if (this.draftValid) {
        isValid = this.isClient ? this.clientValidation(this.stepNumber) : this.checkValidation(this.stepNumber);
      }

      // check if all fields are valid then move onto next step
      if (isValid) {
        if (this.stepNumber % 3 === 0 && this.stepNumber !== 6) {
          this.initLocalVariables(this.stepNumber);
        }

        // if valid then store the local variable data into DB object.
        this.stepNumber = cacheStep;
        this.updateStepStatus(cacheStep);
      }
    }
  }

  previousClicked(): void {
    if (this.stepNumber === 1) {
      return;
    }
    if (this.stepNumber % 3 === 0 && this.stepNumber !== 6) {
      this.initLocalVariables(this.stepNumber);
    }
    this.stepNumber--;
    this.getBrokersFromAccountDetails();
    this.updateStepStatus(this.stepNumber, false);
  }
  validateEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }
  checkValidation(step: number = 1, showToaster: boolean = true): boolean {
    let isValid = true;
    let toasterMSG = '';
    switch (step) {
      case 1: {
        this.organizational.forEach((stateObj, index) => {
          if (index < 2) {
            // treat them as array
            for (const valObj of stateObj) {
              // stateObj.forEach(valObj => {
              if (valObj.asterisk) {
                if (typeof valObj.value === 'string') {
                  if (!valObj.value || (valObj.value && !valObj.value.trim())) {
                    isValid = false;
                    toasterMSG = valObj.title + ' Is Missing';
                    // return false;
                    break;
                  }
                  if (
                    isValid &&
                    (valObj?.title === 'Client Name' || valObj?.title === 'Primary PoC Name') &&
                    valObj?.value?.length > 20
                  ) {
                    isValid = false;
                    toasterMSG = valObj.title + ' Cannot Be More Than 20 Characters';
                    // return false;
                    break;
                  }
                  if (isValid && valObj.title === 'Primary PoC Email') {
                    valObj.value = valObj.value.toLowerCase();
                    if (!this.validateEmail(valObj.value)) {
                      isValid = false;
                      toasterMSG = 'Please Enter Valid Email';
                      // return false;
                      break;
                    }
                  }
                } else if (typeof valObj.value === 'object') {
                  isValid = false;
                  toasterMSG = valObj.title + 'Mandatory Fields Values Missing';
                  break;
                  // return false;
                }
                // else if (index === 1) {
                //   if (valObj?.displayValue?.length > 20) {
                //     isValid = false;
                //     toasterMSG = valObj?.title + ' Limit Exceed';
                //     // return false;
                //     break;
                //   }
                // }
              }
              // });
            }
          } else if (index === 2) {
            if (!stateObj.industry) {
              isValid = false;
              toasterMSG = 'Please Select An Industry';
              return false;
            }
          } else if (index === 3) {
            // treat as object
            if (stateObj.isActive) {
              if (!this.newActiveDomains || (this.newActiveDomains && !this.newActiveDomains.length)) {
                isValid = false;
                toasterMSG = 'Add At-least 1 Domain If Active Scan Is Enabled';
                return false;
              } else {
                const cacheList = [];
                this.newActiveDomains.forEach(domain => {
                  if (domain.value && domain.value.trim()) {
                    cacheList.push(domain.value);
                  }
                });
                if (!cacheList.length) {
                  isValid = false;
                  toasterMSG = 'Add At-least 1 Domain If Active Scan Is Enabled';
                  return false;
                }
              }
            }
          }
        });
        break;
      }
      case 2: {
        // let isEmailEntered = false;
        // for (const stateObj of this.accountDetails[1]) {
        //   if (stateObj.title !== 'Name of Broker' && stateObj?.value?.length) {
        //     stateObj.value = stateObj.value.toLowerCase();
        //     if (!this.validateEmail(stateObj.value)) {
        //       isValid = false;
        //       toasterMSG = `Please Enter Valid ${stateObj.title}`;
        //       isEmailEntered = false;
        //       break;
        //     } else {
        //       isEmailEntered = true;
        //     }
        //   }
        // }
        // // As Broker emails are not mandatory fields but if user added broker email then make sure user should add Broker name too
        // if (isEmailEntered) {
        //   if (this.accountDetails[1][0].value === null || this.accountDetails[1][0].value === '') {
        //     isValid = false;
        //     toasterMSG = `Please Enter ${this.accountDetails[1][0].title}`;
        //   }
        // }
        break;
      }
      case 3: {
        for (const stateObj of this.newObj.step_3[0]) {
          if (stateObj.asterisk && stateObj.value === '') {
            isValid = false;
            toasterMSG = stateObj.title + 'Is Missing';
            break;
          }
        }
        break;
      }
      case 4: {
        this.dataScope.forEach((stateObj, index) => {
          if (index !== this.dataScope.length - 1) {
            stateObj.forEach(intObj => {
              if (intObj.isChecked && typeof intObj.value !== 'number') {
                isValid = false;
                toasterMSG = 'Please fill the Checked Fields';
                return false;
              }
            });
          }
        });
        break;
      }
      case 5: {
        this.technical.forEach((stateObj, index) => {
          if (index !== this.technical.length - 1) {
            stateObj.forEach(intObj => {
              if (typeof intObj.value !== 'number' && intObj.asterisk) {
                isValid = false;
                toasterMSG = 'Please Fill The Mandatory Fields';
                return false;
              }
            });
          }
        });
        break;
      }
    }
    if (!isValid && showToaster) {
      this.toastr.info(toasterMSG);
    }
    return isValid;
  }

  /**
   *
   * @param step
   * @param showToaster
   * @returns boolean that tells is the client data is valid or not
   */

  clientValidation(step: number = 1, showToaster: boolean = true): boolean {
    let isValid = true;
    let toasterMSG = '';
    switch (step) {
      case 1: {
        for (const stateObj of this.newObj.step_3[0]) {
          if (stateObj.asterisk && (stateObj.value === null || stateObj.value === '' || stateObj.value === 0)) {
            isValid = false;
            toasterMSG = stateObj.title + ' Is Missing';
            break;
          }
          // if (stateObj?.displayValue?.length > 20) {
          //   isValid = false;
          //   toasterMSG = stateObj?.title + ' Limit Exceeds';
          //   break;
          // }
        }

        // if (isValid) {
        //   for (const stateObj of this.newObj?.step_3[1]) {
        //     if (stateObj?.displayValue?.length > 20) {
        //       isValid = false;
        //       toasterMSG = stateObj?.title + ' Limit Exceeds';
        //       break;
        //     }
        //   }
        // }
        break;
      }
      case 2: {
        for (const appInfo of this.applicantInfo[0]) {
          if (appInfo.asterisk && !appInfo.value) {
            toasterMSG = appInfo.title + ' Is Missing';
            isValid = false;
            break;
          }
          // if (appInfo?.value?.length > 40) {
          //   isValid = false;
          //   toasterMSG = appInfo.title + ' Cannot Be More Than 40 Characters';
          //   break;
          // }
        }
        if (isValid) {
          if (!UtilsService.validateDomain(this.applicantInfo[0][3].value)) {
            toasterMSG = 'Please Enter Valid Website.';
            isValid = false;
            break;
          }
        }
        if (isValid) {
          if (this.applicantInfo[1][0][0].optionList.every(stateObj => !stateObj.isChecked)) {
            isValid = false;
            toasterMSG = 'Please Select Applicant Category';
            break;
          } else {
            const isOtherSelected = this.applicantInfo[1][0][0].optionList[3].isChecked;
            const isDataAdded = this.applicantInfo[1][0][0].optionList[3].value;
            if (isOtherSelected && !isDataAdded) {
              isValid = false;
              toasterMSG = 'Please Fill Applicant Type';
              break;
            }
            // if (isDataAdded?.length > 20) {
            //   isValid = false;
            //   toasterMSG = 'Applicant Type Detail Too Big';
            //   break;
            // }
          }
        }

        if (isValid && !this.applicantInfo[1][1][0].value) {
          isValid = false;
          toasterMSG = 'Please Select Date';
          break;
        }

        if ((isValid && this.applicantInfo[1][2][0].value === null) || this.applicantInfo[1][2][0].value === '') {
          isValid = false;
          toasterMSG = 'Number Of Employees Is Missing';
          break;
        }
        // if (isValid && this.applicantInfo[1][2][0]?.displayValue?.length > 20) {
        //   isValid = false;
        //   toasterMSG = 'Number of Employees Limit Exceed';
        //   break;
        // }
        if (isValid && this.applicantInfo[2][0][0].optionList.every(stateObj => !stateObj.isChecked)) {
          isValid = false;
          toasterMSG = 'Please Select Applicant Is Subsidiary or Not?';
          break;
        } else {
          if (this.applicantInfo[2][0][0].optionList[0].isChecked) {
            if (!this.applicantInfo[2][0][1].value) {
              isValid = false;
              toasterMSG = 'Please Enter Name Of Parent Organization';
              break;
            }
          }
        }
        if (isValid) {
          for (const stateObj of this.applicantInfo[3]) {
            if (Object.prototype.hasOwnProperty.call(stateObj, 'value')) {
              if (!stateObj.asterisk && !stateObj.value) {
                isValid = false;
                toasterMSG = stateObj.title + ' Is Missing';
                break;
              }
              if (stateObj.title.toLowerCase() === 'email') {
                stateObj.value = stateObj.value.toLowerCase();
                if (!this.validateEmail(stateObj.value)) {
                  isValid = false;
                  toasterMSG = 'Please Enter Valid Email';
                  break;
                }
              }
              // if (!stateObj.asterisk && stateObj.title.toLowerCase() !== 'email' && stateObj?.value?.length > 40) {
              //   isValid = false;
              //   toasterMSG = stateObj.title + ' Cannot Be more Than 40 characters';
              //   break;
              // }

              if (stateObj.title.toLowerCase() === 'phone') {
                const alphabetRegex = /[a-zA-Z]/;
                if (alphabetRegex.test(stateObj.value)) {
                  isValid = false;
                  toasterMSG = 'Please Enter Valid Phone Number';
                  break;
                }
              }
            }
          }
        }

        break;
      }

      case 3:
        {
          if (!this.revenueDetails[0].value) {
            isValid = false;
            toasterMSG = 'Please Fill Nature Of Business';
            break;
          }
          for (const stateObj of this.revenueDetails[1].pastMonths[0].revenue) {
            if (stateObj.value === '' || stateObj.value === null) {
              isValid = false;
              toasterMSG = 'Revenue Details of Past Months Is Missing';
              break;
            }
            // if (stateObj?.value?.toString()?.length > 20) {
            //   isValid = false;
            //   toasterMSG = 'Past Months Revenue Details Limit Exceed';
            //   break;
            // }
          }
          if (isValid) {
            for (const stateObj of this.revenueDetails[1].projectedMonths[0].revenue) {
              if (!stateObj.value) {
                isValid = false;
                toasterMSG = 'Revenue Details Of Projected Months Is Missing';
                break;
              }
              // if (stateObj?.value?.toString()?.length > 20) {
              //   isValid = false;
              //   toasterMSG = 'Projected Months Revenue Details Limit Exceed';
              //   break;
              // }
            }
          }
          if (isValid) {
            if (this.revenueDetails[2].optionList.every(stateObj => !stateObj.isChecked)) {
              isValid = false;
              toasterMSG = 'Please Answer Significant Changes';
              break;
            } else {
              if (this.revenueDetails[2].optionList[0].isChecked) {
                if (!this.revenueDetails[4].value) {
                  isValid = false;
                  toasterMSG = 'Please Provide Details Of Significant Changes';
                  break;
                }
              }
            }
          }
          // commenting this because file uploading is not mandatory
          // if (isValid) {
          //   if (this.revenueDetails[5].bodyItems.every(stateObj => !stateObj.isChecked)) {
          //     isValid = false;
          //     toasterMSG = 'Please Upload Atleast One File';
          //     break;
          //   } else {
          //     for (const stateObj of this.revenueDetails[5].bodyItems) {
          //       if (stateObj.isChecked && !stateObj.files.length) {
          //         isValid = false;
          //         toasterMSG = 'Please Upload File Against Selected Option';
          //         break;
          //       }
          //     }
          //   }
          // }
        }
        break;
      case 4:
        {
          for (const stateObj of this.dataInventory[0][0].optionList) {
            if (!stateObj.value) {
              isValid = false;
              toasterMSG = stateObj.toasterString + ' Is Mandatory';
              break;
            }
            // if (stateObj?.value?.length > 40) {
            //   isValid = false;
            //   toasterMSG = stateObj.title + ' Cannot Be More Than 40 Characters';
            //   break;
            // }
          }
          if (isValid) {
            if (this.dataInventory[1][0][0].optionList.every(stateObj => !stateObj.isChecked)) {
              isValid = false;
              toasterMSG = 'Please Select How Records Are Stored';
              break;
            } else {
              if (this.dataInventory[1][0][0].optionList[2].isChecked && !this.dataInventory[1][0][1].value) {
                isValid = false;
                toasterMSG = 'Please Provide Records Storage Details';
                break;
              }
            }
          }
        }
        break;
    }
    if (!isValid && showToaster) {
      this.toastr.info(toasterMSG);
    }
    return isValid;
  }
  /**
   * This function will check only if the eneter data is valid or not during save draft functionality
   * @param step
   * @param showToaster
   * @returns isValid (true or false)
   */

  draftValidation(step: number = 1, showToaster: boolean = true): boolean {
    console.log(step);
    // console.clear();
    const isValid = true;
    const toasterMSG = '';
    if (this.isClient) {
      // switch (step) {
      //   case 1: {
      //     this.newObj.step_3[0].forEach(stateObj => {
      //       if (stateObj?.displayValue?.length > 20) {
      //         isValid = false;
      //         toasterMSG = stateObj?.title + ' Limit Exceeds';
      //         return false;
      //       }
      //     });
      //     this.newObj.step_3[1].forEach(stateObj => {
      //       if (stateObj?.displayValue?.length > 20) {
      //         isValid = false;
      //         toasterMSG = stateObj?.title + 'Limit Exceeds';
      //         return false;
      //       }
      //     });
      //     break;
      //   }
      //   case 2: {
      //     this.applicantInfo[0].forEach(element => {
      //       if (element?.value?.length > 40) {
      //         isValid = false;
      //         toasterMSG = element.title + ' Cannot Be More Than 40 Characters';
      //         return;
      //       }
      //     });
      //     const isOtherSelected = this.applicantInfo[1][0][0].optionList[3].isChecked;
      //     const isDataAdded = this.applicantInfo[1][0][0].optionList[3].value;
      //     if (isDataAdded?.length > 40) {
      //       isValid = false;
      //       toasterMSG = 'Applicant Type Detail Too Big';
      //     }
      //     if (this.applicantInfo[1][2][0]?.displayValue?.length > 20) {
      //       isValid = false;
      //       toasterMSG = 'Number of Employees Limit Exceed';
      //       break;
      //     }
      //     if (this.applicantInfo[2][0][1]?.value?.length > 20) {
      //       isValid = false;
      //       toasterMSG = 'Parent Organization Name Cannot Be More Than 20 Characters';
      //     }
      //     this.applicantInfo[3].forEach(stateObj => {
      //       if (Object.prototype.hasOwnProperty.call(stateObj, 'value')) {
      //         if (stateObj?.value?.length > 40) {
      //           isValid = false;
      //           toasterMSG = 'Contact Information Cannot Be More Than 40 Characters';
      //           return false;
      //         }
      //       }
      //     });
      //     break;
      //   }
      //   case 3:
      //     {
      //       this.revenueDetails[1]?.pastMonths[0]?.revenue?.forEach(stateObj => {
      //         if (stateObj?.value?.length > 20) {
      //           isValid = false;
      //           toasterMSG = 'Past Months Revenue Details Limit Exceed';
      //           return false;
      //         }
      //       });
      //       this.revenueDetails[1]?.projectedMonths[0]?.revenue?.forEach(stateObj => {
      //         if (stateObj?.value?.length > 20) {
      //           isValid = false;
      //           toasterMSG = 'Projected Months Revenue Details Limit Exceed';
      //           return false;
      //         }
      //       });
      //       if (this.revenueDetails[4]?.value?.length > 30) {
      //         isValid = false;
      //         toasterMSG = 'Additional Details too long';
      //       }
      //     }
      //     break;
      //   case 4:
      //     {
      //       this.dataInventory[0][0].optionList.forEach(stateObj => {
      //         if (stateObj?.value?.length > 20) {
      //           isValid = false;
      //           toasterMSG = stateObj?.title + ' Cannot Be More Than 20 Characters';
      //           return;
      //         }
      //       });
      //       if (isValid) {
      //         if (this.dataInventory[1][0][1]?.value?.length > 40) {
      //           isValid = false;
      //           toasterMSG = 'Details too long';
      //         }
      //       }
      //     }
      //     break;
      // }
    } else {
      // switch (step) {
      //   case 1: {
      //     this.organizational.forEach((stateObj, index) => {
      //       if (index < 2) {
      //         // treat them as array
      //         stateObj.forEach(valObj => {
      //           if (typeof valObj.value === 'string') {
      //             if (
      //               (valObj?.title === 'Client Name' || valObj?.title === 'Primary PoC Name') &&
      //               valObj?.value?.length > 20
      //             ) {
      //               isValid = false;
      //               toasterMSG = valObj.title + ' Cannot Be More Than 20 Characters';
      //               return false;
      //             }
      //           } else if (index === 1) {
      //             if (valObj?.displayValue?.length > 20) {
      //               isValid = false;
      //               toasterMSG = valObj?.title + ' Limit Exceeds';
      //               return false;
      //             }
      //           }
      //         });
      //       }
      //     });
      //     break;
      //   }
      //   case 3: {
      //     this.newObj.step_3[0].forEach(stateObj => {
      //       if (stateObj?.displayValue?.length > 20) {
      //         isValid = false;
      //         toasterMSG = stateObj?.title + ' Limit Exceeds';
      //         return false;
      //       }
      //     });
      //     this.newObj.step_3[1].forEach(stateObj => {
      //       if (stateObj?.displayValue?.length > 20) {
      //         isValid = false;
      //         toasterMSG = stateObj?.title + ' Limit Exceeds';
      //         return false;
      //       }
      //     });
      //     break;
      //   }
      // }
    }
    if (!isValid && showToaster) {
      this.toastr.info(toasterMSG);
    }
    return isValid;
  }

  updateCheckBox(titleObj): void {
    if (['Paper', 'Electronic', 'Both'].includes(titleObj.title)) {
      titleObj.isChecked = !titleObj.isChecked;
      const optionList = this.dataInventory[1][0][0].optionList;
      if (optionList[2].isChecked && !titleObj.isChecked) {
        optionList[2].isChecked = false;
      }
      if (titleObj.title === 'Both') {
        optionList[0].isChecked = optionList[1].isChecked = titleObj.isChecked;
      } else {
        const trueCount = optionList.reduce(
          (count, obj) => (obj.title !== 'Both' && obj.isChecked ? count + 1 : count),
          0
        );

        if (trueCount >= 2) {
          optionList.forEach(obj => (obj.isChecked = true));
        }
      }
      if (optionList[2].isChecked) {
        this.dataInventory[1][0][1].asterisk = true;
      } else {
        this.dataInventory[1][0][1].asterisk = false;
      }
    } else {
      titleObj.isChecked = !titleObj.isChecked;
    }
  }
  updateOptions(targetIndex, sectionName: string = '') {
    if (sectionName === 'revenueDetails') {
      this.revenueDetails[2].optionList[targetIndex].isChecked = true;

      this.revenueDetails[2].optionList.forEach((obj, index) => {
        if (index !== targetIndex) {
          obj.isChecked = false;
        }
      });
    } else {
      this.applicantInfo[2][0][0].optionList[targetIndex].isChecked = true;

      this.applicantInfo[2][0][0].optionList.forEach((obj, index) => {
        if (index !== targetIndex) {
          obj.isChecked = false;
          if (obj.title && obj.title.toLowerCase() === 'yes') {
            this.applicantInfo[2][0][1].value = '';
          }
        }
      });
    }
  }

  enableClientButton() {
    if (
      this.organizational[0][0].value.length &&
      this.organizational[0][1].value.length &&
      this.validateEmail(this.organizational[0][2].value)
    ) {
      this.enableClientBtn = false;
    } else {
      this.enableClientBtn = true;
    }
  }
  storeDataInOriginalObj(step: number = 1): void {
    switch (step) {
      case 1: {
        // add the domains if any
        this.organizational[3].domains = [];
        if (this.newActiveDomains && this.newActiveDomains.length) {
          this.newActiveDomains.forEach(domain => {
            if (domain.value && domain.value.trim()) {
              this.organizational[3].domains.push(domain.value.trim());
            }
          });
        }
        break;
      }
      case 3: {
        // calculate all values for Risk Scenarios when updating from admin side
        this.riskScenarioCalc();
        break;
      }
      case 4: {
        // calculate all values for Risk Scenarios when updating from client side
        this.riskScenarioCalc();
        break;
      }
      default:
        break;
    }
  }

  updateStepStatus(step: number = 1, isNext: boolean = true): void {
    const modeStep = JSON.parse(JSON.stringify(step)) - 1;
    if (isNext) {
      switch (step) {
        case 1:
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;

          break;
        case 2:
          this.steps[modeStep - 1].active = true;
          this.steps[modeStep - 1].completed = true;
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;
          break;

        case 3:
          this.steps[modeStep - 1].active = true;
          this.steps[modeStep - 1].completed = true;
          this.steps[modeStep - 2].active = true;
          this.steps[modeStep - 2].completed = true;
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;
          break;
        default:
          this.steps[modeStep - 1].active = true;
          this.steps[modeStep - 1].completed = true;
          this.steps[modeStep - 2].active = true;
          this.steps[modeStep - 2].completed = true;
          this.steps[modeStep - 3].active = true;
          this.steps[modeStep - 3].completed = true;
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;
          break;
      }
    } else {
      // if (step === 1) {
      //   this.steps[step].active = false;
      //   this.steps[step].completed = false;
      // } else {
      //   this.steps[step].active = true;
      //   this.steps[step].completed = false;

      //   this.steps[step - 2].active = true;
      //   this.steps[step - 2].completed = true;
      // }
      // this.steps[step - 1].active = true;
      // this.steps[step - 1].completed = true;

      switch (step) {
        case 1:
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;
          this.steps[modeStep + 1].active = false;
          this.steps[modeStep + 1].completed = false;

          break;
        case 2:
          this.steps[modeStep - 1].active = true;
          this.steps[modeStep - 1].completed = true;
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;
          this.steps[modeStep + 1].active = false;
          this.steps[modeStep + 1].completed = false;
          break;

        case 3:
          this.steps[modeStep - 1].active = true;
          this.steps[modeStep - 1].completed = true;
          this.steps[modeStep - 2].active = true;
          this.steps[modeStep - 2].completed = true;
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;
          this.steps[modeStep + 1].active = false;
          this.steps[modeStep + 1].completed = false;
          break;
        default:
          this.steps[modeStep - 1].active = true;
          this.steps[modeStep - 1].completed = true;
          this.steps[modeStep - 2].active = true;
          this.steps[modeStep - 2].completed = true;
          this.steps[modeStep - 3].active = true;
          this.steps[modeStep - 3].completed = true;
          this.steps[modeStep].active = true;
          this.steps[modeStep].completed = false;
          break;
      }
    }
  }
  updateSelectedFramework(standard: string): void {
    if (this.frameworks.includes(standard)) {
      // remove
      const index = this.frameworks?.findIndex(name => name === standard);
      if (index > -1) {
        this.frameworks.splice(index, 1);
      }
    } else {
      this.frameworks.push(standard);
    }
  }

  checkWidthEllipsis(element): void {
    this.showTextTooltip = element.scrollWidth > element.clientWidth;
  }

  frameworkFilterChange(): void {
    let cacheList = [];
    switch (this.frameworkFilterBy) {
      case 'All':
        cacheList = this.allCompList;
        break;
      case 'On':
        cacheList = JSON.parse(JSON.stringify(this.frameworks));
        break;
      case 'Off':
        cacheList = this.allCompList.filter(name => !this.frameworks.includes(name));
        break;
    }
    if (this.searchKeyword && this.searchKeyword.trim()) {
      if (this.searchKeyword && this.searchKeyword.length > 2) {
        if (this.timerDelay) {
          clearInterval(this.timerDelay);
        }
        this.timerDelay = setTimeout(() => {
          const regex = RegExp(this.searchKeyword.toLowerCase(), 'g');
          this.complianceList = cacheList.filter(name => regex.test(name.toLowerCase()));
        }, 1000);
      } else {
        if (this.timerDelay) {
          clearInterval(this.timerDelay);
        }
        this.complianceList = JSON.parse(JSON.stringify(cacheList));
      }
    } else {
      this.complianceList = JSON.parse(JSON.stringify(cacheList));
    }
  }

  async imgHandler(eventFile: Event): Promise<void> {
    try {
      const eventTarget: HTMLInputElement = eventFile?.target as HTMLInputElement;
      if (eventTarget.files.length === 0) {
        return;
      }
      this.uploadingStart = true;
      this.cachedImage = JSON.parse(JSON.stringify(this.uploadedLogo));
      await this.fileService.verifyUploadFile(eventFile, FileTypeEnum.LOGO);
      const id = this.wizardId ? this.wizardId : uuid();
      this.wizardId = id;
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      setTimeout(async () => {
        this.s3Input = await this.fileService.convertToS3Input(eventFile, id);
        this.uploadedLogo = await this.fileService.getUploadedImg(eventFile);
        this.uploadingStart = false;
        this.newLogoUploaded = true;
      }, 2000);
    } catch (error) {
      this.toastr.error(error);
      eventFile = null;
      this.s3Input = null;
      this.uploadedLogo = this.cachedImage;
      this.newLogoUploaded = false;
      this.uploadingStart = false;
    }
  }

  initWizardData(existingData: any = null): void {
    if (existingData) {
      this.initializeFrameworkList();
      this.stepKeywordProps.forEach((stepName, index) => {
        switch (index) {
          case 2:
            this[stepName] = existingData[stepName];
            break;
          default:
            this[stepName] = existingData[stepName]
              ? JSON.parse(existingData[stepName])
              : this.returnEmptyStepObject(index + 1);
        }
        this.initLocalVariables(index + 1, this[stepName]);
        this.wizardId = existingData.id;
      });
      try {
        if (existingData.domainScanTimes) {
          this.domainScanTimes = JSON.parse(existingData.domainScanTimes);
        }
      } catch (e) {
        this.domainScanTimes = [];
      }
      this.revertToMidMarketObj();
    } else {
      // Init the main Data Object Holders
      this.stepKeywordProps.forEach((stepName, index) => {
        this[stepName] = this.returnEmptyStepObject(index + 1);
        this.initLocalVariables(index + 1);
      });
      this.organizational[2].industry = 'RETAIL';
      this.domainScanTimes = [];
    }
    this.stepNumber = 1;
    this.wizardProgress = 10;
    this.getBrokersFromAccountDetails();
  }

  /**
   * This function will convert our orignal entity wizard object to mid market entity wizard object
   */
  revertToMidMarketObj() {
    // step 1
    this.organizational[1][0] = this.technical[0][2];
    this.organizational[1][1] = this.technical[0][3];
    this.organizational[1][2] = this.technical[0][4];
    this.organizational[1][3] = this.technical[1][5];

    // step 3
    this.newObj.step_3[0][0].displayValue = this.financial[0][0].displayValue;
    this.newObj.step_3[0][0].value = this.financial[0][0].value;
    this.newObj.step_3[0][1].displayValue = this.financial[0][1].displayValue;
    this.newObj.step_3[0][1].value = this.financial[0][1].value;
    this.newObj.step_3[0][2].displayValue = this.technical[0][0].displayValue;
    this.newObj.step_3[0][2].value = this.technical[0][0].value;
    this.newObj.step_3[0][3].displayValue = this.technical[0][1].displayValue;
    this.newObj.step_3[0][3].value = this.technical[0][1].value;
    // this.newObj.step_3[1][0].value = this.dataScope[0][1].value;
    // this.newObj.step_3[1][1].value = this.dataScope[0][3].value;
    // this.newObj.step_3[1][2].value = this.dataScope[1][1].value;
    // this.newObj.step_3[1][3].value = this.dataScope[1][3].value;
  }

  async initLocalVariables(step: number = 1, existing = null): Promise<void> {
    switch (step) {
      case 1:
        if (existing) {
          this.newActiveDomains = [];
          this.oldExistingDomains = [];
          const activeDomains = existing[3].domains;
          if (activeDomains && activeDomains.length) {
            activeDomains.forEach(domain => {
              this.oldExistingDomains.push(domain);
              this.newActiveDomains.push({ value: domain });
            });
          } else {
            this.newActiveDomains = [{ value: '' }];
            this.oldExistingDomains = [];
          }
          this.activeScan = existing[3].isActive;
          const logoFile = existing[2].logoS3;
          this.s3Input = null;
          if (logoFile) {
            this.uploadedLogo = (await this.fileService.downloadFromS3(logoFile)) as string;
            this.newLogoUploaded = true;
          } else {
            this.newLogoUploaded = false;
            this.uploadedLogo = null;
          }
        } else {
          this.newActiveDomains = [{ value: '' }];
          this.activeScan = false;

          this.newLogoUploaded = false;
          this.s3Input = null;
          this.uploadedLogo = null;
        }
        break;
      case 3:
        this.frameworkFilterBy = 'All';
        this.searchKeyword = '';
        this.complianceList = [...this.allCompList];
        break;
      case 6:
        this.detailOpened = 0;
        this.showAdditionalDetails = false;
        this.displayAdd = null;
        this.showTotalRisk = false;
        this.projectedInherent = 0;
        break;
    }
    // step 3 local vars
  }
  returnEmptyStepObject(step: number = 1): any {
    let dataObj = null;
    switch (step) {
      case 1:
        dataObj = [
          [
            {
              asterisk: true,
              title: 'Client Name',
              value: '',
              disabled: false,
            },
            {
              asterisk: true,
              title: 'Primary PoC Name',
              value: '',
              disabled: this.isUpperdeck,
            },
            {
              asterisk: true,
              title: 'Primary PoC Email',
              value: '',
              disabled: this.isUpperdeck,
            },
          ],
          [
            {
              asterisk: true,
              displayValue: '2,000',
              showDollar: true,
              title: 'Cost Per Employee',
              value: 2000,
            },
            {
              asterisk: true,
              displayValue: '15,000',
              showDollar: true,
              title: 'Cost Per Server',
              value: 15000,
            },
            {
              asterisk: true,
              displayValue: '2,000',
              showDollar: true,
              title: 'Cost Per Workstation',
              value: 2000,
            },
            {
              asterisk: true,
              displayValue: '20,149',
              title: 'PR and Response Expenses',
              value: 20149,
            },
          ],
          [
            {
              title: 'Active Scan',
              domains: [],
              isActive: false,
            },
          ],
          [
            {
              frameworks: [],
            },
          ],
        ];

        break;
      case 2:
        dataObj = [
          [
            { title: 'Annual Revenue', displayValue: '', value: null, asterisk: true, showDollar: true },
            {
              title: 'Annual Cost of Goods and/or Services',
              displayValue: '',
              value: null,
              asterisk: true,
              showDollar: true,
            },
            { title: 'Annual Selling and Administration', displayValue: '', value: null, asterisk: false },
            { title: 'Other Expenses', displayValue: '', value: null, asterisk: false },
          ],
          [
            { title: 'Reputation Cost', displayValue: '', value: null, asterisk: false, showDollar: true },
            { title: 'Length of Extended Loss (Days)', displayValue: '', value: null, asterisk: false },
            { title: 'Maximum Reputational Risk', displayValue: '', value: null, asterisk: false, showDollar: true },
          ],
          {
            isValid: false,
          },
        ];
        break;
      case 3:
        dataObj = [];
        this.selectedRiskFramework = this.riskFrameWorkOpt?.length
          ? this.riskFrameWorkOpt[0]?.key
          : 'PANEL_APPLICATION';
        break;
      case 4:
        dataObj = [
          [
            { title: 'Number of External PII Records', value: null, asterisk: false, isChecked: false },
            { title: 'Number of  PII Records', value: null, asterisk: false, isChecked: false },
            { title: 'Number of External PHI Records', value: null, asterisk: false, isChecked: false },
            { title: 'Number of  PHI Records', value: null, asterisk: false, isChecked: false },
          ],
          [
            { title: 'Number of External PIFI Records', value: null, asterisk: false, isChecked: false },
            { title: 'Number of  PIFI Records', value: null, asterisk: false, isChecked: false },
            { title: 'Number of External PCI Records', value: null, asterisk: false, isChecked: false },
            { title: 'Number of  PCI Records', value: null, asterisk: false, isChecked: false },
          ],
          {
            isValid: false,
          },
        ];
        break;
      case 5:
        dataObj = [
          [
            { title: 'Number of Servers', displayValue: '', value: null, asterisk: true },
            { title: 'Number of Workstations', displayValue: '', value: null, asterisk: true },
            {
              title: 'Cost Per Employee',
              displayValue: '',
              value: 2000,
              asterisk: false,
              showDollar: true,
            },
            { title: 'Cost Per Server', displayValue: '', value: 15000, asterisk: true, showDollar: true },
            { title: 'Cost Per Workstation', displayValue: '', value: 2000, asterisk: true, showDollar: true },
          ],
          [
            { title: 'Property and Equipment', displayValue: '', value: null, asterisk: false },
            { title: 'Percent of Equipment Budget', displayValue: '', value: 0.05, asterisk: false, showPercent: true },
            { title: 'Crown Jewel IT Assets', displayValue: '', value: null, asterisk: false },
            { title: 'Replacement of Hardware', displayValue: '', value: null, asterisk: false },
            { title: 'Forensic and Remediation', displayValue: '', value: null, asterisk: false },
            { title: 'PR and Response Expenses', displayValue: '', value: 15000, asterisk: true },
          ],
          {
            isValid: false,
          },
        ];
        break;
      case 6:
        dataObj = [
          {
            title: 'Data Breach',
            inherent: 0,
            residual: 0,
            moreChildren: [
              { name: 'Forensic Investigation', value: 0 },
              { name: 'Security Remediation', value: 0 },
              { name: 'Data Breach Coach', value: 0 },
              { name: 'Notification Letters', value: 0 },
              { name: 'Call Center', value: 0 },
              { name: 'Credit Monitoring', value: 0 },
              { name: 'Public Relations Service', value: 0 },
              { name: 'FTC Fines', value: 0 },
              { name: 'HHS Fines', value: 0 },
              { name: 'State AG Fines', value: 0 },
              { name: 'PCI Fines', value: 0 },
              { name: 'Legal Defense & Damages', value: 0 },
              { name: 'Bank Card Reissuance', value: 0 },
            ],
          },
          {
            title: 'Business Interruption',
            inherent: 0,
            residual: 0,
            moreChildren: [
              { name: 'Income Loss', value: 0 },
              { name: 'System Reconstruction', value: 0 },
              { name: 'Extra Expense', value: 0 },
            ],
          },
          {
            title: 'Hybrid',
            inherent: 0,
            residual: 0,
            moreChildren: [
              { name: 'Forensic Investigation', value: 0 },
              { name: 'Security Remediation', value: 0 },
              { name: 'Data Breach Coach', value: 0 },
              { name: 'Notification Letters', value: 0 },
              { name: 'Call Center', value: 0 },
              { name: 'Credit Monitoring', value: 0 },
              { name: 'Public Relations Service', value: 0 },
              { name: 'FTC Fines', value: 0 },
              { name: 'HHS Fines', value: 0 },
              { name: 'State AG Fines', value: 0 },
              { name: 'PCI Fines', value: 0 },
              { name: 'Legal Defense & Damages', value: 0 },
              { name: 'Bank Card Reissuance', value: 0 },
              { name: 'Income Loss', value: 0 },
              { name: 'System Reconstruction', value: 0 },
              { name: 'Extra Expense', value: 0 },
            ],
          },
        ];
        dataObj[2].moreChildren = this.beecherHybridTitles();
        dataObj[0].moreChildren.splice(1, 1);
        break;
      case 7:
        dataObj = [
          [
            {
              title: 'Account Type',
              value: '',
              asterisk: false,
              isCalendar: false,
              isDropDownOpened: false,
              list: this.oldAccountTypeList,
            },
            { title: 'Renewal Date', value: null, asterisk: false, isCalendar: true, isCalendarOpen: false },
            { title: 'Submission Target Date', value: null, asterisk: false, isCalendar: true, isCalendarOpen: false },
            {
              title: 'Pre-Submission Kickoff Target',
              value: null,
              asterisk: false,
              isCalendar: true,
              isCalendarOpen: false,
            },
            {
              title: 'Producer',
              value: null,
              asterisk: false,
              isCalendar: false,
              isDropDownOpened: false,
            },
          ],
          [
            // {
            //   title: 'Name of Broker',
            //   value: null,
            //   asterisk: false,
            //   isCalendar: false,
            //   isDropDownOpened: false,
            // },
            // {
            //   title: 'Broker# 1 Email',
            //   value: null,
            //   asterisk: false,
            //   isCalendar: false,
            //   isDropDownOpened: false,
            // },
            // {
            //   title: 'Broker# 2 Email',
            //   value: null,
            //   asterisk: false,
            //   isCalendar: false,
            //   isDropDownOpened: false,
            // },
            {
              title: 'Risk Analyst Email',
              value: null,
              asterisk: false,
              isCalendar: false,
              isDropDownOpened: false,
            },
            { title: 'Notes', value: null, asterisk: false, isCalendar: true },
          ],
        ];
        break;
      case 8:
        dataObj = [
          [
            {
              title: 'Compensation Amount',
              type: 'number',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'Compensation Type',
              type: 'string',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'Primary Carrier',
              type: 'string',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'Total Limit',
              type: 'number',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'Total Premium',
              type: 'number',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'Primary Limit',
              type: 'number',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'Primary Premium',
              type: 'number',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'Retention',
              type: 'string',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'RW Sublimit',
              type: 'number',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
            {
              title: 'RW Co-Insurance',
              type: 'number',
              current: { displayValue: null, value: null },
              previous: { displayValue: null, value: null },
            },
          ],
          [
            {
              title: 'Premium To Comp',
              type: '%',
              current: 0,
              previous: 0,
            },
            {
              title: 'Excess Limit',
              type: '$',
              current: 0,
              previous: 0,
            },
            {
              title: 'Excess Premium',
              type: '$',
              current: 0,
              previous: 0,
            },
            {
              title: 'Total PPM',
              type: '$',
              current: 0,
              previous: 0,
            },
            {
              title: 'Primary PPM',
              type: '$',
              current: 0,
              previous: 0,
            },
            {
              title: 'Excess PPM',
              type: '$',
              current: 0,
              previous: 0,
            },
            {
              title: 'Average ILF',
              type: '%',
              current: 0,
              previous: 0,
            },
          ],
        ];
        break;
      case 9:
        dataObj = [
          [
            { title: 'Name of Applicant', displayValue: '', value: null, asterisk: true, type: 'string' },
            { title: 'Address', displayValue: '', value: null, asterisk: true, type: 'string' },
            { title: 'Subsidiaries', displayValue: '', value: null, asterisk: false, type: 'string' },
            { title: 'Websites', displayValue: '', value: null, asterisk: true, type: 'string' },
          ],
          [
            [
              {
                title: 'Applicant is an:',
                asterisk: true,
                optionList: [
                  { title: 'Individual', isChecked: false },
                  { title: 'Corporation', isChecked: false },
                  { title: 'Partnership', isChecked: false },
                  { value: '', isChecked: false, inputField: true, placeholder: 'Other' },
                ],
              },
            ],
            [
              {
                title: 'Date Applicant was Established',
                value: null,
                asterisk: true,
                isCalendar: true,
                isCalendarOpen: false,
              },
            ],
            [{ title: 'Number of Employees', displayValue: '', value: null, asterisk: true }],
          ],
          [
            [
              {
                title: 'Is the Applicant a subsidiary of another organization?',
                asterisk: true,
                optionList: [
                  { title: 'Yes', isChecked: false },
                  { title: 'No', isChecked: false },
                ],
              },
              {
                title: 'If so, what is the name of the parent organization?',
                displayValue: '',
                value: null,
                asterisk: true,
              },
            ],
          ],
          [
            {
              title: 'Cyber Security Contact Information (CISO/RM/Equivalent):',
              asterisk: true,
            },
            { title: 'Name', displayValue: '', value: null, asterisk: false, type: 'string' },
            { title: 'Email', displayValue: '', value: null, asterisk: false, type: 'string' },
            { title: 'Phone', displayValue: '', value: null, asterisk: false, type: 'string' },
          ],
        ];
        break;
      case 10:
        dataObj = [
          {
            title: 'Nature of Business Operations of the Applicant:',
            displayValue: '',
            value: null,
            asterisk: true,
          },
          {
            title: 'Revenue Information (USD)',
            displayValue: '',
            value: null,
            pastMonths: [
              {
                title: 'Past 12 Months',

                revenue: [
                  {
                    title: 'US',
                    displayValue: '',
                    value: null,
                    asterisk: true,
                  },
                  {
                    title: 'Foreign',
                    displayValue: '',
                    value: null,
                    asterisk: true,
                  },
                ],
              },
            ],
            projectedMonths: [
              {
                title: 'Projected 12 Months',
                revenue: [
                  {
                    title: 'US',
                    displayValue: '',
                    value: null,
                    asterisk: true,
                  },
                  {
                    title: 'Foreign',
                    displayValue: '',
                    value: null,
                    asterisk: true,
                  },
                ],
              },
            ],
          },
          {
            title:
              'Are any significant changes in the nature or size (20%+ increase in revenue) anticipated over the next 12 months?',
            asterisk: true,
            optionList: [
              { title: 'Yes', isChecked: false },
              { title: 'No', isChecked: false },
            ],
          },
          {
            title: 'If yes, please provide additional details?',
            displayValue: '',
            value: null,
            asterisk: true,
          },
          {
            title: '',
            displayValue: '',
            value: null,
            asterisk: false,
          },
          {
            tableHeader: ['Provide Copies of the following', 'Upload'],
            bodyItems: [
              {
                title: 'Most recently audited financials',
                isChecked: false,
                svgName: 'upload_collection',
                isFileUploaded: false,
                fileCount: 0,
                fileUploadedSvg: '',
                files: [],
              },
              {
                title:
                  // eslint-disable-next-line max-len
                  'Most recent organizational structure (inclusive of all entities desiring coverage outlining relationships by ownership percentage)',
                isChecked: false,
                svgName: 'upload_collection',
                isFileUploaded: false,
                fileCount: 0,
                fileUploadedSvg: '',
                files: [],
              },
              {
                title: 'Breakdown of revenues produced in each country (last fiscal year)',
                isChecked: false,
                svgName: 'upload_collection',
                isFileUploaded: false,
                fileCount: 0,
                fileUploadedSvg: '',
                files: [],
              },
              {
                title: 'Copy of standard contract or MSA (applicable if applying for technology errors & omissions)',
                isChecked: false,
                svgName: 'upload_collection',
                isFileUploaded: false,
                fileCount: 0,
                fileUploadedSvg: '',
                files: [],
              },
              {
                title: 'List of top 10 supply chain vendors (if applicable)',
                isChecked: false,
                svgName: 'upload_collection',
                isFileUploaded: false,
                fileCount: 0,
                fileUploadedSvg: '',
                files: [],
              },
            ],
          },
        ];
        break;
      case 11:
        dataObj = [
          [
            {
              title:
                // eslint-disable-next-line max-len
                'How many unique records do you, or a third party on your behalf, hold for each type of information below (collected, stored, processed, or transacted annually)? If a record could fall into more than one category, count it toward the most appropriate category.',
              displayValue: '',
              value: null,
              asterisk: true,
              optionList: [
                {
                  title: 'a) Payment Card Information (PCI):',
                  displayValue: '',
                  value: null,
                  asterisk: false,
                  toasterString: 'Payment Card Information',
                  isTooltip: true,
                  toolTipContent: `A PCI DSS record typically includes sensitive credit card 
                    information such as the Primary Account Number (PAN), cardholder name, expiration date, 
                    security code, service code, and magnetic stripe data.`,
                },
                {
                  title: 'b) Protected Health Information (PHI):',
                  displayValue: '',
                  value: null,
                  asterisk: false,
                  toasterString: 'Protected Health Information',
                  isTooltip: true,
                  toolTipContent: `A PHI (Protected Health Information) record typically includes sensitive health-related data, 
                    such as patient identifiers, medical history, treatment information, and any other information 
                    that can be used to identify an individual’s health status.`,
                },
                {
                  title: 'c) Biometric Data:',
                  displayValue: '',
                  value: null,
                  asterisk: false,
                  toasterString: 'Biometric Data:',
                  isTooltip: true,
                  toolTipContent: `A PII (Personally Identifiable Information) record typically includes personal data 
                    like names, addresses, social security numbers, email addresses, phone numbers, 
                    or any information that can be used to identify or contact an individual.`,
                },
                {
                  title: 'd) Personally Identifiable Information (PII)',
                  displayValue: '',
                  value: null,
                  asterisk: false,
                  toasterString: 'Personally Identifiable Information',
                  isTooltip: true,
                  toolTipContent: `Biometric data records typically include unique physical or behavioral
                    characteristics of individuals, such as fingerprints, iris scans, 
                    facial recognition data, voiceprints, or other biologically-derived traits used for identity verification.`,
                },
              ],
            },
          ],
          [
            [
              {
                title: 'How are records stored?',
                asterisk: true,
                optionList: [
                  { title: 'Paper', isChecked: false },
                  { title: 'Electronic', isChecked: false },
                  { title: 'Both', isChecked: false },
                ],
              },
              {
                title: 'a) If both, please provide further details',
                displayValue: '',
                value: null,
                asterisk: false,
              },
            ],
          ],
        ];
    }
    return dataObj;
  }

  beecherHybridTitles(): any {
    return [
      { name: 'Ransomware', value: 0 },
      { name: 'Data Breach Coach', value: 0 },
      { name: 'Public Relations Service', value: 0 },
      { name: 'Forensic Investigation', value: 0 },
      { name: 'Notification Letters', value: 0 },
      { name: 'Call Center', value: 0 },
      { name: 'Credit Monitoring', value: 0 },
      { name: 'FTC Fines', value: 0 },
      { name: 'HHS Fines', value: 0 },
      { name: 'State AG Fines', value: 0 },
      { name: 'PCI Fines', value: 0 },
      { name: 'Legal Defense & Damages', value: 0 },
      { name: 'Bank Card Reissuance', value: 0 },
      { name: 'Income Loss', value: 0 },
      { name: 'System Reconstruction', value: 0 },
    ];
  }

  initializeFrameworkList(): void {
    // handling the scenario for edit case.
    // if the wizard contains the data, value of risk framework will be set accordingly.
    if (this.wizardData?.riskFramework) {
      this.selectedRiskFramework = this.wizardData.riskFramework;
    } else {
      this.selectedRiskFramework = this.riskFrameWorkOpt?.length ? this.riskFrameWorkOpt[0].key : 'PANEL_APPLICATION';
    }
  }

  draftSavingBoolean(isDraftSaving): boolean {
    if (this.fromUpperDeck || (this.wizardData && this.wizardData.userId && !this.wizardData.isDraft)) {
      return false;
    } else {
      return isDraftSaving;
    }
  }

  async saveWizardDraft(
    isDraftSaving: boolean = true,
    midMarketObj = {},
    inviteClient: boolean = true,
    nextClicked: boolean = false
  ): Promise<UpdateEntityWizardInput> {
    this.addBrokersToAccountDetails(); //* Saves brokers to account details object when save draft/next is clicked
    // let isContinue = true;
    if (inviteClient && !this.isClient) {
      // let isEmailEntered = false;
      // for (const stateObj of this.accountDetails[1]) {
      //   if (stateObj.title !== 'Name of Broker' && stateObj?.value?.length) {
      //     stateObj.value = stateObj.value.toLowerCase();
      //     if (!this.validateEmail(stateObj.value)) {
      //       this.toastr.info(`Please Enter Valid ${stateObj.title}`);
      //       isContinue = false;
      //       isEmailEntered = false;
      //     } else {
      //       isEmailEntered = true;
      //     }
      //   }
      // }
      // if (isEmailEntered) {
      //   if (this.accountDetails[1][0].value === null || this.accountDetails[1][0].value === '') {
      //     isContinue = false;
      //     this.toastr.info(`Please Enter ${this.accountDetails[1][0].title}`);
      //   }
      // }
    }
    // if (isContinue) {
    if (isDraftSaving) {
      const isValid = this.draftValidation(this.stepNumber);
      this.draftValid = isValid;
      if (!isValid) {
        return;
      }
    }
    if (isDraftSaving && !nextClicked) {
      this.toastr.info('Saving Draft . . .');
    }
    try {
      if (this.wizardData && this.wizardData.score) {
        delete this.wizardData.score.__typename;
      }
      if (!this.isClient && this.s3Input && this.stepNumber === 1) {
        const data = await this.fileService.uploadToS3(this.s3Input);
        this.organizational[2].logoS3 = this.s3Input ? data : this.organizational[2].logoS3;
        if (this.wizardData && this.wizardData.rootEntity) {
          this.wizardData.rootEntity.logo = this.s3Input ? data : this.organizational[2].logoS3;
        }
      }
      let parentId = this.entityService.getLayerId(ClientSubMenuEnum.REGION.toUpperCase());
      if (!parentId) {
        parentId = 'root';
      }
      const domainScanTimes = this.updateDomainScanTimes(isDraftSaving);
      const domains = this.checkNewDomainsAdded(true) as string[];
      if (isDraftSaving) {
        midMarketObj = this.createWizardObj();
      }
      this.entityWizardWrapper(midMarketObj, isDraftSaving);
      // save draft case handling from management screen
      if (
        this.fromClientScreen &&
        this.wizardData &&
        (this.wizardData.rootEntity || this.wizardData.userId) &&
        !this.wizardData.isDraft
      ) {
        this.riskScenarioCalc();
      }
      this.storeDataInOriginalObj(this.stepNumber);
      const id = this.wizardData && this.wizardData.id ? this.wizardData.id : this.wizardId ? this.wizardId : uuid();
      // handle the scenerio if we sent inivitation to client and then save the draft
      this.userId = this.wizardData && this.wizardData?.userId ? this.wizardData?.userId : '';
      if (inviteClient && !this.isClient) {
        const name = this.organizational[0][1]?.value;
        const email = this.organizational[0][2]?.value;
        // get All Roles and filter out entity-leader role id
        let defaultRoles = this.userService.getDefault_Roles();
        if (!defaultRoles.length) {
          defaultRoles = await this.userService.getDefaultRoles();
        }
        const entityLeader: any = defaultRoles?.find(
          role => role.name?.toLowerCase() === AppLevelRoleEnum.ENTITY_LEADER.toLowerCase()
        );
        const user = this.initUser(name, email, !isDraftSaving, id, entityLeader.id);
        const showTost = inviteClient && isDraftSaving;
        await this.addUser(user, showTost);
      }
      // files Removal and uplaoding
      if ((this.isClient && this.stepNumber === 3) || (this.isClient && this.fromUpperDeck && this.stepNumber === 3)) {
        if (this.removedFiles.length) {
          this.removedFiles.map(file => {
            if (Object.keys(file).length && file.url) {
              const filePath = {
                key: `public/${file.url}`,
              };
              try {
                this.fileService.deleteFromS3(filePath);
              } catch (err) {
                this.toastr.error('File Deletion Failed');
                console.log('Error', err);
              }
            }
          });
        }
        try {
          this.uploadFilesToS3(id);
        } catch (error) {
          console.log('Error', error);
        }
      }
      const myData: CreateEntityWizardInput = {
        id,
        isDraft: this.draftSavingBoolean(isDraftSaving),
        organizational: JSON.stringify(this.organizational),
        financial: JSON.stringify(this.financial),
        frameworks: this.frameworks,
        dataScope: JSON.stringify(this.dataScope),
        technical: JSON.stringify(this.technical),
        riskScenarios: JSON.stringify(this.riskScenarios),
        accountDetails: JSON.stringify(this.accountDetails),
        currentYear: JSON.stringify(this.currentYear),
        applicantInfo: this.applicantInfo ? JSON.stringify(this.applicantInfo) : null,
        revenueDetails: this.revenueDetails ? JSON.stringify(this.revenueDetails) : null,
        dataInventory: this.dataInventory ? JSON.stringify(this.dataInventory) : null,
        parentId,
        score: this.wizardData?.score ?? {
          total: 0,
          collection: 0,
          remediation: 0,
        },
        inherent: this.projectedInherent ? this.projectedInherent + '' : '0',
        residual: this.projectedInherent ? this.projectedInherent + '' : '0',
        domainScanned: !isDraftSaving && this.activeScan && domains.length > 0,
        domainScanTimes,
        riskFramework: this.selectedRiskFramework ? this.selectedRiskFramework : 'MID_MARKET_APPLICATION',
        clientDomain: DomainFrameworkService.getDomain(),
        userId: this.userId,
      };
      this.wizardId = myData.id;
      let returnedObj = null;
      if (this.wizardData || this.isNextClicked) {
        myData.updatedBy = this.isClient ? 'client' : 'admin';
        const final: any = await this.entityService.updateResidualScore(this.wizardId, false, myData);
        if (final && final.score) {
          delete final.score.__typename;
        }
        const maxRetries = 2;
        let retryCount = 0;
        while (retryCount < maxRetries) {
          returnedObj = await this.entityService.updateEntityWizard(final, parentId);
          if (retryCount) {
            console.log('Retrying...');
          }
          if (returnedObj) {
            break;
          }
          retryCount = retryCount + 1;
        }
      } else {
        this.isNextClicked = true;
        returnedObj = await this.entityService.createNewWizard(myData);
      }
      if (isDraftSaving) {
        delete returnedObj?.__typename;
        this.wizardData = returnedObj ? returnedObj : null;
        returnedObj.userId = this.userId;
        this.draftSaved.emit(returnedObj);
        if (!nextClicked) {
          this.toastr.success('Draft Saved!');
        }
      } else {
        return returnedObj;
      }
      this.userId = '';
      // close the modal after saving draft or send invite to client
      if (!this.isClient && !nextClicked) {
        this.closeModal();
      }
    } catch (e) {
      console.log(e);
      if (isDraftSaving) {
        this.toastr.error('Could Not Save Draft');
      } else {
        this.toastr.error('Could Not Complete Process');
        this.closeModal();
      }
    }
    // }
  }

  updateOrganizational(isDraftSaving: boolean = false) {
    const domains = [];
    this.newActiveDomains.forEach(domain => {
      domains.push(domain.value);
    });
    const data = [
      { title: 'Line of Business', value: null, asterisk: false },
      {
        title: 'Number of Employees',
        displayValue: '',
        value: null,
        asterisk: false,
      },
      { title: 'Number of Customers', displayValue: '', value: null, asterisk: false },
      { title: 'Number of Vendors', displayValue: '', value: null, asterisk: false },
    ];
    const obj = { title: 'Other Contact', value: '', asterisk: false };
    // pushinh organizational data to make the object as our old entity wizard data object
    if (!isDraftSaving) {
      this.organizational[0].push(obj);
      this.organizational[1] = data;
    }
    this.organizational[2] = { industry: this.organizational[2].industry, logoS3: this.organizational[2].logoS3 };
    this.organizational[3] = { domains, isActive: this.activeScan };
    this.organizational[4] = { isValid: this.checkValidation(1, false) };
    delete this.organizational[1]?.industry;
    delete this.organizational[1]?.logoS3;
  }

  entityWizardWrapper(midMarketObj: any = {}, isDraftSaving) {
    // step 1
    this.updateOrganizational(isDraftSaving);
    this.technical[0][2].displayValue = midMarketObj.step_1[2][0].displayValue;
    this.technical[0][2].value = midMarketObj.step_1[2][0].value;

    this.technical[0][3].displayValue = midMarketObj.step_1[2][1].displayValue;
    this.technical[0][3].value = midMarketObj.step_1[2][1].value;

    this.technical[0][4].displayValue = midMarketObj.step_1[2][2].displayValue;
    this.technical[0][4].value = midMarketObj.step_1[2][2].value;

    this.technical[1][5].displayValue = midMarketObj.step_1[2][3].displayValue;
    this.technical[1][5].value = midMarketObj.step_1[2][3].value;

    // step 3
    this.financial[0][0].displayValue = midMarketObj.step_3[0][0].displayValue;
    this.financial[0][0].value = midMarketObj.step_3[0][0].value;

    this.financial[0][1].displayValue = midMarketObj.step_3[0][1].displayValue;
    this.financial[0][1].value = midMarketObj.step_3[0][1].value;
    this.financial[2].isValid = this.checkValidation(3, false);

    this.technical[0][0].displayValue = midMarketObj.step_3[0][2].displayValue;
    this.technical[0][0].value = midMarketObj.step_3[0][2].value;

    this.technical[0][1].displayValue = midMarketObj.step_3[0][3].displayValue;
    this.technical[0][1].value = midMarketObj.step_3[0][3].value;
    this.technical[2].isValid = this.checkValidation(3, false);

    // this.dataScope[0][1].value = midMarketObj.step_3[1][0].value;
    // this.dataScope[0][1].isChecked = true;

    // this.dataScope[0][3].value = midMarketObj.step_3[1][1].value;
    // this.dataScope[0][3].isChecked = true;

    // this.dataScope[1][1].value = midMarketObj.step_3[1][2].value;
    // this.dataScope[1][1].isChecked = true;

    // this.dataScope[1][3].value = midMarketObj.step_3[1][3].value;
    // this.dataScope[1][3].isChecked = true;
    this.dataScope[2].isValid = this.checkValidation(3, false);
  }

  /**
   * The function return a stringified array of objects containing domain scanning times
   * @param isDraft Is the wizard a draft entity or not
   * @returns a stringified array of domain scan times
   */
  updateDomainScanTimes(isDraft: boolean): string {
    const scanTimes = [];
    const date = Date.now();
    // making sure it is not draft and active scan is true
    if (!isDraft && this.activeScan) {
      const doms = this.checkNewDomainsAdded(true) as string[];
      if (doms && doms.length) {
        if (this.domainScanTimes && this.domainScanTimes.length) {
          doms.forEach(dom => {
            // sanitizing the domain name then finding that domain
            const sanDom = UtilsService.getSanitizedDomain(dom);
            const idx = this.domainScanTimes?.findIndex(obj => obj.name === sanDom);
            if (idx > -1) {
              this.domainScanTimes[idx].updated = date;
              scanTimes.push({ ...this.domainScanTimes[idx] });
            } else {
              scanTimes.push({ name: sanDom, created: date, updated: date });
            }
          });
        } else {
          // if there are no existing domain scan object , create new ones
          doms.forEach(dom => {
            const sanDom = UtilsService.getSanitizedDomain(dom);
            scanTimes.push({ name: sanDom, created: date, updated: date });
          });
        }
      }
    }
    return JSON.stringify(scanTimes);
  }

  resetDeleteDraft(): void {
    // complete process of wiping the wizard
    this.isLoading = true;
    this.stepKeywordProps.forEach((stepName, index) => {
      this[stepName] = this.returnEmptyStepObject(index + 1);
      this.initLocalVariables(index + 1);
    });
    this.stepNumber = 1;
    this.wizardProgress = 10;
    this.closeDeleteModal();
    this.isLoading = false;
  }

  closeDeleteModal(): void {
    if (this.deleteModalInstance) {
      this.deleteModalInstance.close();
    }
  }

  openFrameworkModal() {
    const modalRef = this.modalService.open(FrameworkModalComponent, this.ngbModalFrameworkModal);
    modalRef.componentInstance.modalInstance = modalRef;
    if (!this.selectedRiskFramework) {
      this.selectedRiskFramework = this.orignamFrameworkList[0]?.key;
    }
    modalRef.componentInstance.frameworkList = this.orignamFrameworkList;
    modalRef.componentInstance.selectedFramework = this.selectedRiskFramework;

    modalRef.componentInstance.modalResult.subscribe(framework => {
      this.selectedRiskFramework = framework;
      const index = this.frameworkList?.findIndex(fw => fw.key === framework);
      const orignalListIndex = this.orignamFrameworkList?.findIndex(fw => fw.key === framework);

      if (index > 2) {
        const temp = this.frameworkList[0];
        this.frameworkList[0] = this.frameworkList[index];
        this.frameworkList[index] = temp;
        this.frameworkList[0].selected = true;
        this.orignamFrameworkList[orignalListIndex].selected = true;
      }
    });
  }

  openDeletePopUp(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>): void {
    // Function to open the modal pop up to edit entity
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalDeleteWizard);
    const compInstance = modalRef.componentInstance;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
    this.deleteModalInstance = modalRef;
  }

  addNewCustomFramework(): void {
    this.editingFrames.push({ isToggled: false, value: null });
  }
  checkEnteredClicked(val, index, event): void {
    if (event.key === 'Enter') {
      this.onSavingCustomFrame(val, index);
    }
  }
  onSavingCustomFrame(customName, index): void {
    if (customName && customName.trim()) {
      this.removeEditableFramework(index, customName);
    }
  }
  removeEditableFramework(index: number, addVal: string = null): void {
    const cache = JSON.parse(JSON.stringify(this.editingFrames));
    cache.splice(index, 1);
    this.editingFrames = JSON.parse(JSON.stringify(cache));
    if (addVal) {
      const name = addVal.trim();
      this.customCompList.unshift(name);
      this.complianceList.unshift(name);
      this.allCompList.unshift(name);
      this.frameworks.push(name);
      this.saveCustomFrameworkOnS3();
    }
  }
  async saveCustomFrameworkOnS3(): Promise<any[]> {
    const config = {
      contentType: 'application/zip',
      download: true,
      level: 'public',
    };

    const path = 'BNB_CUSTOM_FRAMEWORKS/customFrameworks.json';
    const obj = { customList: this.complianceList };
    await this.fileService.uploadToS3CustomPath(path, obj, config);
    return [];
  }

  async saveCustomBusinessOfLinesOnS3(): Promise<any[]> {
    const config = {
      contentType: 'application/zip',
      download: true,
      level: 'public',
    };
    const path = 'BNB_CUSTOM_FRAMEWORKS/businessOfLines.json';
    const obj = { businessList: this.customBusinessList };
    await this.fileService.uploadToS3CustomPath(path, obj, config);
    return [];
  }
  async getCustomFrameworksFromS3(): Promise<any[]> {
    try {
      const res = await this.fileService.checkIfPathExistsS3('BNB_CUSTOM_FRAMEWORKS/customFrameworks.json');
      if (res && res.length) {
        const pathLink = await this.fileService.downloadFileFromS3(
          'public/BNB_CUSTOM_FRAMEWORKS/customFrameworks.json'
        );
        const cacheList = JSON.parse(await this.fileService.getFile(pathLink));
        return cacheList.customList;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }

  async getCustomBusinessOfLinesFromS3(): Promise<any[]> {
    try {
      const res = await this.fileService.checkIfPathExistsS3('BNB_CUSTOM_FRAMEWORKS/businessOfLines.json');
      if (res && res.length) {
        const pathLink = await this.fileService.downloadFileFromS3('public/BNB_CUSTOM_FRAMEWORKS/businessOfLines.json');
        const cacheList2 = JSON.parse(await this.fileService.getFile(pathLink));
        return cacheList2.businessList;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }

  showDeleteConfirmationPop(): void {
    setTimeout(() => {
      this.closeDeleteModal();
    }, 0);
    const modalRef = this.modalService.open(DeleteModalComponent, this.ngbModalDeleteConfirmation);
    modalRef.componentInstance.titleText = '';
    modalRef.componentInstance.deleteEntityModal = true;
    modalRef.componentInstance.entityType = '';
    modalRef.componentInstance.removeLabelText = 'Wizard';
    modalRef.componentInstance.isConfirm2Required = false;
    modalRef.componentInstance.deleteFramework.subscribe((deleteConfirm: boolean) => {
      if (deleteConfirm) {
        this.isLoading = true;
        this.deleteWizardData();
      }
    });
  }

  async deleteWizardData(): Promise<void> {
    try {
      this.toastr.info('Deleting Wizard . . .');
      if (this.wizardId) {
        await Promise.all([
          this.updateMidMarketUser(),
          this.entityService.deleteEntity(this.wizardId),
          this.entityService.deleteWizardAndRoot(
            this.wizardId,
            this.entityService.getLayerId(ClientSubMenuEnum.REGION.toUpperCase()),
            this.wizardData?.isDraft
          ),
        ]);
        this.deleteCalled.emit(this.wizardId);
      }

      this.toastr.success('Successfully Deleted!');
    } catch (e) {
      this.toastr.error('Could Not Delete Wizard');
    }
    this.closeDeleteModal();
    this.isLoading = false;
    this.closeModal();
  }
  /**
   * This function will set the onBoarding status to null if the wizard is deleted
   * so if the assigned user login again it won't see the mid-market pages
   */
  async updateMidMarketUser() {
    try {
      if (this.wizardData?.userId?.length) {
        const params = {
          id: this.wizardData.userId,
          onBoardingStatus: null,
        };
        await this.customApi.UpdateUser(params);
      }
    } catch (e) {
      console.log(e);
    }
  }

  riskScenarioCalc(): void {
    this.projectedInherent = 0;
    this.parsedInherentValues = [];

    // calculating Data Breach Data.
    this.calculateDataBreach();

    // calculating Business Interruption
    this.calculateBusinessInterruption();

    // calculating HYBRID Section
    this.calculateHybrid();

    this.parsedInherentValues.push(this.addCommasIntoNumber(this.projectedInherent));
  }

  calculateDataBreach(): void {
    // get the P2 , PH , PF and PC Record values
    this.numConstant = 4;
    const { P2, PH, PC, PF } = this.getDataScopeValues();
    // Beecher Domain Variables
    const totalRecordsNotPII = PH + PC + PF;
    const annualRev = this.financial[0][0].value ? this.financial[0][0].value : 0;
    const industry = this.organizational[2].industry ? this.organizational[2].industry : null;

    this.numConstant = P2 === 0 ? this.numConstant - 1 : this.numConstant;
    this.numConstant = PH === 0 ? this.numConstant - 1 : this.numConstant;
    this.numConstant = PC === 0 ? this.numConstant - 1 : this.numConstant;
    this.numConstant = PF === 0 ? this.numConstant - 1 : this.numConstant;
    const allObj = {
      FOR_INV: 0,
      SEC_REM: 0,
      DAT_BRE_COA: 0,
      NOT_LET: 0,
      CAL_CEN: 0,
      CRE_MON: 0,
      PUB_REL_SER: 0,
      FTC_FIN: 0,
      HHS_FIN: 0,
      STA_AG_FIN: 0,
      PCI_FIN: 0,
      LEG_DEF_DAM: 0,
      BAN_CAR_RES: 0,
    };
    const allObjAdditional = {
      FOR_INV: {},
      SEC_REM: {},
      DAT_BRE_COA: {},
      NOT_LET: {},
      CAL_CEN: {},
      CRE_MON: {},
      PUB_REL_SER: {},
      FTC_FIN: {},
      HHS_FIN: {},
      STA_AG_FIN: {},
      PCI_FIN: {},
      LEG_DEF_DAM: {},
      BAN_CAR_RES: {},
    } as any;

    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------

    // for beecher case - the calculations are happening on the input
    // element itself , so getting the value from there.
    allObj.FOR_INV = this.technical[1][4].value;
    allObjAdditional.FOR_INV.AR = annualRev;
    allObjAdditional.FOR_INV.IDT = industry;

    // ---------------------------------------------------------------------------
    // -------------------------SECURITY REMEDIATION------------------------------
    // ---------------------------------------------------------------------------
    // includes the PII , PHI , PIFI , Credit Card(PCI) = DataScope
    // for Beecher specific calculations there is no calculation needed , save as 0

    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------
    allObj.SEC_REM = null;

    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------
    const beecherDBC =
      annualRev >= 25000000000
        ? 3253306
        : annualRev >= 1000000000
        ? 389879
        : annualRev >= 100000 &&
          (industry === 'IT' ||
            industry === 'MANUFACTURING' ||
            industry === 'RETAIL' ||
            industry === 'FINANCIAL SERVICES')
        ? 115933
        : annualRev >= 100000 &&
          (industry === 'HEALTHCARE' ||
            industry === 'HOSPITALITY/RESTAURANT/ENTERTAINMENT' ||
            industry === 'PUBLIC ADMIN')
        ? 85664
        : annualRev >= 100000
        ? 43202
        : 21246;
    allObj.DAT_BRE_COA = beecherDBC;
    allObjAdditional.DAT_BRE_COA.AR = annualRev;
    allObjAdditional.DAT_BRE_COA.IDT = industry;

    // ---------------------------------------------------------------------------
    // ------------------------NOTIFICATION LETTERS-------------------------------
    // ---------------------------------------------------------------------------
    // includes the PII , PHI , PIFI , Credit Card(PCI) = DataScope
    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------
    const beecherNotifications =
      totalRecordsNotPII === 0
        ? 0
        : (totalRecordsNotPII < 2.11
            ? 0.0000000000000000555 +
              0.419 * (totalRecordsNotPII - 1) +
              0.000000034 * Math.pow(totalRecordsNotPII - 1, 2) +
              +0.0947 * Math.pow(totalRecordsNotPII - 1, 3)
            : 0) +
          (totalRecordsNotPII < 4.47 && totalRecordsNotPII >= 2.11
            ? 0.595 +
              0.769 * (totalRecordsNotPII - 2.11) +
              0.315 * Math.pow(totalRecordsNotPII - 2.11, 2) -
              0.0693 * Math.pow(totalRecordsNotPII - 2.11, 3)
            : 0) +
          (totalRecordsNotPII < 9.44 && totalRecordsNotPII >= 4.47
            ? 3.26 +
              1.1 * (totalRecordsNotPII - 4.47) -
              0.175 * Math.pow(totalRecordsNotPII - 4.47, 2) +
              0.0175 * Math.pow(totalRecordsNotPII - 4.47, 3)
            : 0) +
          (totalRecordsNotPII < 19.95 && totalRecordsNotPII >= 9.44
            ? 6.53 +
              0.649 * (totalRecordsNotPII - 9.44) +
              0.0849 * Math.pow(totalRecordsNotPII - 9.44, 2) -
              0.00395 * Math.pow(totalRecordsNotPII - 9.44, 3)
            : 0) +
          (totalRecordsNotPII < 42.17 && totalRecordsNotPII >= 19.95
            ? 18.1 +
              1.12 * (totalRecordsNotPII - 19.9) -
              0.0397 * Math.pow(totalRecordsNotPII - 19.9, 2) +
              0.000873 * Math.pow(totalRecordsNotPII - 19.9, 3)
            : 0) +
          (totalRecordsNotPII < 89.13 && totalRecordsNotPII >= 42.17
            ? 33.1 +
              0.651 * (totalRecordsNotPII - 42.2) +
              0.0184 * Math.pow(totalRecordsNotPII - 42.2, 2) -
              0.000192 * Math.pow(totalRecordsNotPII - 42.2, 3)
            : 0) +
          (totalRecordsNotPII < 188.36 && totalRecordsNotPII >= 89.13
            ? 84.5 +
              1.12 * (totalRecordsNotPII - 89.1) -
              0.00856 * Math.pow(totalRecordsNotPII - 89.1, 2) +
              0.0000421 * Math.pow(totalRecordsNotPII - 89.1, 3)
            : 0) +
          (totalRecordsNotPII < 398.11 && totalRecordsNotPII >= 188.36
            ? 152 +
              0.661 * (totalRecordsNotPII - 188) +
              0.00397 * Math.pow(totalRecordsNotPII - 188, 2) -
              0.00000924 * Math.pow(totalRecordsNotPII - 188, 3)
            : 0) +
          (totalRecordsNotPII < 841.4 && totalRecordsNotPII >= 398.11
            ? 380 +
              1.11 * (totalRecordsNotPII - 398) -
              0.00184 * Math.pow(totalRecordsNotPII - 398, 2) +
              0.00000185 * Math.pow(totalRecordsNotPII - 398, 3)
            : 0) +
          (totalRecordsNotPII < 1778.28 && totalRecordsNotPII >= 841.4
            ? 670 +
              0.563 * (totalRecordsNotPII - 841) +
              0.000615 * Math.pow(totalRecordsNotPII - 841, 2) -
              0.000000284 * Math.pow(totalRecordsNotPII - 841, 3)
            : 0) +
          (totalRecordsNotPII < 3758.37 && totalRecordsNotPII >= 1778.28
            ? 1500 +
              0.968 * (totalRecordsNotPII - 1780) -
              0.000183 * Math.pow(totalRecordsNotPII - 1780, 2) +
              0.0000000452 * Math.pow(totalRecordsNotPII - 1780, 3)
            : 0) +
          (totalRecordsNotPII < 7943.28 && totalRecordsNotPII >= 3758.37
            ? 3050 +
              0.775 * (totalRecordsNotPII - 3760) +
              0.0000856 * Math.pow(totalRecordsNotPII - 3760, 2) -
              0.0000000102 * Math.pow(totalRecordsNotPII - 3760, 3)
            : 0) +
          (totalRecordsNotPII < 16788.04 && totalRecordsNotPII >= 7943.28
            ? 7050 +
              0.956 * (totalRecordsNotPII - 7940) -
              0.0000423 * Math.pow(totalRecordsNotPII - 7940, 2) +
              0.00000000215 * Math.pow(totalRecordsNotPII - 7940, 3)
            : 0) +
          (totalRecordsNotPII < 35481.34 && totalRecordsNotPII >= 16788.04
            ? 13700 +
              0.711 * (totalRecordsNotPII - 16800) +
              0.0000146 * Math.pow(totalRecordsNotPII - 16800, 2) -
              0.000000000373 * Math.pow(totalRecordsNotPII - 16800, 3)
            : 0) +
          (totalRecordsNotPII < 74989.42 && totalRecordsNotPII >= 35481.34
            ? 29700 +
              0.867 * (totalRecordsNotPII - 35500) -
              0.00000628 * Math.pow(totalRecordsNotPII - 35500, 2) +
              0.0000000000544 * Math.pow(totalRecordsNotPII - 35500, 3)
            : 0) +
          (totalRecordsNotPII < 251073.098000001 && totalRecordsNotPII >= 74989.42
            ? 57500 +
              0.626 * (totalRecordsNotPII - 75000) +
              0.000000176 * Math.pow(totalRecordsNotPII - 75000, 2) -
              0.000000000000327 * Math.pow(totalRecordsNotPII - 75000, 3)
            : 0) +
          (totalRecordsNotPII < 1121631.3645 && totalRecordsNotPII >= 251073.098000001
            ? 171000 +
              0.658 * (totalRecordsNotPII - 251000) +
              0.00000000327 * Math.pow(totalRecordsNotPII - 251000, 2) -
              0.00000000000000281 * Math.pow(totalRecordsNotPII - 251000, 3)
            : 0) +
          (totalRecordsNotPII < 5010719.641 && totalRecordsNotPII >= 1121631.3645
            ? 745000 +
              0.657 * (totalRecordsNotPII - 1120000) -
              0.00000000407 * Math.pow(totalRecordsNotPII - 1120000, 2) +
              0.000000000000000373 * Math.pow(totalRecordsNotPII - 1120000, 3)
            : 0) +
          (totalRecordsNotPII < 22384636.932 && totalRecordsNotPII >= 5010719.641
            ? 3260000 +
              0.642 * (totalRecordsNotPII - 5010000) +
              0.000000000288 * Math.pow(totalRecordsNotPII - 5010000, 2) -
              0.00000000000000000566 * Math.pow(totalRecordsNotPII - 5010000, 3)
            : 0) +
          (totalRecordsNotPII >= 22384636.932
            ? 14500000 +
              0.647 * (totalRecordsNotPII - 22400000) -
              0.00000000000646 * Math.pow(totalRecordsNotPII - 22400000, 2) +
              0.0000000000000000000277 * Math.pow(totalRecordsNotPII - 22400000, 3)
            : 0) +
          1501.08;
    allObj.NOT_LET = beecherNotifications;
    allObjAdditional.NOT_LET.TRN = totalRecordsNotPII;

    // ---------------------------------------------------------------------------
    // --------------------------------CALL CENTER--------------------------------
    // ---------------------------------------------------------------------------
    // includes the PII , PHI , PIFI , Credit Card(PCI) = DataScope
    const beecherCall =
      totalRecordsNotPII === 0
        ? 0
        : (totalRecordsNotPII < 5623.41 && totalRecordsNotPII >= 500
            ? -124 +
              0.696 * (totalRecordsNotPII - 316) +
              0.000188 * Math.pow(totalRecordsNotPII - 316, 2) -
              0.0000000126 * Math.pow(totalRecordsNotPII - 316, 3)
            : 0) +
          (totalRecordsNotPII <= 100000 && totalRecordsNotPII >= 5623.41
            ? 6980 +
              1.63 * (totalRecordsNotPII - 5620) -
              0.0000124 * Math.pow(totalRecordsNotPII - 5620, 2) +
              0.0000000000439 * Math.pow(totalRecordsNotPII - 5620, 3)
            : 0) +
          3000 +
          (totalRecordsNotPII > 100000
            ? jStat.erf(
                (Math.log10(totalRecordsNotPII) - Math.log10(100000)) / (Math.log10(10000000) - Math.log10(100000))
              ) *
                (375000 - 90271.9875480008) +
              87427.71
            : 0);
    allObj.CAL_CEN = beecherCall;
    allObjAdditional.CAL_CEN.TRN = totalRecordsNotPII;
    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------

    // --------------------------------------------------------------------------
    // -----------------------------CREDIT MONITORING----------------------------
    // --------------------------------------------------------------------------
    // includes the PII , PHI , PIFI , Credit Card(PCI) = DataScope

    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------
    const beecherCC =
      totalRecordsNotPII === 0
        ? 0
        : totalRecordsNotPII < 1000000
        ? Math.exp(
            (Math.log(totalRecordsNotPII) < 2.76310211130191
              ? 0.00000000000000577 +
                1 * (Math.log(totalRecordsNotPII) + 0) +
                0.000000000000081 * Math.pow(Math.log(totalRecordsNotPII) + 0, 2) -
                0.0003 * Math.pow(Math.log(totalRecordsNotPII) + 0, 3)
              : 0) +
              (Math.log(totalRecordsNotPII) < 5.52620422338095 && Math.log(totalRecordsNotPII) >= 2.76310211130191
                ? 2.77 +
                  0.998 * (Math.log(totalRecordsNotPII) - 2.76) -
                  0.00249 * Math.pow(Math.log(totalRecordsNotPII) - 2.76, 2) +
                  0.000866 * Math.pow(Math.log(totalRecordsNotPII) - 2.76, 3)
                : 0) +
              (Math.log(totalRecordsNotPII) < 8.28930633489538 && Math.log(totalRecordsNotPII) >= 5.52620422338095
                ? 5.53 +
                  1 * (Math.log(totalRecordsNotPII) - 5.53) +
                  0.00469 * Math.pow(Math.log(totalRecordsNotPII) - 5.53, 2) -
                  0.00147 * Math.pow(Math.log(totalRecordsNotPII) - 5.53, 3)
                : 0) +
              (Math.log(totalRecordsNotPII) < 11.0524084464028 && Math.log(totalRecordsNotPII) >= 8.28930633489538
                ? 8.3 +
                  0.996 * (Math.log(totalRecordsNotPII) - 8.29) -
                  0.00748 * Math.pow(Math.log(totalRecordsNotPII) - 8.29, 2) -
                  0.0012 * Math.pow(Math.log(totalRecordsNotPII) - 8.29, 3)
                : 0) +
              (Math.log(totalRecordsNotPII) < 13.8155105579643 && Math.log(totalRecordsNotPII) >= 11.0524084464028
                ? 11 +
                  0.927 * (Math.log(totalRecordsNotPII) - 11.1) -
                  0.0174 * Math.pow(Math.log(totalRecordsNotPII) - 11.1, 2) +
                  0.0021 * Math.pow(Math.log(totalRecordsNotPII) - 11.1, 3)
                : 0) +
              -0.003814026
          )
        : ((Math.exp(totalRecordsNotPII < 1000000 ? 0 : Math.log10(totalRecordsNotPII) / Math.log10(1000000) + 1) /
            (Math.exp(totalRecordsNotPII < 1000000 ? 0 : Math.log10(totalRecordsNotPII) / Math.log10(1000000) + 1) +
              1) -
            Math.exp(2) / (Math.exp(2) + 1)) /
            (1 - Math.exp(2) / (Math.exp(2) + 1))) *
            9709300.57683305 +
          678206.746726092;

    allObj.CRE_MON = beecherCC;
    allObjAdditional.CRE_MON.TRN = totalRecordsNotPII;

    // ------------------------------------------------------------------------
    // ----------------------PUBLIC RELATIONS SERVICE--------------------------
    // ------------------------------------------------------------------------
    // includes the PII , PHI , PIFI , Credit Card(PCI) = DataScope

    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------

    // for beecher case - we are directly calculating it on the
    // input element itself - getting the value from there.

    allObj.PUB_REL_SER = this.technical[1][5].value;
    allObjAdditional.PUB_REL_SER.AR = annualRev;
    allObjAdditional.PUB_REL_SER.IDT = industry;

    // ------------------------------DIRECT BREACH COSTS-------------------------------------------
    const DIRECT_BREACH_COSTS =
      allObj.FOR_INV +
      allObj.SEC_REM +
      allObj.DAT_BRE_COA +
      allObj.NOT_LET +
      allObj.CAL_CEN +
      allObj.CRE_MON +
      this.medicalConstant +
      allObj.PUB_REL_SER;

    // Now calculating for Regulatory Fines , Combo on FTC Fines , HHS Fines , State AG Fines and PCI Fines

    // ------------------------REGULATORY------------------------------------

    // ------------------------FTC FINES-------------------------------------
    const P2_FTC =
      P2 === 0
        ? 0
        : P2 < 5000
        ? 2760.67269390272 + P2 * 2.22861583074678 + Math.pow(P2, 2) * 0.000096463547474155
        : 30.63 * Math.pow(P2, 0.7371);
    const PH_FTC = 0;
    const PF_FTC =
      PF === 0
        ? 0
        : PF < 5000
        ? 2760.67269390272 + PF * 2.22861583074678 + Math.pow(PF, 2) * 0.000096463547474155
        : 30.63 * Math.pow(PF, 0.7371);
    const PC_FTC =
      PC === 0
        ? 0
        : PC < 5000
        ? 2760.67269390272 + PC * 2.22861583074678 + Math.pow(PC, 2) * 0.000096463547474155
        : 30.63 * Math.pow(PC, 0.7371);
    allObj.FTC_FIN = P2_FTC + PH_FTC + PF_FTC + PC_FTC;
    allObjAdditional.FTC_FIN.PII = P2_FTC;
    allObjAdditional.FTC_FIN.PIFI = PF_FTC;
    allObjAdditional.FTC_FIN.PCI = PC_FTC;

    // -------------------------------HHS FINES---------------------------------
    const PH_LOG = Math.log(PH);
    const MEAN = 11.161013 + 0.2101748 * PH_LOG;
    const DEVIATION = 1.373676;
    const X = 0.9;
    const NORM_DIS_INV = jStat.normal.inv(X, MEAN, DEVIATION);
    allObj.HHS_FIN = Math.exp(NORM_DIS_INV);
    allObjAdditional.HHS_FIN.PHI = PH;

    // ------------------------STATE AG FINES--------------------------------------------
    const P2_SAG = P2 === 0 ? 0 : P2 < 100000 ? 0 : 0.0907 * P2 + 164981;
    const PH_SAG = PH === 0 ? 0 : PH < 100000 ? 0 : 0.0907 * PH + 164981;
    const PF_SAG = PF === 0 ? 0 : PF < 100000 ? 0 : 0.0907 * PF + 164981;
    const PC_SAG = PC === 0 ? 0 : PC < 100000 ? 0 : 0.0907 * PC + 164981;
    allObj.STA_AG_FIN = P2_SAG + PH_SAG + PF_SAG + PC_SAG;
    allObjAdditional.STA_AG_FIN.PII = P2_SAG;
    allObjAdditional.STA_AG_FIN.PHI = PH_SAG;
    allObjAdditional.STA_AG_FIN.PIFI = PF_SAG;
    allObjAdditional.STA_AG_FIN.PCI = PC_SAG;

    // ------------------------------- PCI FINES --------------------------------------
    allObj.PCI_FIN =
      PC === 0
        ? 0
        : PC < 1000
        ? 5000
        : PC < 5000
        ? 5000 + ((PC - 1000) / 4000) * (8086.15687725745 - 5000)
        : 216.98 * Math.pow(PC, 0.4248);

    // ------------------------------ BEECHER SPECIFIC CALCULATIONS ------------------------------
    // old calculations
    // allObj.PCI_FIN =
    // PC === 0
    //   ? 0
    //   : PC < 5000
    //   ? 2760.67269390272 + PC * 2.22861583074678 + Math.pow(PC, 2) * 0.000096463547474155
    //   : 30.63 * Math.pow(PC, 0.7371);

    allObjAdditional.PCI_FIN.PCI = PC;

    // ------------------------------TOTAL REGULATORY------------------------------
    const REGULATORY = allObj.FTC_FIN + allObj.HHS_FIN + allObj.STA_AG_FIN + allObj.PCI_FIN;

    // ------------------------------------------------------------------------
    // ----------------------LEGAL DEFENSE & DAMAGES--------------------------
    // ------------------------------------------------------------------------
    const P2_DND =
      P2 === 0
        ? 0
        : P2 < 200000
        ? 179699.591637088 + P2 * 8.98613752534106 + Math.pow(P2, 2) * -0.0000172235115063121
        : 1.4483 * P2 + 1 * Math.pow(10, 6);
    const PH_DND =
      PH === 0
        ? 0
        : PH < 200000
        ? 179699.591637088 + PH * 8.98613752534106 + Math.pow(PH, 2) * -0.0000172235115063121
        : 1.4483 * PH + 1 * Math.pow(10, 6);
    const PF_DND =
      PF === 0
        ? 0
        : PF < 200000
        ? 179699.591637088 + PF * 8.98613752534106 + Math.pow(PF, 2) * -0.0000172235115063121
        : 1.4483 * PF + 1 * Math.pow(10, 6);
    const PC_DND =
      PC === 0
        ? 0
        : PC < 200000
        ? 179699.591637088 + PC * 8.98613752534106 + Math.pow(PC, 2) * -0.0000172235115063121
        : 1.4483 * PC + 1 * Math.pow(10, 6);
    allObj.LEG_DEF_DAM = P2_DND + PH_DND + PF_DND + PC_DND;
    allObjAdditional.LEG_DEF_DAM.PII = P2_DND;
    allObjAdditional.LEG_DEF_DAM.PHI = PH_DND;
    allObjAdditional.LEG_DEF_DAM.PIFI = PF_DND;
    allObjAdditional.LEG_DEF_DAM.PCI = PC_DND;

    // ------------------------------------------------------------------------
    // ----------------------BANK CARD ISSUANCE/RESISTANCE---------------------
    // ------------------------------------------------------------------------
    allObj.BAN_CAR_RES =
      PC === 0
        ? 0
        : PC < 200000
        ? 2477.76880141827 + PC * 5.34591680816633 + (Math.pow(PC, 2) * 6.44918872604088) / Math.pow(10, 6)
        : 3.0511 * PC + 719778;

    allObjAdditional.BAN_CAR_RES.PCI = PC;

    const DATA_PRIVACY_BREACH = DIRECT_BREACH_COSTS + REGULATORY + allObj.LEG_DEF_DAM + allObj.BAN_CAR_RES;
    this.riskScenarios[0].moreChildren.forEach(prop => {
      prop.value = allObj[this.titleConst[prop.name]];
      prop.additionalChild = allObjAdditional[this.titleConst[prop.name]];
    });
    this.riskScenarios[0].inherent = DATA_PRIVACY_BREACH;
    this.riskScenarios[0].residual = DATA_PRIVACY_BREACH;
    this.parsedInherentValues.push(this.addCommasIntoNumber(DATA_PRIVACY_BREACH));

    this.projectedInherent = DATA_PRIVACY_BREACH + this.projectedInherent;

    // Hybrid Data Copy for Beecher Case Only
    this.riskScenarios[2].moreChildren.forEach(prop => {
      if (allObj[this.titleConst[prop.name]] || allObj[this.titleConst[prop.name]] === 0) {
        prop.value = allObj[this.titleConst[prop.name]];
        prop.additionalChild = allObjAdditional[this.titleConst[prop.name]];
      }
    });
  }

  getDataScopeValues(): any {
    const records = {
      P2: 0,
      PH: 0,
      PF: 0,
      PC: 0,
    };
    this.dataScope.forEach((ds, index) => {
      if (index < 2) {
        ds.forEach(prop => {
          if (prop.title.includes('PII')) {
            if (prop.value && prop.value > 0 && prop.isChecked) {
              records.P2 = prop.value + records.P2;
            }
          }
          if (prop.title.includes('PHI')) {
            if (prop.value && prop.value > 0 && prop.isChecked) {
              records.PH = prop.value + records.PH;
            }
          }
          if (prop.title.includes('PIFI')) {
            if (prop.value && prop.value > 0 && prop.isChecked) {
              records.PF = prop.value + records.PF;
            }
          }
          if (prop.title.includes('PCI')) {
            if (prop.value && prop.value > 0 && prop.isChecked) {
              records.PC = prop.value + records.PC;
            }
          }
        });
      }
    });
    return records;
  }

  calculateBusinessInterruption(): void {
    const records = {
      INC_LOS: 0,
      SYS_REC: 0,
      EXT_EXP: 0,
    };
    const { noServer, noWorkstation, costServer, costWorkstation, pr_response } = this.getTechnicalStepData();

    // -------------------------- INCOME LOSS ---------------------------
    // ------ Lost Revenue ------
    const hourlyRev = this.getFinancialData(0);
    const daySum = this.getDaySumValue();
    const lostRevenue = daySum * hourlyRev * 24;
    // ------ Lost Revenue Saved------
    const hourlyRevSaved = this.getFinancialData(1);
    const annualSaving = this.getFinancialData(2);
    const otherExpenses = this.getFinancialData(3);
    const savedExpenses = daySum * hourlyRevSaved * 24 + daySum * annualSaving * 24 + daySum * otherExpenses * 24;

    records.INC_LOS = lostRevenue - savedExpenses;
    // --------------------- SYSTEM RECONSTRUCTION ----------------------
    const replacementOfHardware = noServer * costServer + noWorkstation * costWorkstation;
    records.SYS_REC = replacementOfHardware * this.initialConst;
    // ------------------------- EXTRA EXPENSE --------------------------
    const forensic_remediation = (noServer + noWorkstation) * 2000;
    records.EXT_EXP = this.initialConst * forensic_remediation + pr_response;
    const sumTotal = records.INC_LOS + records.SYS_REC + records.EXT_EXP;
    this.riskScenarios[1].inherent = sumTotal;
    this.parsedInherentValues.push(this.addCommasIntoNumber(sumTotal));
    this.riskScenarios[1].residual = sumTotal;
    this.riskScenarios[1].moreChildren.forEach(child => {
      child.value = records[this.titleConst[child.name]];
    });

    this.projectedInherent = this.projectedInherent + sumTotal;

    // Hybrid Data Copy for Beecher Case Only
    this.riskScenarios[2].moreChildren.forEach(prop => {
      if (records[this.titleConst[prop.name]] || records[this.titleConst[prop.name]] === 0) {
        prop.value = records[this.titleConst[prop.name]];
      }
    });
  }
  getFinancialData(indexVal: number): number {
    const val = this.financial[0][indexVal].value;
    return val ? val / 365 / 24 : 0;
  }

  getTechnicalStepData(): any {
    const obj = {
      noServer: 0,
      noWorkstation: 0,
      costServer: 0,
      costWorkstation: 0,
      pr_response: 0,
    };
    const cacheList = this.technical[0];
    obj.noServer = cacheList[0].value;
    obj.noWorkstation = cacheList[1].value;
    obj.costServer = cacheList[3].value;
    obj.costWorkstation = cacheList[4].value;
    obj.pr_response = this.technical[1][5].value;
    Object.keys(obj).forEach(prop => (obj[prop] = obj[prop] && obj[prop] > 0 ? obj[prop] : 0));
    return obj;
  }

  getDaySumValue(): number {
    let day = 60;
    let acc = 0;
    while (day > 0) {
      acc = acc + 0.5 * day;
      day--;
    }
    acc = acc - 1;
    return acc / 100;
  }

  calculateHybrid(): void {
    const revenue = this.financial[0][0].value ? this.financial[0][0].value : 0;
    const industry = this.organizational[2].industry ? this.organizational[2].industry : null;

    const allObj = {
      RNSM: 0,
      DAT_BRE_COA: 0,
      PUB_REL_SER: 0,
      FOR_INV: 0,
    };
    const allObjAdditional = {
      RNSM: {},
      DAT_BRE_COA: {},
      PUB_REL_SER: {},
      FOR_INV: {},
    } as any;

    // ------------------------------------------------------------------------
    // -----------------------HYBRID RANSOMWARE FORMULA------------------------
    // ------------------------------------------------------------------------
    // includes only the Revenue Value
    allObj.RNSM = Math.exp(0.4 * Math.log(revenue) + 8.1053);
    allObjAdditional.RNSM.AR = revenue;

    // ------------------------------------------------------------------------
    // -------------------HYBRID DATA BREACH COACH FORMULA---------------------
    // ------------------------------------------------------------------------
    // includes the Revenue and Industry value
    allObj.DAT_BRE_COA =
      revenue >= 25000000000
        ? 3688947
        : revenue >= 1000000000
        ? 842521
        : revenue >= 100000 &&
          (industry === 'IT' ||
            industry === 'MANUFACTURING' ||
            industry === 'RETAIL' ||
            industry === 'FINANCIAL SERVICES')
        ? 150191
        : revenue >= 100000 &&
          (industry === 'HEALTHCARE' ||
            industry === 'HOSPITALITY/RESTAURANT/ENTERTAINMENT' ||
            industry === 'PUBLIC ADMIN')
        ? 124360
        : revenue >= 100000
        ? 63656
        : 31847;

    allObjAdditional.DAT_BRE_COA.AR = revenue;
    allObjAdditional.DAT_BRE_COA.IDT = industry;

    // ------------------------------------------------------------------------
    // ---------------------HYBRID PUBLIC RELATION SERVICE---------------------
    // ------------------------------------------------------------------------
    // includes the Revenue and Industry value
    allObj.PUB_REL_SER = this.technical[1][5].value;

    allObjAdditional.PUB_REL_SER.AR = revenue;
    allObjAdditional.PUB_REL_SER.IDT = industry;

    // ------------------------------------------------------------------------
    // ---------------------HYBRID FORENSIC INVESTIGATION----------------------
    // ------------------------------------------------------------------------
    // includes the Revenue and Industry value , getting it from the
    // Data Breach Forensic Investigation value
    allObj.FOR_INV = this.technical[1][4].value;

    allObjAdditional.FOR_INV.AR = revenue;
    allObjAdditional.FOR_INV.IDT = industry;

    this.riskScenarios[2].moreChildren.forEach(prop => {
      if (allObj[this.titleConst[prop.name]] || allObj[this.titleConst[prop.name]] === 0) {
        prop.value = allObj[this.titleConst[prop.name]];
        prop.additionalChild = allObjAdditional[this.titleConst[prop.name]];
      }
    });

    let acc = 0;
    this.riskScenarios[2].moreChildren.forEach(prop => {
      acc = prop.value || prop.value === 0 ? acc + prop.value : acc;
    });
    this.riskScenarios[2].inherent = acc;
    this.riskScenarios[2].residual = acc;

    this.parsedInherentValues.push(this.addCommasIntoNumber(acc));

    // for beecher case , only the Hybrid Inherent is the Total
    this.projectedInherent = acc;
  }
  getMaxValue(listArr: number[]): number {
    return Math.max(...listArr) / this.numConstant;
  }

  /**
   *
   * @param args - Current button pressed
   * @returns true if it is a numeric number
   */
  numberCheck(args) {
    const keys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight'];
    return /^\d+$/.test(args.key) || keys.includes(args.key);
  }

  /**
   *
   * @param event - Current input value
   * @param obj - Object
   * @param idx - index of current input element
   */
  parseInput(
    event: string,
    obj: any,
    input: HTMLInputElement,
    stepName: string = null,
    isRevenue: boolean = false
  ): void {
    let value;
    // If cash input, then add commas
    if (event && event !== '$') {
      // remove starting zeros
      event = event.replace(/^0+/, '');
      // Convert event into string value
      value = event
        .toString()
        .replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      obj.value = Number(value.replaceAll(',', '')); // Converting it to number
      obj.displayValue = value; // Value to be shown in inputs
      obj.showDollar = true;
      // check if obj.displayValue is value that we need to show on front-end
      // adding extra check that if obj.value=0 then set the obj.dispaly as ''
      if (obj.value === 0) {
        input.value = '';
        obj.value = '';
        obj.displayValue = '';
        obj.showDollar = false;
      }
      if ((stepName === 'current_year' || stepName === 'previous_year' || isRevenue) && obj.displayValue !== '') {
        obj.displayValue = '$' + obj.displayValue; // for current and previous year section, $ is part of displayValue
      } else {
        input.value = value;
      }
    } else {
      // if event is empty then simply reset this input field
      input.value = '';
      obj.value = '';
      obj.displayValue = '';
      obj.showDollar = false;
    }

    // binding fields to other wizard fields

    // ----------------------- Replacement of Hardware Calculation -------------------------------
    const titlesHardware = ['Cost Per Server', 'Cost Per Workstation', 'Number of Servers', 'Number of Workstations'];
    if (titlesHardware.includes(obj.title)) {
      const sum = this.calculateHardwareReplacement();
      this.technical[1][3].value = sum;
      this.technical[1][3].displayValue = this.addCommasIntoNumber(sum);
    }

    // -------------------- Number of Employees is bind to Number of Workstations
    if (obj.title === 'Number of Employees' || obj.title === 'Number of Teammates') {
      this.technical[0][1].value = obj.value;
      this.technical[0][1].displayValue = obj.displayValue;

      // Number of Workstation is updated , also need to update replacement
      const sum = this.calculateHardwareReplacement();
      this.technical[1][3].value = sum;
      this.technical[1][3].displayValue = this.addCommasIntoNumber(sum);
    }

    if (obj.title === 'Annual Revenue') {
      this.calculatePRAndExpenses();
      this.calculateForensicRemediation();
    }
  }

  addDollarSign(val: string): string {
    if (val) {
      return val.includes('$') ? val : '$' + val;
    }
  }
  /**
   * Function to add commas in numbers to make it mathematical value
   * @param numbObj - current number
   * @returns - parsed number
   */
  addCommasIntoNumber(numbObj: number): string {
    let result = numbObj.toFixed().toString().replace(/^0+/, '');
    result = result
      .toString()
      .replace(/\D/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return result;
  }

  /**
   * Returns the calculation sum of Hardware Replacement Value in Technical Step.
   */
  calculateHardwareReplacement(): number {
    const sW = {
      nServer: 0,
      nWork: 0,
      cServer: 0,
      cWork: 0,
    };
    sW.nServer = this.technical[0][0].value ? this.technical[0][0].value : 0;
    sW.nWork = this.technical[0][1].value ? this.technical[0][1].value : 0;
    sW.cServer = this.technical[0][3].value ? this.technical[0][3].value : 0;
    sW.cWork = this.technical[0][4].value ? this.technical[0][4].value : 0;

    // formula for Replacement of Hardware
    return sW.nServer * sW.cServer + sW.nWork * sW.cWork;
  }

  /**
   * Returns the calculation of Forensic Remediation Value in Technical Step.
   */
  calculateForensicRemediation(): void {
    const annualRev = this.financial[0][0].value ? this.financial[0][0].value : 0;
    const industry = this.organizational[2].industry ? this.organizational[2].industry : null;
    const beecherForensic =
      annualRev >= 25000000000
        ? 2215395
        : annualRev >= 1000000000
        ? 1856203
        : annualRev >= 100000 &&
          (industry === 'IT' ||
            industry === 'MANUFACTURING' ||
            industry === 'RETAIL' ||
            industry === 'FINANCIAL SERVICES')
        ? 655256
        : annualRev >= 100000 &&
          (industry === 'HEALTHCARE' ||
            industry === 'HOSPITALITY/RESTAURANT/ENTERTAINMENT' ||
            industry === 'PUBLIC ADMIN')
        ? 521262
        : annualRev >= 100000
        ? 266346
        : 86741;
    this.technical[1][4].value = beecherForensic;
    this.technical[1][4].displayValue = this.addCommasIntoNumber(beecherForensic);
  }

  /**
   * Returns the calculation sum of PR and Expenses Value in Technical Step.
   */
  calculatePRAndExpenses(): void {
    const annualRev = this.financial[0][0].value ? this.financial[0][0].value : 0;
    const industry = this.organizational[2].industry ? this.organizational[2].industry : null;
    const beecherPRS =
      annualRev >= 25000000000
        ? 105249
        : annualRev >= 1000000000
        ? 91082
        : annualRev >= 100000 &&
          (industry === 'IT' ||
            industry === 'MANUFACTURING' ||
            industry === 'RETAIL' ||
            industry === 'FINANCIAL SERVICES')
        ? 64664
        : annualRev >= 100000 &&
          (industry === 'HEALTHCARE' ||
            industry === 'HOSPITALITY/RESTAURANT/ENTERTAINMENT' ||
            industry === 'PUBLIC ADMIN')
        ? 52435
        : annualRev >= 100000
        ? 35000
        : 20149;
    this.technical[1][5].value = beecherPRS;
    this.technical[1][5].displayValue = this.addCommasIntoNumber(beecherPRS);
  }

  /**
   * This function will add a delay so that the dom is updated before
   * new screenshot
   * @param ms miliseconds
   * @returns promise
   */
  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async screenShotProcess(): Promise<void> {
    try {
      const zip = new JSZip();
      const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

      const captureScreenshot = async (element: HTMLElement, filename: string) => {
        const screenshot = await html2canvas(element);
        const screenshotBlob = await fetch(screenshot.toDataURL()).then(res => res.blob());
        zip.file(filename, screenshotBlob);
      };
      const wizard: HTMLElement = document.querySelector('#all-steps-div');
      // step1
      await captureScreenshot(wizard, 'step1.png');
      // step2
      this.organizationalScreen = false;
      this.accountsScreen = true;
      await delay(1000);
      await captureScreenshot(wizard, 'step2.png');
      console.log('Taking Screenshots...');
      // step3
      this.accountsScreen = false;
      this.dataScopeScreen = true;
      await delay(1000);
      await captureScreenshot(wizard, 'step3.png');
      // step4
      this.dataScopeScreen = false;
      this.applicantInfoScreen = true;
      await delay(1000);
      await captureScreenshot(wizard, 'step4.png');
      // step5
      this.applicantInfoScreen = false;
      this.revenueDetailScreen = true;
      await delay(1000);
      await captureScreenshot(wizard, 'step5.png');
      // step6
      this.revenueDetailScreen = false;
      this.dataInventoryScreen = true;
      await delay(1000);
      await captureScreenshot(wizard, 'step6.png');
      await delay(1000);

      // Create a blob containing the zip file data
      const blob = await zip.generateAsync({ type: 'blob' });

      // upload to s3 folder
      const config = {
        contentType: 'application/zip',
        download: true,
        level: 'public',
      };
      const entityId = this.wizardId ? this.wizardId : uuid();
      this.wizardId = entityId;
      const path = 'MIDMARKET_REPORTS/Wizard_Screenshots/' + entityId + '/screenshot.zip';
      await this.fileService.uploadToS3CustomPath(path, blob, config);
    } catch (e) {
      console.log(e);
      this.nextClicked(false);
    }
  }

  async scanAllDomains(wizardId: string): Promise<void> {
    const domainList = this.checkNewDomainsAdded(true) as string[];
    const allFiles = await this.fileService.checkIfPathExistsS3(`BEECHER_BE_SCANS/${wizardId}`);
    if (allFiles && allFiles.length) {
      allFiles.forEach(file => {
        const domName = file.key.split('/')[2];
        const dom = domName.split('.zip')[0];
        const index = domainList.findIndex(d => d === dom);
        if (index >= 0) {
          domainList.splice(index, 1);
        } else {
          this.fileService.deleteFromS3(file);
        }
      });
    }
    if (!domainList.length) {
      return;
    }
    const json = {
      wizardId,
      domainList,
    };

    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: environment.region,
    });
    const params = {
      FunctionName: `multiDomainBinaryScan-${environment.name}`,
      Payload: JSON.stringify({ ...json }),
    };
    try {
      await lambda.invoke(params).promise();
      this.entityService.updateWizardDomainSubscriber.next(true);
      this.toastr.success('Domain Scanned Complete!');
    } catch (error) {
      this.toastr.error('Issue While Scanning Domains');
      console.log('Issue while scanning domains ' + JSON.stringify(error));
    }
  }

  /**
   *
   * @param returnDiff return a list of domains that are newly added ,
   * by default this option is false so it will return a boolean answer
   * @returns A list of newly added domains or a boolean status telling if new domains are added.
   */
  checkNewDomainsAdded(returnDiff: boolean = false): boolean | string[] {
    let newAddedStatus = false;
    let newlyAdded: string[] = [];
    const cacheAll: string[] = [];
    if (this.newActiveDomains && this.newActiveDomains.length && this.oldExistingDomains.length) {
      // checking the difference between the arrays for new domains
      this.newActiveDomains.forEach(domObj => {
        cacheAll.push(domObj.value);
        if (!this.oldExistingDomains.includes(domObj.value)) {
          newAddedStatus = true;
          newlyAdded.push(domObj.value);
        }
      });
    } else if (this.newActiveDomains && this.newActiveDomains.length) {
      this.newActiveDomains.forEach(domObj => {
        cacheAll.push(domObj.value);
      });
    }
    // if no root entity exists in Wizard , it is first time creation so return all domains list.

    newlyAdded = cacheAll;
    newAddedStatus = true;

    if (newAddedStatus) {
    }

    // commenting out this optimization till clients needs it , for now scanning all domains again whenever
    // we save the wizard

    // if (this.wizardData && !this.wizardData.rootEntity) {
    //   newlyAdded = cacheAll;
    //   newAddedStatus = true;
    // }else{

    // }
    // for now returning all arrays as more work is needed for optimization.
    // return returnDiff ? newlyAdded : newAddedStatus;
    return returnDiff ? cacheAll : true;
  }

  /**
   * The function loads the Object which contains the current title and it's details regarding calculation
   * @param show to show the additional detail UI or not
   * @param obj the object that contains the details and it's additional detail regarding formula calculations
   */
  addCurrentDisplayAdd(show: boolean, obj: any): void {
    this.showAdditionalDetails = show;
    this.displayAdd = obj;
  }

  expandLowerPortion(): void {
    this.isDropDownOpened = !this.isDropDownOpened;
  }

  /**
   * Toggle beecher industry dropdown
   */
  openBeecherIndustry(): void {
    this.showIndustryDropDown = !this.showIndustryDropDown;
  }

  /**
   * Selected beecher industry will be set.
   * @param index: index of the industry
   */
  selectBeecherIndustry(index: number): void {
    this.showIndustryDropDown = !this.showIndustryDropDown;
    this.organizational[2].industry = this.industryOptBeecher[index];
    this.calculateForensicRemediation();
    this.calculatePRAndExpenses();
  }

  /**
   * Selected beecher industry will be set.
   * @param index: index of the industry
   */
  selectRevenueIndustry(index: number): void {
    this.showIndustryDropDown = !this.showIndustryDropDown;
    this.revenueDetails[0].value = this.industryOptBeecher[index];
  }

  /**
   * Toggle current year pop up (when clicked on Calculate button)
   */
  toggleCurrentYearPopup(): void {
    this.calculateStep8Values();
    this.showCurrentYearPopup = !this.showCurrentYearPopup;
  }

  /**
   * Will calculate values for step 8 when clicked on calulcate button to show pop up
   */
  calculateStep8Values() {
    // Premium TO Comp
    this.currentYear[1][0].current =
      this.currentYear[0][4].current.value && this.currentYear[0][0].current.value
        ? (this.currentYear[0][4].current.value / this.currentYear[0][0].current.value) * 100
        : 0;
    this.currentYear[1][0].previous =
      this.currentYear[0][4].previous.value && this.currentYear[0][0].previous.value
        ? (this.currentYear[0][4].previous.value / this.currentYear[0][0].previous.value) * 100
        : 0;

    // Excess Limit
    this.currentYear[1][1].current = this.currentYear[0][3].current.value - this.currentYear[0][5].current.value;
    this.currentYear[1][1].previous = this.currentYear[0][3].previous.value - this.currentYear[0][5].previous.value;

    // Excess Premium
    this.currentYear[1][2].current = this.currentYear[0][4].current.value - this.currentYear[0][6].current.value;
    this.currentYear[1][2].previous = this.currentYear[0][4].previous.value - this.currentYear[0][6].previous.value;

    // Total PPM
    this.currentYear[1][3].current =
      this.currentYear[0][4].current.value && this.currentYear[0][3].current.value
        ? this.currentYear[0][4].current.value / (this.currentYear[0][3].current.value / 1000000)
        : 0;
    this.currentYear[1][3].previous =
      this.currentYear[0][4].previous.value && this.currentYear[0][3].previous.value
        ? this.currentYear[0][4].previous.value / (this.currentYear[0][3].previous.value / 1000000)
        : 0;

    // Primary PPM
    this.currentYear[1][4].current =
      this.currentYear[0][4].current.value && this.currentYear[0][5].current.value
        ? this.currentYear[0][4].current.value / (this.currentYear[0][5].current.value / 1000000)
        : 0;
    this.currentYear[1][4].previous =
      this.currentYear[0][4].previous.value && this.currentYear[0][5].previous.value
        ? this.currentYear[0][4].previous.value / (this.currentYear[0][5].previous.value / 1000000)
        : 0;

    // Excess PPM
    this.currentYear[1][5].current =
      this.currentYear[1][2].current && this.currentYear[1][1].current
        ? this.currentYear[1][2].current / (this.currentYear[1][1].current / 1000000)
        : 0;
    this.currentYear[1][5].previous =
      this.currentYear[1][2].previous && this.currentYear[1][1].previous
        ? this.currentYear[1][2].previous / (this.currentYear[1][1].previous / 1000000)
        : 0;

    // Average ILF
    this.currentYear[1][6].current =
      this.currentYear[1][5].current && this.currentYear[1][4].current
        ? (this.currentYear[1][5].current / this.currentYear[1][4].current) * 100
        : 0;
    this.currentYear[1][6].previous =
      this.currentYear[1][5].previous && this.currentYear[1][4].previous
        ? (this.currentYear[1][5].previous / this.currentYear[1][4].previous) * 100
        : 0;
  }

  /**
   * Will handle All account details dropdown
   */
  openAccountDetailDropdown(obj: any, index: number): void {
    obj.isDropDownOpened = !obj.isDropDownOpened;
    this.dropDownIndex = index;
    // index < 5 means left side
    if (index < 5) {
      this.leftDropDown = !this.leftDropDown;
    } else {
      this.rightDropDown = !this.rightDropDown;
    }
  }

  /**
   * Close account details dropdowns
   * @param isRight - Right section or left
   */
  closeAccountDetailDropdown(isRight: boolean = false): void {
    if (isRight) {
      this.rightDropDown = !this.rightDropDown;
      this.accountDetails[1].forEach(item => {
        item.isDropDownOpened = false;
      });
    } else {
      this.leftDropDown = !this.leftDropDown;
      this.accountDetails[0].forEach(item => {
        item.isDropDownOpened = false;
      });
    }
  }

  /**
   * Function to save selected item in the main obj
   * @param obj - current object
   * @param index - Index of the element from the dropdown list
   */
  saveDropdownItem(obj: any, index: number): void {
    obj.value = obj.list[index];
    obj.isDropDownOpened = false;
    this.leftDropDown = this.rightDropDown = false;
  }

  /**
   * Save date in the given object in mm/dd/yyyy format
   * @param event - event has date object
   * @param obj - current object
   */
  saveDate(event, obj) {
    obj.value = event.month + '/' + event.day + '/' + event.year;

    this.setCalendarMinMax(event);
  }

  calRefUpdate(event, obj): void {
    if (obj && obj.value) {
      this.existingDate = UtilsService.getDateInNgbDateStructFormat(new Date(obj.value));
    } else {
      this.existingDate = UtilsService.getDateInNgbDateStructFormat(new Date());
    }
    this.setCalendarMinMax(this.existingDate);
    event.startDate = this.existingDate;
  }

  setCalendarMinMax(date): void {
    // setting min and max date
    console.log(`date => ${date}`);
    // console.clear();
    const mome1 = moment('1900-01-01');
    const mome2 = moment();
    // this.minDate = UtilsService.getDateInNgbDateStructFormat(mome1.subtract(5, 'years').format('YYYY-MM-DD'));
    // this.maxDate = UtilsService.getDateInNgbDateStructFormat(mome2.add(5, 'years').format('YYYY-MM-DD'));

    this.minDate = UtilsService.getDateInNgbDateStructFormat(mome1.format('YYYY-MM-DD'));
    this.maxDate = UtilsService.getDateInNgbDateStructFormat(mome2.format('YYYY-MM-DD'));
  }

  /**
   * Will copy current year values to previous year
   */
  copyCurrentValues() {
    this.currentYear[0].forEach(node => {
      node.previous.displayValue = node.current.displayValue;
      node.previous.value = node.current.value;
    });
  }

  openCalendar(obj) {
    this.closeAllOtherCalendars();
    obj.isCalendarOpen = !obj.isCalendarOpen;
  }

  closeAllOtherCalendars(): void {
    this.accountDetails[0].forEach(cal => {
      if (cal.isCalendarOpen) {
        cal.isCalendarOpen = false;
      }
    });
  }

  addNewLine(event?: any): void {
    console.log(event);
    // console.clear();
    this.isNewClicked = false;
    this.businessList.unshift(this.newBusinessLine);
    this.customBusinessList.unshift(this.newBusinessLine);
    if (this.organizational[1][0]?.value?.length) {
      this.organizational[1][0].value.push(this.newBusinessLine);
    } else {
      this.organizational[1][0].value = [this.newBusinessLine];
    }
    this.newBusinessLine = null;
    this.saveCustomBusinessOfLinesOnS3();
  }

  onSelectBusiness(value: any, index: number): void {
    if (value?.target?.checked) {
      if (this.organizational[1][0]?.value?.length) {
        this.organizational[1][0].value.push(this.businessList[index]);
      } else {
        this.organizational[1][0].value = [this.businessList[index]];
      }
    } else {
      if (this.organizational[1][0] && this.organizational[1][0].value) {
        const foundIndex = this.organizational[1][0]?.value?.findIndex(or => or === this.businessList[index]);
        if (foundIndex > -1) {
          this.organizational[1][0].value.splice(foundIndex, 1);
        }
      }
    }
    this.organizational[1][0].value = JSON.parse(JSON.stringify(this.organizational[1][0].value));
  }

  isChecked(item): boolean {
    if (this.organizational[1][0].value) {
      return this.organizational[1][0].value.includes(item);
    }
    return false;
  }

  /**
   *
   * @returns a new structure of object for mid-market entities
   */
  createWizardObj() {
    const newObj: any = {
      step_1: [
        [
          {
            title: 'Client Name',
            value: '',
            asterisk: true,
          },
          {
            title: 'Primary PoC Name',
            value: '',
            asterisk: true,
          },
          {
            title: 'Primary PoC Email',
            value: '',
            asterisk: true,
          },
          {
            industry: '',
          },
        ],
        [
          {
            domains: [],
            isActive: false,
          },
        ],
        [
          {
            asterisk: true,
            displayValue: '2,000',
            showDollar: true,
            title: 'Cost Per Employee',
            value: 2000,
          },
          {
            asterisk: true,
            displayValue: '15,000',
            showDollar: true,
            title: 'Cost Per Server',
            value: 15000,
          },
          {
            asterisk: true,
            displayValue: '2,000',
            showDollar: true,
            title: 'Cost Per Workstation',
            value: 2000,
          },
          {
            asterisk: true,
            displayValue: '20,149',
            title: 'PR and Response Expenses',
            value: 20149,
          },
        ],
        [
          {
            frameworks: [],
          },
        ],
      ],
      step_2: [
        [
          {
            asterisk: false,
            isCalendar: false,
            isDropDownOpened: false,
            list: this.oldAccountTypeList,
            title: 'Account Type',
            value: '',
          },
          {
            asterisk: false,
            isCalendar: true,
            isCalendarOpen: false,
            title: 'Renewal Date',
            value: null,
          },
          {
            asterisk: false,
            isCalendar: true,
            isCalendarOpen: false,
            title: 'Submission Target Date',
            value: null,
          },
          {
            asterisk: false,
            isCalendar: true,
            isCalendarOpen: false,
            title: 'Pre-Submission Kickoff Target',
            value: null,
          },
          {
            asterisk: false,
            isCalendar: false,
            isDropDownOpened: false,
            title: 'Producer',
            value: null,
          },
        ],
        [
          // {
          //   asterisk: false,
          //   isCalendar: false,
          //   isDropDownOpened: false,
          //   title: 'Broker # 1',
          //   value: null,
          // },
          // {
          //   asterisk: false,
          //   isCalendar: false,
          //   isDropDownOpened: false,
          //   title: 'Broker # 2',
          //   value: null,
          // },
          {
            asterisk: false,
            isCalendar: false,
            isDropDownOpened: false,
            title: 'Risk Analyst Email',
            value: null,
          },
          {
            asterisk: false,
            isCalendar: true,
            title: 'Notes',
            value: null,
          },
        ],
        [
          {
            current: {
              displayValue: '',
              value: '',
            },
            previous: {
              displayValue: '',
              value: '',
            },
            title: 'Compensation Amount',
            type: 'number',
          },
          {
            current: {
              displayValue: null,
              value: '',
            },
            previous: {
              displayValue: null,
              value: '',
            },
            title: 'Compensation Type',
            type: 'string',
          },
          {
            current: {
              displayValue: null,
              value: '',
            },
            previous: {
              displayValue: null,
              value: '',
            },
            title: 'Primary Carrier',
            type: 'string',
          },
          {
            current: {
              displayValue: '',
              value: '',
            },
            previous: {
              displayValue: '',
              value: '',
            },
            title: 'Total Limit',
            type: 'number',
          },
          {
            current: {
              displayValue: '',
              value: '',
            },
            previous: {
              displayValue: '',
              value: '',
            },
            title: 'Total Premium',
            type: 'number',
          },
          {
            current: {
              displayValue: '',
              value: '',
            },
            previous: {
              displayValue: '',
              value: '',
            },
            title: 'Primary Limit',
            type: 'number',
          },
          {
            current: {
              displayValue: '',
              value: '',
            },
            previous: {
              displayValue: '',
              value: '',
            },
            title: 'Primary Premium',
            type: 'number',
          },
          {
            current: {
              displayValue: null,
              value: '',
            },
            previous: {
              displayValue: null,
              value: '',
            },
            title: 'Retention',
            type: 'string',
          },
          {
            current: {
              displayValue: '',
              value: '',
            },
            previous: {
              displayValue: '',
              value: '',
            },
            title: 'RW Sublimit',
            type: 'number',
          },
          {
            current: {
              displayValue: '',
              value: '',
            },
            previous: {
              displayValue: '',
              value: '',
            },
            title: 'RW Co-Insurance',
            type: 'number',
          },
        ],
      ],
      step_3: [
        [
          {
            asterisk: true,
            displayValue: '',
            title: 'Annual Revenue',
            value: null,
            showDollar: true,
          },
          {
            asterisk: true,
            displayValue: '',
            title: 'Annual Cost of Goods and/or Services',
            value: null,
            showDollar: true,
          },
          {
            asterisk: true,
            displayValue: '',
            title: 'Number of Servers',
            value: null,
          },
          {
            asterisk: true,
            displayValue: '',
            title: 'Number of Workstations',
            value: null,
          },
          {
            logoS3: null,
          },
        ],
        // [
        //   {
        //     asterisk: false,
        //     isChecked: false,
        //     title: 'Number of PII Records',
        //     value: null,
        //   },
        //   {
        //     asterisk: false,
        //     isChecked: false,
        //     title: 'Number of PHI Records',
        //     value: null,
        //   },
        //   {
        //     asterisk: false,
        //     isChecked: false,
        //     title: 'Number of PIFI Records',
        //     value: null,
        //   },
        //   {
        //     asterisk: false,
        //     isChecked: false,
        //     title: 'Number of PCI Records',
        //     value: null,
        //   },
        // ],
      ],
    };

    newObj.step_1.forEach((obj, outerIndex) => {
      switch (outerIndex) {
        case 0:
          obj.forEach((innerObj, innerIndex) => {
            if (Object.prototype.hasOwnProperty.call(innerObj, 'industry')) {
              innerObj.industry = [];
              innerObj.industry.push(this.organizational[2].industry);
            } else {
              innerObj.value = this.organizational[outerIndex][innerIndex].value;
            }
          });
          break;
        case 1:
          obj[0].isActive = this.activeScan;
          this.newActiveDomains.forEach(domain => {
            obj[0].domains.push(domain.value);
          });
          break;
        case 2:
          obj.forEach((innerObj, innerIndex) => {
            innerObj.value = this.organizational[1][innerIndex].value;
            innerObj.displayValue = this.organizational[1][innerIndex].displayValue;
          });
          break;
        default:
          obj[0].frameworks = [];
          obj[0].frameworks.push(this.selectedRiskFramework);
          break;
      }
    });

    newObj.step_2.forEach((obj, outerIndex) => {
      switch (outerIndex) {
        case 0:
          obj.forEach((innerObj, innerIndex) => {
            innerObj.asterisk = this.accountDetails[outerIndex][innerIndex].asterisk;
            innerObj.isCalendar = this.accountDetails[outerIndex][innerIndex].isCalendar;
            innerObj.title = this.accountDetails[outerIndex][innerIndex].title;
            innerObj.value = this.accountDetails[outerIndex][innerIndex].value;
            if (Object.prototype.hasOwnProperty.call(innerObj, 'isDropDownOpened')) {
              innerObj.isDropDownOpened = this.accountDetails[outerIndex][innerIndex].isDropDownOpened;
            }
          });
          break;

        case 1:
          obj.forEach((innerObj, innerIndex) => {
            innerObj.asterisk = this.accountDetails[outerIndex][innerIndex].asterisk;
            innerObj.isCalendar = this.accountDetails[outerIndex][innerIndex].isCalendar;

            if (Object.prototype.hasOwnProperty.call(innerObj, 'isDropDownOpened')) {
              innerObj.isDropDownOpened = this.accountDetails[outerIndex][innerIndex].isDropDownOpened;
            }

            if (Object.prototype.hasOwnProperty.call(innerObj, 'isCalendarOpen')) {
              innerObj.isCalendarOpen = this.accountDetails[outerIndex][innerIndex].isCalendarOpen;
            }

            if (Object.prototype.hasOwnProperty.call(innerObj, 'list')) {
              innerObj.list = this.accountDetails[outerIndex][innerIndex].list;
            }
            innerObj.title = this.accountDetails[outerIndex][innerIndex].title;
            innerObj.value = this.accountDetails[outerIndex][innerIndex].value;
          });

          break;
        default:
          obj.forEach((innerObj, innerIndex) => {
            innerObj.current = this.currentYear[0][innerIndex].current;
            innerObj.previous = this.currentYear[0][innerIndex].previous;
            innerObj.title = this.currentYear[0][innerIndex].title;
            innerObj.type = this.currentYear[0][innerIndex].type;
          });
          break;
      }
    });
    newObj.step_3.forEach((obj, outerIndex) => {
      switch (outerIndex) {
        case 0:
          obj.forEach((innerObj, innerIndex) => {
            if (Object.prototype.hasOwnProperty.call(innerObj, 'logoS3')) {
              innerObj.logoS3 = this.uploadedLogo;
            } else {
              innerObj.title = this.newObj.step_3[outerIndex][innerIndex].title;

              innerObj.displayValue = this.newObj.step_3[outerIndex][innerIndex].displayValue;
              innerObj.value = this.newObj.step_3[outerIndex][innerIndex].value;

              innerObj.asterisk = this.newObj.step_3[outerIndex][innerIndex].asterisk;

              innerObj.isChecked = true;
            }
          });
          break;
        default:
          obj.forEach((innerObj, innerIndex) => {
            innerObj.title = this.newObj.step_3[outerIndex][innerIndex].title;
            innerObj.value = this.newObj.step_3[outerIndex][innerIndex].value;
            innerObj.asterisk = this.newObj.step_3[outerIndex][innerIndex].asterisk;
            innerObj.isChecked = true;
          });
          break;
      }
    });

    return newObj;
  }

  sendConfirmationModal(header: TemplateRef<any>, content: TemplateRef<any>, footer: TemplateRef<any>): void {
    // Function to open the modal pop up to edit entity
    const modalRef = this.modalService.open(ModalTemplateComponent, this.ngbModalSendClient);
    const compInstance = modalRef.componentInstance;
    compInstance.customizeHeader = true;
    compInstance.headerContentRef = header;
    compInstance.contentRef = content;
    compInstance.footerContentRef = footer;
    this.deleteModalInstance = modalRef;
  }

  sendClientEmail(): void {
    this.closeDeleteModal();
    this.sendToClient();
  }

  /**
   * This function will create a sub-entity leader
   */
  async sendToClient(isWizardCompleted: boolean = false) {
    if (!isWizardCompleted) {
      await this.saveWizardDraft(true);
    }
  }

  /**
   * initialize user with necessary fields
   */
  initUser(
    name: string = '',
    email: string = '',
    isWizardCompleted: boolean = false,
    wizardId: string = '',
    entityLeaderId: string = ''
  ): any {
    return {
      name,
      email,
      role: 'CUSTOM',
      roleId: entityLeaderId, // Entitiy leader role id
      isCognitoUser: false,
      questionMap: JSON.stringify({}),
      isViewOnly: false,
      clickThrough: false,
      clickThroughDate: null,
      entityIds: [wizardId],
      // if the wizard is not completed then we are passing wizard id here so that client can update the form
      // and save draft but cannot complete the wizard creation process
      entityId: wizardId,
      temporaryHigherRank: true,
      onBoardingStatus: isWizardCompleted ? MidMarketEnum.STEP_2 : MidMarketEnum.STEP_1,
    };
  }

  /**
   *  add new User to database and update the Current Users list here
   * @param newUser is type of User Object
   */
  async addUser(newUser: CreateUserInput, showTost: boolean = false): Promise<void> {
    if (newUser) {
      try {
        // checking weather the suer already added or not
        const midMarketUser = (await this.customApi.UserByEmail(newUser.email)) as any;
        if (midMarketUser?.items?.length) {
          if (showTost) {
            this.toastr.error('User Already Added');
            return;
          }
          this.userId = this.wizardData?.userId;
          // updating user if the onBoarding status is changed
          // added upperDeck check here because we not need to call update user when we edit wizard from upperDeck
          if (
            this.userId &&
            midMarketUser.items[0].onBoardingStatus !== newUser.onBoardingStatus &&
            !this.fromUpperDeck &&
            !this.fromClientScreen
          ) {
            const params = {
              id: this.userId,
              onBoardingStatus: MidMarketEnum.STEP_2,
            };
            await this.customApi.UpdateUser(params);
          }
        } else {
          // create new midmarket user
          newUser.role = RoleEnum.CUSTOM;
          const user = await this.authService.addNewParticipant(newUser);
          const tempUser = JSON.parse(user);
          this.userId = tempUser.id;
          this.toastr.success('Invite Sent To Client');
        }
      } catch (e) {
        console.log('Error while adding user:', e);
      }
    }
  }

  /**
   * Function sends Email Notifications to brokers
   */
  async sendNotification(): Promise<void> {
    const mailBrokers: _Broker[] = []; //* brokers whom mail will be sent

    //* skipping last 2 elements
    this.accountDetails[1].slice(0, -2).forEach(brokerObj => {
      mailBrokers.push({ name: brokerObj.name, email: brokerObj.value });
    });
    // const assignedUserArray = Array.from(uniqueEmailsSet);
    // console.log(assignedUserArray);
    // const userName = this.accountDetails[1][0].value;
    const clientName = this.organizational[0][1]?.value;
    if (mailBrokers.length) {
      const json = {
        type: 'blockerUser',
        assignedUsers: mailBrokers,
        clientName,
      };
      try {
        await this.lambdaService.invokeLambda('createNotifications', json);
        this.toastr.success('Notifications Email Sent to Broker Successfully!!');
      } catch (error) {
        this.toastr.error('Issue While Sending Notification Email');
        console.log('Issue While Sending Notification Email ' + JSON.stringify(error));
      }
    }
  }

  /**
   * This function will upload the file on S3 once form is complete and wizard will create
   */
  async uploadFilesToS3(id: string): Promise<void> {
    // upload zip files to s3 bucket
    try {
      const s3Inputs = [];
      for (const item of this.revenueDetails[5].bodyItems) {
        if (item.files.length) {
          item.files.map((zip, idx) => {
            if (item.isFileUploaded && !zip.url) {
              const s3Id = uuid();
              const extensionRegex = /\.[^.]+$/;
              // Replace any extension with .zip
              const zipRemove = zip.name;
              const updatedFilename = zipRemove.replace(extensionRegex, '');
              item.files[idx].url = `MIDMARKET/${FileTypeEnum.CLIENT_WIZARD_FILES}/${id}/${s3Id}#${updatedFilename}`;
              s3Inputs.push({
                id: s3Id,
                name: updatedFilename,
                fileType: FileTypeEnum.CLIENT_WIZARD_FILES,
                contentType: zip.file.type,
                body: zip.file,
                date: Date.now(),
                wizardId: id,
                url: `MIDMARKET/${FileTypeEnum.CLIENT_WIZARD_FILES}/${id}/${s3Id}#${updatedFilename}`,
              });
            }
          });
        }
      }
      if (s3Inputs.length) {
        await Promise.all(
          s3Inputs.map(async input => {
            if (input) {
              try {
                await this.fileService.uploadToS3(input);
              } catch (err) {
                this.toastr.error('File Uploading Failed');
                console.log('File uploading error');
              }
            }
          })
        );
      }
    } catch (err) {
      console.log('Error while uploading files');
      this.toastr.error('File Uploading Failed');
    }
  }

  /**
   * this function will remove the selected file from file upload pop-up
   * @param file selected file
   * @param idx index
   */
  removeFiles(file, idx): void {
    const index = this.revenueDetails[5].bodyItems[idx].files.findIndex(
      item => item.name.toLowerCase() === file.name.toLowerCase()
    );
    this.removedFiles.push(this.revenueDetails[5].bodyItems[idx].files[index]);
    this.revenueDetails[5].bodyItems[idx].files.splice(index, 1);
    // revenue details checkbox handling on the basis of files
    this.revenueDetails[5].bodyItems[idx].isChecked =
      this.revenueDetails[5].bodyItems[idx].files.length > 0 ? true : false;
  }
  /**
   * This is emitter function will call when user select file
   */
  uploadFiles(index: number): void {
    const modalRef = this.modalService.open(MidMarketFileUploadComponent, this.modalFileUploadScreen);
    modalRef.componentInstance.existingFiles = this.revenueDetails[5].bodyItems[index].files;
    modalRef.componentInstance.removeFile.subscribe((file: any) => {
      this.removeFiles(file, index);
    });
    modalRef.componentInstance.saveFiles.subscribe(res => {
      if (res.length) {
        this.revenueDetails[5].bodyItems[index].files = res;
        // revenue details checkbox handling on the basis of files
        this.revenueDetails[5].bodyItems[index].isChecked = true;
        this.revenueDetails[5].bodyItems[index].isFileUploaded = true;
      }
    });
    modalRef.componentInstance.closeModalClicked.subscribe(() => {
      modalRef.close();
    });
  }
  ngOnDestroy(): void {
    this.subscription.forEach(listener => listener.unsubscribe());
  }
}
