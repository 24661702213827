import { CollectionService } from 'app/collection/collection.service';
import { AuthService } from 'app/auth/auth.service';
import { QuestionnaireService } from 'app/questionnaire/questionnaire.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetUserQuery, RiskAction, RoleEnum } from 'app/API.service';
import { AnswerEnum } from '../enums/answer.enum';
import { ManagerActionEnum } from '../enums/manager-action.enum';
import { UtilsService } from '../utils.service';
import { LogsTypeEnum } from '../enums/logsKey.enum';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'cygov-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class DeleteModalComponent implements OnInit {
  @Input() titleText = 'DELETE';
  @Input() removeOrCloseText = 'remove';
  @Input() removeLabelText = 'this content';
  @Input() removeFromText = '';
  @Input() users: GetUserQuery[];
  @Input() show2ndConfirmText = '';
  @Input() deleteEntityModal = false;
  @Input() showPermanentText = true;
  @Input() entityType = '';
  @Input() itemName = '';
  @Input() commentHeading = '';
  @Input() currentUser;
  @Input() role;
  @Input() selectedQuestions = [];
  @Input() artifactsNames = [];
  @Input() hasPermission = false;
  @Input() isConfirm2Required: boolean = false;
  @Input() isThirdPartyVendorDeletion: boolean = false;
  @Input() svgName: string = 'trash-icon';
  @Input() activeAssessmentId: string = '';
  @Input() isCustomContent: boolean = false;
  @Input() deleteFrameworkText: string = '';
  @Input() showLineBreak: boolean = true;
  @Input() isFromRiskAiConfonfirmation: boolean = false; // to customise for risk ai
  @Output() deleteFramework = new EventEmitter<boolean>();
  @Output() deleteVendor = new EventEmitter<boolean>();
  @Output() updatedQuestion = new EventEmitter<any>();
  @Output() updatedQuestions = new EventEmitter<any>(); // could be single questions or list of questions
  @Output() updatedComment = new EventEmitter<any>();
  showColumnTooltip: boolean = false;
  MANAGE_ACTION_ENUM = {
    APPROVE: 'approve',
    DENY: 'deny',
    GAP: 'gap',
  };

  confirmationLevel = 1;
  confirmationString = '';
  comment: string = '';

  svgNameDescription = {
    'trash-icon-2': [
      'Please note you are about to delete this requirement.',
      ' The vendor will no longer be required to provide this item.',
    ],
    'trash-icon-3': [
      'Please note you are about to delete this requirement.',
      ' The vendor will no longer be required to provide this item.',
    ],
    'restart-questionnaire': [
      'Please note you are about to restart Vendor Risk questionnaire.',
      'All answers in this framework will be deleted and will permanently be non-editable.',
    ],
    'archive-questionnaire': [
      'Please note you are about to archive Risk framwork questionnaire(s).',
      'All data in this framework(s) will be archived and no longer editable permanently.',
    ],
    deny: ['Please note you are about to deny the: '],
    gap: ['Please note you are about to open a gap related to: '],
    approve: ['Please note you are about to approve the:'],
  };
  confirmationLevel2Text = 'Are you sure you want to delete?';
  targetId: string;
  type: string;
  showToast: any = true;
  permissionToAnswer: any;
  questionsToSave = [];
  @Output() closeModal = new EventEmitter<boolean>();
  constructor(
    public activeModal: NgbActiveModal,
    private questionnaireService: QuestionnaireService,
    private collectionService: CollectionService,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.users = this.users || [];
    this.confirmationString = this.isCustomContent ? 'confirm' : '';
  }

  onValueChanged(value) {
    this.confirmationString = value;
  }

  // Level 1 confirmation is the one in which we have to type confirm
  confirmLevel1(): void {
    if (this.isConfirm2Required) {
      this.confirmationLevel = 2;
    } else {
      this.confirmLevel2();
    }
  }

  // Level 2 confirmation when confirm then framework will be delete
  confirmLevel2(): void {
    if (
      this.svgName?.toLowerCase() === this.MANAGE_ACTION_ENUM.APPROVE.toLowerCase() ||
      this.svgName?.toLowerCase() === this.MANAGE_ACTION_ENUM.DENY.toLowerCase() ||
      this.svgName?.toLowerCase() === this.MANAGE_ACTION_ENUM.GAP.toLowerCase()
    ) {
      this.saveQuestion();
    } else {
      this.deleteFramework.emit(true);
      this.activeModal.close();
    }
  }

  confirmLevel(): void {
    if (this.confirmationLevel === 1 && this.confirmationString === 'confirm') {
      this.confirmLevel1();
    } else if (this.confirmationLevel === 2) {
      this.confirmLevel2();
    }
    if (this.confirmationString === 'confirm') {
      if (this.isConfirm2Required && this.confirmationLevel === 2) {
        return;
      } else if (!this.isConfirm2Required && this.confirmationLevel === 1) {
        this.deleteFramework.emit(true);
        this.activeModal.close();
      }
    }
  }

  returnToLevel1(): void {
    this.confirmationLevel = 1;
    this.confirmationString = '';
  }

  /**
   * will copy confirm word to clipboard
   */
  copyToClipboard(): void {
    navigator.clipboard
      .writeText('confirm')
      .then()
      .catch(e => console.error(e));
  }

  /**
   * This function will convert manager answers to their dependent options for scoring
   * @param action selected option fromquestion
   * @returns ANSERENUM (YES,NO,NOT_APPLICABLE)
   */
  answerEnumToNumForManager(action: string): number {
    switch (action) {
      case ManagerActionEnum.APPROVE:
        return UtilsService.answerEnumToNum(AnswerEnum.YES);
      case ManagerActionEnum.DENIED:
        return UtilsService.answerEnumToNum(AnswerEnum.NO);
    }
  }

  /**
   * Function to check weather there is a file before approving a question
   * @returns boolean
   */
  checkIfFile(): boolean {
    let fileExists = true;
    let fileFound = true;
    this.selectedQuestions?.forEach(question => {
      this.permissionToAnswer = this.collectionService.checkPermissionToAnswerQuestion(question, question?.assignments);
      if (!question?.answers?.length && !question?.managerAnswers?.length) {
        fileExists = fileFound = false;
        return fileFound;
      }
      question?.answers?.forEach(qAnswer => {
        fileExists = !!qAnswer?.file?.length;
        if (!fileExists) {
          fileFound = false;
        }
        if (!fileExists || (this.currentUser?.isViewOnly && !this.permissionToAnswer)) {
          fileExists = false;
        }
      });
      question?.managerAnswers?.forEach(qAnswer => {
        fileExists = !!qAnswer?.file?.length;
        if (!fileExists) {
          fileFound = false;
        }
        if (!fileExists || (this.currentUser?.isViewOnly && !this.permissionToAnswer)) {
          fileExists = false;
        }
      });
    });
    return fileFound;
  }

  /**
   * This function will save manager answer
   * @returns void
   */

  async saveQuestion(): Promise<void> {
    let checkIfFile: boolean = true;
    if (this.svgName?.toLowerCase() === ManagerActionEnum.APPROVE.toLowerCase()) {
      checkIfFile = this.checkIfFile();
    }
    if (!checkIfFile) {
      this.toastr.info('Please upload artifacts before approving answer');
      return;
    }
    const promises = [];
    this.selectedQuestions?.forEach(question => {
      question.activeAssessmentId = this.activeAssessmentId;
      question.assessmentIds = [this.activeAssessmentId];

      let answer = {
        assessmentId: question.activeAssessmentId,
        userId: this.currentUser.id,
        file: [],
        answer: 0,
        isUptodate: false,
        questionId: question.id,
      };
      // 1 - we need to check if the answer is already exist or not.
      const answerExists = question?.answers?.find(
        a => a?.userId === this.currentUser?.id && a?.assessmentId === question?.activeAssessmentId
      );
      const answerManagerExists = question?.managerAnswers?.find(
        a => a?.userId === this.currentUser?.id && a?.assessmentId === question?.activeAssessmentId
      );
      if (answerExists) {
        answer = { ...answerExists };
      }
      if (answerManagerExists) {
        answer = { ...answerManagerExists };
      }
      if (answer?.file?.length) {
        answer.file = answer.file.map(file => file.fileObj);
      }
      answer.answer = this.answerEnumToNumForManager(
        this.svgName?.toLowerCase() === this.MANAGE_ACTION_ENUM.APPROVE.toLowerCase()
          ? ManagerActionEnum.APPROVE.toLowerCase()
          : ManagerActionEnum.DENIED.toLowerCase()
      );
      if (answer?.answer >= 0) {
        promises.push(this.saveAnswer({ ...answer }, this.currentUser, { ...question }));
      }
    });
    try {
      await Promise.all(promises);
    } catch (e) {
      console.log('Error', e);
    }
    const commentWithQuestions = {
      comment: this.comment,
      questions: this.questionsToSave,
    };
    if (
      this.svgName?.toLowerCase() === this.MANAGE_ACTION_ENUM.DENY ||
      this.svgName?.toLowerCase() === this.MANAGE_ACTION_ENUM.GAP
    ) {
      this.questionsToSave.forEach(question => {
        question.status = 'Denied';
      });
    } else if (this.svgName?.toLowerCase() === this.MANAGE_ACTION_ENUM.APPROVE) {
      this.questionsToSave.forEach(question => {
        question.status = 'Approved';
      });
    }
    if (this.showToast) {
      this.toastr.success('Saved Successfully');
      this.updatedQuestions?.emit(commentWithQuestions);
    } else {
      this.showToast = true;
    }
  }

  async saveAnswer(userAnswer = null, currentUser, question): Promise<void> {
    try {
      // Initialize answer array if not exists
      if (!question.answers) {
        question.answers = [];
      }
      if (!question.managerAnswers) {
        question.managerAnswers = [];
      }
      /**
       * Fix: multiple users upload artifact issue
       * 'answer' is coming from 'saveFiles' when uploading artifact.
       * on multiple users, "this.answer" does not have the right artifacts
       */
      // const userAnswer = answer ? answer : this.answer;
      if (userAnswer?.file?.length) {
        userAnswer?.file?.forEach(input => delete input?.__typename);
      }

      if (question?.activeAssessmentId && question?.id) {
        this.targetId = `${question.activeAssessmentId}#${question.id}`;
      }

      if (question?.frameworkName) {
        this.type = `${LogsTypeEnum?.QUESTION}#${question?.frameworkName?.toUpperCase().replaceAll(' ', '_')}`;
      }

      // Update answer if already answered or add new answer if unanswered
      userAnswer.isUptodate = false;
      if (this.hasPermission || this.role.name === RoleEnum.ADMIN.toLowerCase()) {
        if (this.svgName !== 'gap') {
          question.riskAction = RiskAction.MANAGED;
          userAnswer.isActionTaken = true;
          userAnswer.riskAction = RiskAction.MANAGED;
        } else {
          question.riskAction = RiskAction.IGNORED;
          userAnswer.isActionTaken = true;
          userAnswer.riskAction = RiskAction.IGNORED;
        }
      } else {
        question.riskAction = null;
        userAnswer.isActionTaken = false;
      }

      question.frameworkName = question?.frameworkName?.replaceAll(' ', '_')?.toUpperCase();
      await this.questionnaireService.addAnswerWithAssignments(
        question,
        this.currentUser?.id,
        { ...userAnswer },
        question?.assignments ? question?.assignments : [],
        null,
        null,
        this.targetId,
        this.type,
        currentUser?.name,
        false,
        false
      );
      this.questionsToSave.push({ ...question });
    } catch (err) {
      console.log('Error while saving answer', err);
      this.toastr.error('Error while saving answer');
    }
  }

  hideModal() {
    this.activeModal.close();
    this.closeModal?.emit(false);
  }
}
