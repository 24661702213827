import { Injectable } from '@angular/core';
import { parse, unparse } from 'papaparse';
import { v4 as uuid } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { Storage } from 'aws-amplify';
import { FileTypeEnum } from 'app/API.service';
import { NetskopeFileEnum } from 'app/shared/enums/netskope-board.enum';
import { NGXLogger } from 'ngx-logger';
import { ObjectsSort } from './helpers/generic-helpers';
import { Auth } from 'aws-amplify';
// fix import - build-optimization-1
import Lambda from 'aws-sdk/clients/lambda';
import { ToastrService } from 'ngx-toastr';
import * as JSZip from 'jszip';
import { UtilsService } from 'app/shared/utils.service';
import { ActivatedRoute } from '@angular/router';
import {
  FRAMEWORK_RANGES_PATH,
  frameworkRangeMapPath,
  FIRST_PARTY_ENUMS_PATH,
  THIRD_PARTY_ENUMS_PATH,
} from 'app/app.paths';
import { FrameworkEnumTypes } from 'app/shared/enums/framework-enum-types.enum';
import { ClientLambdaService } from './client-lambda.service';
// npm install pdfjs-dist@2.9.359
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import { environment } from 'environments/environment';

export interface IstandardsNIndustries {
  category: string;
  standards_name: string;
  industry: string;
  comments: string;
}

export interface StoragePutResponse {
  key: string;
}

@Injectable()
export class FileService {
  static fileSize = 31457280; // 30MB
  static logoFileSizeLimit = 2097152; // 2MB

  encryptedFolders = ['ASSESSMENTS/', 'public/ASSESSMENTS/'];

  frameworkRangeMap = null;
  private frameworkRangeDictionary = {}; // Store the Data in key value Pair ( To make the Searching Fast in O(n) )
  StandardEnum: null;
  RiskFrameworkEnum: null;
  VendorRiskFrameworkEnum: null;
  CERTIFICATIONS: null; // these  Enums used for Vendor Compliance Frameworks
  isAllEnumsLoaded = false;
  private maliciousPatterns = [
    /<script.*?>.*?<\/script>/gi, // Detect <script> tags
    /exec\(.*?\)/gi, // Detect exec() function calls
    /system\(.+?\)/gi, // Detect system() function calls
    /\/Type\s*\/Action\s*\/S\s*\/JavaScript/gi, // Detect JavaScript actions
    /app\.trustedFunction\s*=\s*function/gi, // Detect potential JavaScript-based attacks
    // eslint-disable-next-line max-len
    /(?:\bvar\b|\bconst\b)[\s\S]*?(?:[;{}]|(?=<\/?\w+>))/g, // Detect any type of javascript code in the pdf file
  ];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private clientLambdaService: ClientLambdaService
  ) {
    this.init();
  }

  async init(): Promise<boolean> {
    try {
      const promises = [];
      // step 1 : get Framework Ranges
      promises.push(this.getFrameworkRangeMap());
      // step 2 : get First party framework Enums
      promises.push(this.getFirstPartyEnums());
      // step 3 : get Third Party Framework Enums
      promises.push(this.getThirdPartyEnums());

      await Promise.all(promises);

      this.isAllEnumsLoaded = true;
      return true;
    } catch (e) {
      console.log('Error : ');
    }
  }

  /**
   * Get All Global Framework Ranges.
   */
  async getFrameworkRangeMap(): Promise<any[]> {
    try {
      if (!this.frameworkRangeMap) {
        const responseData = await this.getFileByPathFromS3(frameworkRangeMapPath);
        if (responseData) {
          this.frameworkRangeMap = responseData.FrameworkRangeMap;
          this.buildFrameworkRangesDictionary();
        }
      }
      return this.frameworkRangeMap;
    } catch (e) {
      console.log('Error : ', e);
    }
    return [];
  }
  /**
   * Create the Dictionary from Framework Ranges map list to keep data in key value pair.
   * Make the Searching FAST. It will take O(1) to get value instead of getting value in O(n)
   */
  private buildFrameworkRangesDictionary(): void {
    if (this.frameworkRangeMap) {
      this.frameworkRangeMap.forEach(framework => {
        this.frameworkRangeDictionary[framework.name] = framework;
      });
    }
  }
  /**
   * @param frameworkKey framework name as key
   * @returns framework with left and Right Ranges
   */
  getFrameworkWithRange(frameworkKey: string): any {
    let frameKey = frameworkKey;
    if (frameKey.includes('#')) {
      frameKey = frameKey.split('#')[0];
    }
    return this.frameworkRangeDictionary[frameKey];
  }

  /**
   * @param id will be either rootEntityId,childEntityId or vendorId
   * @returns custom Frameworks Ranges
   */
  async getCustomFrameworkRangeMap(id: string): Promise<any[]> {
    try {
      const responseData = await this.getFileByPathFromS3(FRAMEWORK_RANGES_PATH + id + '.json');
      return responseData.FrameworkRangeMap ? responseData.FrameworkRangeMap : [];
    } catch (e) {
      console.log('Error : ', e);
    }
    return [];
  }
  /**
   * get the Cashed Enums that shared in s3 instead of local
   * @param frameworkEnumType is type of FrameworkEnumTypes
   * @returns Enum Object
   */
  importFrameworkEnumsFromS3(frameworkEnumType: FrameworkEnumTypes): any {
    if (this.isAllEnumsLoaded) {
      let enumData = null;
      switch (frameworkEnumType) {
        case FrameworkEnumTypes.RISK_FRAMEWORK_ENUM:
          enumData = this.RiskFrameworkEnum;
          break;
        case FrameworkEnumTypes.STANDARD_ENUM:
          enumData = this.StandardEnum;
          break;
        case FrameworkEnumTypes.VENDOR_RISK_FRAMEWORK_ENUM:
          enumData = this.VendorRiskFrameworkEnum;
          break;
        case FrameworkEnumTypes.CERTIFICATIONS_ENUM:
          enumData = this.CERTIFICATIONS;
          break;
      }
      return enumData;
    } else {
      console.log('Framework Enums Fail to load from s3 ');
    }
    return null;
  }

  /**
   * get StandardEnum and RiskFrameworkEnum Object from s3( Risk & Compliance Framework )
   * @returns an object of Standards
   */
  async getFirstPartyEnums(): Promise<void> {
    try {
      if (!this.StandardEnum || !this.RiskFrameworkEnum) {
        const responseData = await this.getFileByPathFromS3(FIRST_PARTY_ENUMS_PATH);
        if (responseData) {
          this.StandardEnum = responseData.StandardEnum;
          this.RiskFrameworkEnum = responseData.RiskFrameworkEnum;
        }
      }
    } catch (e) {
      console.log('S3->FrameworkEnums : ', e);
    }
  }

  /**
   * get CERTIFICATIONS and VendorRiskFrameworkEnum Object from s3( Risk & Compliance Framework )
   * @returns an object of Standards
   */
  async getThirdPartyEnums(): Promise<void> {
    try {
      if (!this.VendorRiskFrameworkEnum || !this.CERTIFICATIONS) {
        const responseData = await this.getFileByPathFromS3(THIRD_PARTY_ENUMS_PATH);
        if (responseData) {
          this.VendorRiskFrameworkEnum = responseData.VendorRiskFrameworkEnum;
          this.CERTIFICATIONS = responseData.CERTIFICATIONS;
        }
      }
    } catch (e) {
      console.log('S3->FrameworkEnums : ', e);
    }
  }

  static validateFileSize(file: any): boolean {
    return file.size > FileService.fileSize;
  }

  static validateFileType(file: any, fileType: string): boolean {
    // verification for FileTypeEnum
    const ext = file.name.match(/\.([^.]+)$/)[1];
    const allowImgTypes = ['jpg', 'jpeg', 'bmp', 'png', 'tif'];
    const allowArtifactsTypes = ['doc', 'docx', 'csv', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'];
    //* Mid market File types
    const midMarketAllowedFileTypes = ['doc', 'docx', 'pdf', 'txt', 'jpg', 'jpeg', 'png'];
    let validType = false;
    switch (fileType) {
      case FileTypeEnum.LOGO:
        if (allowImgTypes.includes(ext)) {
          validType = true;
        }
        break;
      case FileTypeEnum.ARTIFACTS:
      case FileTypeEnum.MANUAL_ARTIFACT:
      case FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE:
        if (allowImgTypes.includes(ext) || allowArtifactsTypes.includes(ext)) {
          validType = true;
        }
        break;
      case FileTypeEnum.MIDMARKET_FILES:
        if (midMarketAllowedFileTypes.includes(ext)) {
          validType = true;
        }
        break;
    }

    return validType;
  }

  async uploadToS3(s3File: any): Promise<any> {
    if (s3File) {
      s3File.name = s3File?.name?.replace(/\s/g, '');
      const config: any = { contentType: s3File.contentType };
      let path;
      switch (s3File.fileType) {
        case FileTypeEnum.LOGO:
          config.level = 'public';
          path = `CLIENTS/${s3File.entityId}/${s3File.fileType}/${s3File.name}`;
          break;
        case FileTypeEnum.ARTIFACTS:
          s3File.id = uuid();
          path = `CLIENTS/${s3File.entityId}/${s3File.fileType}/${s3File.id}`;
          config.level = 'public';
          config.download = true;
          break;
        case 'HITRUST':
          path = `ASSESSMENTS/${s3File.assessmentId}/hitrust-verification/${s3File.name}`;
          config.level = 'public';
          s3File.body = JSON.stringify(s3File); // or JSON.stringify(s3File).body;
          break;
        case FileTypeEnum.REVISIONS:
          break;
        case FileTypeEnum.BOARD_REPORTS:
          path = `${FileTypeEnum.BOARD_REPORTS}/${s3File.entityId}/${s3File.id}.zip`;
          config.level = 'public';
          // s3File.body = JSON.stringify(s3File); // or JSON.stringify(s3File).body;
          break;
        case FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE:
          s3File.id = uuid();
          path = `${FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE}/${s3File.entityId}/${s3File.name}/${s3File.id}`;
          config.level = 'public';
          config.download = true;
          break;
        case 'SUPPORT_FILES':
          s3File.id = uuid();
          path = `SUPPORT_FILES/${s3File.assessmentId}/${s3File.assessmentId}.zip`;
          config.level = 'public';
          config.download = true;
          break;
        case NetskopeFileEnum.NETSKOPE_MATURITY_DATA:
          path = `NETSKOPE_JIRA/SAVED_JSON_DATA/${s3File.id}/MATURITY.json`;
          config.level = 'public';
          s3File.body = JSON.stringify(s3File);
          config.download = true;
          break;
        case NetskopeFileEnum.NETSKOPE_OVERVIEW_DATA:
          path = `NETSKOPE_JIRA/SAVED_JSON_DATA/${s3File.id}/OVERVIEW.json`;
          config.level = 'public';
          s3File.body = JSON.stringify(s3File);
          config.download = true;
          break;
        case FileTypeEnum.CLIENT_WIZARD_FILES:
          path = s3File.url;
          config.level = 'public';
          config.download = true;
          break;
        case FileTypeEnum.E_SIGNATURE:
          path = s3File.url;
          config.level = 'public';
          config.download = true;
          break;
      }

      try {
        config.resumable = true;
        const uploadResult = await new Promise((resolve, reject) => {
          Storage.put(path, s3File.body, {
            contentType: s3File.contentType,
            level: config.level,
            resumable: true,
            completeCallback: event => {
              console.log(`Upload completed: ${event.key}`);
              s3File.key = event.key;
              delete s3File.body;
              resolve(s3File);
            },
            progressCallback: progress => {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            errorCallback: err => {
              console.error('Error uploading file', err);
              this.toastr.error('Internal server error while uploading file(s).');
              reject(err);
            },
          });
          this.toastr.info(
            `Your file(s) is being uploaded in the background. This process can take several minutes. 
            Please do not close or refresh the browser.`
          );
        });
        this.toastr.success('The file(s) has been successfully uploaded.');
        return uploadResult;
      } catch (e) {
        this.logger.error('uploadToS3 - Error: ', e);
        return Promise.reject(e);
      }
    }
  }

  downloadFromS3(s3File: any): Promise<any | string> {
    delete s3File.__typename;
    const config: any = { contentType: s3File.contentType, level: 'public' };
    const key = s3File.key.includes('public/') ? s3File.key.replace('public/', '') : s3File.key;
    return Storage.get(key, config);
  }

  async downloadFileFromS3(path: string): Promise<any | string> {
    const key = path.includes('public/') ? path.replace('public/', '') : path;
    return Storage.get(key);
  }

  deleteFromS3(s3File: any): Promise<any> {
    const config: any = { level: 'public' };
    const key = s3File.key.includes('public/') ? s3File.key.replace('public/', '') : s3File.key;
    return Storage.remove(key, config);
  }

  /**
   * Delete multiple files at a folder/directory from S3
   * @param basicPath contain path of a folder
   * @param filesNames names of files to delete with file extension
   * @returns deleted files status
   */
  async deleteMultipleFilesFromS3(basicPath: string, filesNames: [string]): Promise<any> {
    try {
      if (basicPath && filesNames?.length) {
        const promises = [];
        filesNames.map(name => {
          promises.push(this.deleteFromS3({ key: basicPath + name }));
        });
        return await Promise.all(promises);
      }
    } catch (e) {
      console.log('Error : ', e);
    }
    return null;
  }

  getBase64(file: any): Promise<any | string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getImgFileFromBase64(dataurl: any, filename): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  parseFile(csv): Promise<any> {
    return new Promise((resolve, reject) => {
      parse(csv, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        delimiter: ',',
        encoding: 'UTF-8',
        complete(results, file) {
          // console.log(`file => ${file}`);
          // console.clear();
          resolve(results);
        },
        error(err) {
          this.logger.error(err);
          reject(err);
        },
      });
    });
  }
  // This is synchronous function hence no Promise required
  unParseFile(json): string {
    return unparse(json, {
      header: true,
      delimiter: ',',
    });
  }

  getFile(filePath: string, options: any = { responseType: 'text' }): Promise<any> {
    return this.http.get(filePath, { ...options }).toPromise();
  }

  async uploadToS3CustomPath(path: string, body: any, config: any): Promise<any> {
    try {
      const { key } = (await Storage.put(path, body, config)) as StoragePutResponse;
      return key;
    } catch (e) {
      this.logger.error('uploadToS3 - Error: ', e);
      return Promise.reject(e);
    }
  }

  async getUploadedImg(e: Event): Promise<any> {
    return new Promise((res, rej) => {
      const eventTarget: HTMLInputElement = e.target as HTMLInputElement;
      const imageFile = e && eventTarget.files[0] ? eventTarget.files[0] : null;
      if (!imageFile) {
        res(null);
      }
      try {
        res(this.getBase64(imageFile));
      } catch (error) {
        rej(error);
      }
    });
  }

  verifyUploadFile(e: any, fileType: string): Promise<boolean> {
    return new Promise((res, rej) => {
      const eventTarget: any = (e?.target as HTMLInputElement) || (e as unknown as FileList[]);
      const file: any =
        eventTarget.files && eventTarget?.files[0]
          ? eventTarget?.files[0]
          : eventTarget.length && eventTarget[0]
          ? eventTarget[0]
          : eventTarget
          ? eventTarget
          : null;
      let errMsg = null;
      // verification check for all files
      if (!file) {
        errMsg = 'An occurred while uploading file';
        rej(errMsg);
      }
      // verification for FileTypeEnum
      const ext = file.name.match(/\.([^.]+)$/)[1];
      const allowImgTypes = ['jpg', 'jpeg', 'bmp', 'png', 'tif'];
      const allowArtifactsTypes = ['doc', 'docx', 'csv', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'txt'];
      let validType = false;
      switch (fileType) {
        case FileTypeEnum.LOGO:
          if (file?.size > FileService.logoFileSizeLimit) {
            errMsg = 'Max file size for upload 2MB';
          } else if (!allowImgTypes.includes(ext)) {
            errMsg = 'File type not supported';
          } else {
            validType = true;
          }
          break;
        case FileTypeEnum.ARTIFACTS:
        case FileTypeEnum.MANUAL_ARTIFACT:
        case FileTypeEnum.VENDOR_ARTIFACTS_TEMPLATE:
          if (file?.size > FileService.fileSize) {
            errMsg = 'Max file size for upload 30MB';
          } else if (!allowArtifactsTypes.includes(ext) && !allowImgTypes.includes(ext)) {
            errMsg = 'File type not supported';
          } else {
            validType = true;
          }
          break;
        default:
          errMsg = 'File type not supported';
      }
      if (validType && !errMsg) {
        res(true);
      } else {
        rej(errMsg);
      }
    });
  }

  async convertToS3Input(e: Event, entityId): Promise<any> {
    return new Promise((res, rej) => {
      try {
        const eventTarget: HTMLInputElement = e?.target as HTMLInputElement;
        const imageFile: any = e && eventTarget?.files[0] ? eventTarget?.files[0] : null;

        const s3File = {
          name: imageFile?.name,
          entityId,
          fileType: FileTypeEnum?.LOGO,
          contentType: imageFile?.type,
          body: imageFile,
        };

        res(s3File);
      } catch (error) {
        this.logger.error('Image Handler  Error =', error);
        rej('An occurred while uploading logo');
      }
    });
  }

  async getFileUrlFromBucket(path, options): Promise<any> {
    const key = path.includes('public/') ? path.replace('public/', '') : path;
    return await Storage.get(key, options);
  }

  convertCSVDataToJSON(csvDataAsText): IstandardsNIndustries[] {
    const arr = csvDataAsText.split('\n');
    let headers;
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      if (i === 0) {
        headers = arr[i].split(',');
      } else {
        const rowData = arr[i].split(',');
        const object = {};
        headers.map((header, indx) => {
          const key = header.toLowerCase().trim().replace(/\s/g, '_'); // key => Standards Name => standards_name
          object[key] = rowData[indx];
        });
        result.push(object);
      }
    }
    // Sort by Industry column
    if (result.length > 0) {
      result = ObjectsSort(result, 'industry');
    }
    return result;
  }

  async checkIfPathExistsS3(path: string): Promise<any> {
    const res: any = await Storage.list(path);
    if (res?.results?.length) {
      return res.results;
    }
    return res;
  }

  // <============== B & B Feature ==============>

  async createBnbReport(parentId: string, reportLevel): Promise<string> {
    const json = {
      args: {
        parentId,
        reportLevel,
      },
    };
    const credentials = await Auth.currentCredentials();
    const lambda = new Lambda({
      credentials: Auth.essentialCredentials(credentials),
      region: environment.region,
    });
    const params = {
      FunctionName: `bnbGenerateReport-${environment.name}`,
      Payload: JSON.stringify({ ...json }),
    };
    try {
      const { Payload: result } = await lambda.invoke(params).promise();
      const response = JSON.parse(result.toString());
      if (response.status === 200) {
        this.toastr.success('Report generated successfully!');
        const s3File = await this.getFileUrlFromBucket(response.downloadUrl, {
          contentType: 'text/plain',
          level: 'public',
        });
        if (s3File) {
          this.toastr.success('Report downloaded successfully!');
          return s3File;
        }
      } else {
        this.toastr.error('Report creation failed!');
        console.log('Report creation failed, serverside error ' + JSON.stringify(response.error));
        return '';
      }
    } catch (error) {
      this.toastr.error('Issue while generating data');
      console.log('Error while Generating Entity Data frontend ' + JSON.stringify(error));
    }
  }

  // Download BnB report
  async downloadBnbReport(reportId): Promise<string | null> {
    if (reportId) {
      const filePath = `${FileTypeEnum.BnB_REPORTS}/${reportId}.pdf`;
      return await this.downloadFileFromS3(filePath);
    } else {
      return null;
    }
  }

  async getFrameworkRangeMapWithId(id: string): Promise<any[]> {
    try {
      const responseData = await this.getFileByPathFromS3('FRAMEWORK_RANGES/' + id + '.json');
      return responseData?.FrameworkRangeMap ? responseData.FrameworkRangeMap : [];
    } catch (e) {
      console.log('Error : ', e);
    }
    return [];
  }

  async getZipFileFromS3(filePath, cached = false) {
    try {
      const s3File = await this.getFileUrlFromBucket(filePath, {
        contentType: 'application/zip',
        level: 'public',
        download: cached,
      });
      const zip = new JSZip();
      const originalFile = await zip.loadAsync(s3File.Body);
      const fileName = Object.keys(originalFile.files)[0];
      const blob = await originalFile.files[fileName].async('blob');
      const textContent = await blob.text();
      return JSON.parse(textContent);
    } catch (err) {
      console.log('getZipFileFromS3 err - ', err);
    }
  }

  async getFileByPathFromS3(filePath: string): Promise<any> {
    try {
      const options: any = {
        download: true,
        Recursive: true,
        contentType: 'application/json',
        level: 'public',
        cacheControl: 'no-cache',
      };
      const s3Data: any = await Storage.get(filePath, options);
      const response = await new Response(s3Data.Body).json();
      return response;
    } catch (err) {
      return null;
    }
  }

  /**
   * This function will be triggered if the user uploads a template for Artifacts
   * @param {any} eventFile this list will contain the file to upload
   */
  async uploadZippedFiles(eventFile: any, fileType: any, fileObj: any = null): Promise<any> {
    try {
      const fileEvent = fileObj?.templateURL ? fileObj.templateURL : eventFile;
      await this.verifyUploadFile(fileEvent, fileType);

      if (eventFile) {
        const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
        const zipped = new JSZip().file(fileEvent.name, fileEvent);
        const zip = (await zipped.generateAsync({ type: 'blob' })) as any;
        const s3Object: any = {
          name: fileEvent.name,
          entityId,
          fileType,
          contentType: zip.type,
          body: zip,
          date: Date.now(),
        };
        const uploadedObject = await this.uploadToS3(s3Object);
        return uploadedObject;
      }
    } catch (e) {
      console.log('Cannot upload the file ------', e);
      Promise.reject();
    }
  }

  /**
   * Function will invoke the lambda for the creation of the custom framework.
   * @param payload is the data to be passed to the lambda.
   */
  async creatCustomFramework(payload): Promise<void> {
    try {
      const credentials = await Auth.currentCredentials();
      // add region and credientials for access permission to execute lambda
      const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials),
        region: environment.region,
      });
      // Information which will be send as parameters like event.json in lambda
      const params = {
        FunctionName: `createVendorEntity-${environment.name}`,
        Payload: JSON.stringify(payload),
        InvocationType: 'Event', // InvocationType -> event means it will run as background Job like EventBridge
      };
      // Invoking lambda
      await lambda.invoke(params).promise();
    } catch (e) {
      console.log(' Error : ', e);
    }
  }

  async getJSONFileFromS3(filePath: string, options: any): Promise<any> {
    if (!!this.encryptedFolders.find(fName => filePath.startsWith(fName)) && !filePath.includes('.zip')) {
      const jsonText = await this.getDecryptedFile(filePath);
      return jsonText ? JSON.parse(jsonText) : null;
    } else {
      const s3Data: any = await Storage.get(filePath, options);
      return await new Response(s3Data.Body).json();
    }
  }

  async getTextFileFromS3(filePath: string, options: any): Promise<string> {
    if (!!this.encryptedFolders.find(fName => filePath.startsWith(fName)) && !filePath.includes('.zip')) {
      const jsonText = await this.getDecryptedFile(filePath);
      return jsonText;
    } else {
      const s3File: any = await Storage.get(filePath, options);
      return await new Response(s3File.Body).text();
    }
  }

  async getDecryptedFile(path: string, entityId: string = null): Promise<any> {
    path = path.includes('public') ? path : 'public/' + path;

    if (!entityId) {
      // get from url
      entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    }

    if (!entityId) {
      console.log('entityId is required to get file: ', path);
      return;
    }

    const response: any = await this.clientLambdaService.invokeLambda('securityHandler', {
      action: 'read_encrypted_file',
      params: {
        rootId: entityId,
        path,
      },
    });

    const compressed64 = JSON.parse(response.Payload);

    const compressedBffr = Buffer.from(compressed64.data);
    const blob1 = new Blob([compressedBffr]);

    const zip = new JSZip();
    const originalFile = await zip.loadAsync(blob1);
    const fileName = Object.keys(originalFile.files)[0];
    const blob = await originalFile.files[fileName].async('blob');
    const textContent = await blob.text();
    return textContent;
  }

  async scanPdfFile(file): Promise<boolean> {
    let isMalicious = false;
    try {
      pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.9.359/pdf.worker.js';
      const arrayBuffer = await this.readFileAsArrayBuffer(file);
      const typedArray = new Uint8Array(arrayBuffer);
      const pdf = await pdfjsLib.getDocument(typedArray).promise;
      const pageCount = pdf.numPages;
      for (let pageNum = 1; pageNum <= pageCount; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const textContent = await page.getTextContent();
        const pageText = textContent.items.map((item: any) => item.str).join(' ');
        // Iterate through patterns and check if any matches the content
        for (const pattern of this.maliciousPatterns) {
          if (pattern.test(pageText)) {
            isMalicious = true;
            break; // Malicious content detected
          }
        }

        if (isMalicious) {
          break;
        }
      }
      return isMalicious;
    } catch (err) {
      if (err?.message === 'No password given') {
        this.toastr.info('File is password protected so uploading it anyway.');
        return isMalicious;
      }
    }
  }

  private async readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.onerror = event => {
        reject(event);
      };
      reader.readAsArrayBuffer(file);
    });
  }
}
