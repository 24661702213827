import { SelectModule } from './../select/select.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UiToolsComponent } from './ui-tools.component';
import { AddClientModalComponent } from './add-client-modal/add-client-modal.component';
import { AddSubEntityModalComponent } from './add-sub-entity-modal/add-sub-entity-modal.component';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { ToggleButtonModule } from '../toggle-button/toggle-button.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendarModule } from 'app/shared/ngb-calendar/ngb-calendar.module';
import { AverageBoxModule } from 'app/shared/average-box/average-box.module';
import { AddNewClientModalComponent } from './add-client-modal-new/add-new-client-modal.component';
import { SelectFrameworksModule } from '../select-frameworks/select-frameworks.module';
import { UserListingModule } from '../user-listing/user-listing.module';
import { CygovButtonModule } from 'app/shared/cygov-button/cygov-button.module';
import { CygovLoaderModule } from './../cygov-loader/cygov-loader.module';
import { UserPermissionModule } from './../user-permission/user-permission.module';
import { AddEntityModalComponent } from './add-entity-modal/add-entity-modal.component';

@NgModule({
  declarations: [
    UiToolsComponent,
    AddClientModalComponent,
    AddSubEntityModalComponent,
    AddNewClientModalComponent,
    AddEntityModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SvgIconModule,
    NgbTooltipModule,
    ToggleButtonModule,
    CheckboxModule,
    NgSelectModule,
    SelectModule,
    NgbModule,
    NgbCalendarModule,
    AverageBoxModule,
    SelectFrameworksModule,
    UserListingModule,
    CygovButtonModule,
    CygovLoaderModule,
    UserPermissionModule,
  ],
  exports: [UiToolsComponent],
  entryComponents: [
    AddClientModalComponent,
    AddSubEntityModalComponent,
    AddNewClientModalComponent,
    AddEntityModalComponent,
  ],
})
export class UiToolsModule {}
