import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FrameworkTreeService } from 'app/shared/framework-tree.service';
import { v4 as uuid } from 'uuid';
import {
  APIService,
  UpdateRiskInput,
  CreateRiskInput,
  DeleteRiskInput,
  RiskPriorityEnum,
  RiskQuestionScoreInput,
  RoleEnum,
  RiskStatusEnum,
  CreateAnswerInput,
  RiskAction,
  GetEntityQuery,
  RiskConfidenceEnum,
  CreateCustomTaskInput,
  RiskTaskObjectInput,
  ModelRiskFilterInput,
  TaskStatusEnum,
  TagsByEntityIdQuery,
  CreateRiskOwnerAssignmentInput,
  CreateCommentInput,
  GetCommentQuery,
  UpdateCommentInput,
  DeleteCommentInput,
  CommentsByRiskIdQuery,
  CreateLogsInput,
  LogsByTargetIdQuery,
  DeleteRiskOwnerAssignmentInput,
  CreateSavedFilterInput,
  UpdateSavedFilterInput,
  CreateRiskLineChartMutation,
  RiskImpactEnum,
} from 'app/API.service';
import { UtilsService } from 'app/shared/utils.service';
import { RiskTypeEnum, StandardType } from 'app/API.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EntityService } from 'app/shared/entity.service';
import { IRiskTypes } from 'app/shared/types&Interfaces';
import { RiskTableConstant } from './risk-register.constant';
import { ImpactEnum, ProbabilityEnum, RiskColumnMappersEnum, StatusEnum } from 'app/shared/enums/risk-map.enums';
import { CUSTOMAPIService } from 'app/custom-api.service';
import { getLogMessage } from 'app/shared/helpers/logs.helper';
import { LogsKeyEnum, LogsTypeEnum } from 'app/shared/enums/logsKey.enum';

type Controls = {
  controls: Array<any>;
};
@Injectable()
export class RiskRegisterService {
  dsc: boolean = false;
  isArchiveMode: boolean = false;
  allRisks = [];
  allControlsList = {};
  controlsList = {};
  rawControlsList: Controls;
  editModeEnabled: Subject<boolean> = new Subject<boolean>();
  riskDetailCloser: Subject<boolean> = new Subject<boolean>();
  isRiskDetailOpen: Subject<boolean> = new Subject<boolean>();
  riskDetailOpener: Subject<any> = new Subject<any>();
  showAllRisks: Subject<any> = new Subject<any>();
  saveCurrentRiskData: Subject<boolean> = new Subject<boolean>();
  updateAllRiskList: Subject<any> = new Subject<any>();
  updateTagsList: Subject<any> = new Subject<any>();
  updateCachedRiskProp: Subject<any> = new Subject<any>();
  triggerBulkPop: Subject<boolean> = new Subject<boolean>();
  newRiskControls = [];
  riskGraphLineData: any[] = [];
  nistRisks: any = null;
  nistMappedRC: any = null;
  allRisksMappedRC: any = {};
  rskQuesCount: any = {};
  riskMangersList: any = [];
  currentUser = null;
  defaultFilter: any = null;
  rootEntityId: string;
  subEntities: GetEntityQuery[] = [];
  subEntitiesByRootId: GetEntityQuery[] = [];
  nistSubEntities: GetEntityQuery[] = []; // sub entities which has NIST FRAMEWORK
  hiddenRisks: any[] = [];
  domain = '';
  cachedTagsList: any[] = [];
  categorizedTags = {};
  automatedRisksFrameworks = {
    NIST_CSF: 'NIST_CSF',
    CSF_SC: 'CSF_SC',
  };
  controlsAgainstSubEntity: any;
  previousCircle: any;
  private previousCircleSubject = new BehaviorSubject<any>({});
  previousCircleSubject$ = this.previousCircleSubject.asObservable();

  constructor(
    private toastr: ToastrService,
    private frameworkTreeService: FrameworkTreeService,
    private apiService: APIService,
    private customApi: CUSTOMAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private entityService: EntityService
  ) {
    this.domain = window.location.hostname;
  }

  initializeRiskRegisterService(): void {
    // this.nistRisks = await this.frameworkTreeService.getRiskControlsFromS3('risks-list');
    // this.nistMappedRC = await this.frameworkTreeService.getRiskControlsFromS3('mapped-risks');

    // getting each risks total Questions count.
    this.rskQuesCount = {};

    if (this.nistMappedRC?.mappedRisks?.length) {
      this.nistMappedRC.mappedRisks.forEach(mr => {
        const allConnectedRisks = mr.riskID ? mr.riskID.split(',') : [];
        allConnectedRisks.forEach(gr => {
          const riskName = gr.trim();
          if (this.rskQuesCount[riskName] && this.rskQuesCount[riskName] > 0) {
            this.rskQuesCount[riskName]++;
          } else {
            this.rskQuesCount[riskName] = 1;
          }
        });
      });
    }
  }

  addPreviousCircle(circle: any) {
    this.previousCircle = circle;
    this.previousCircleSubject.next(circle);
  }

  getPreviousCircle() {
    return this.previousCircle;
  }

  set initializeRootEntityId(id: string) {
    this.rootEntityId = id;
  }

  getRiskTotalQuestionCount(riskName: string): number {
    if (this.rskQuesCount && this.rskQuesCount[riskName]) {
      return this.rskQuesCount[riskName];
    } else {
      return null;
    }
  }

  isRiskFrameworkType(frameworkName: string): boolean {
    const frameworkWithRisksFiles = [
      'NIST_CSF',
      'NIST_CSF_BB',
      'NIST_OU',
      'ITC_CUSTOM',
      'PARAGON_FULL',
      'PARAGON_BASIC',
      'BEAZLEY',
      'CSF_SC',
      'NIST_800_171_RISK',
      'BEAZLEY_OT',
      'B_APP_RSWOT',
      'MITRE_ATTACK',
      'CYBERARK_CSA',
      'CYBERARK_NIST_CSF_CRITICAL',
    ];
    return frameworkWithRisksFiles.includes(frameworkName);
  }

  async getStaticRisks(frameworkName: string): Promise<any> {
    if (this.nistRisks) {
      return this.nistRisks;
    } else if (this.isRiskFrameworkType(frameworkName)) {
      this.nistRisks = await this.frameworkTreeService.getRiskControlsFromS3('risks-list', frameworkName);
      return this.nistRisks;
    }
  }

  async getMappedRisks(frameworkName: string): Promise<any> {
    if (this.nistMappedRC) {
      return this.nistMappedRC;
    } else if (this.isRiskFrameworkType(frameworkName)) {
      this.nistMappedRC = await this.frameworkTreeService.getRiskControlsFromS3('mapped-risks', frameworkName);
      this.initializeRiskRegisterService();
      return this.nistMappedRC;
    }
  }

  async getRiskLists(listName: string, frameworkName: string): Promise<any> {
    const riskList = await this.frameworkTreeService.getRiskControlsFromS3(listName, frameworkName);
    return riskList;
  }

  async getAllMappedRisks(assessmentId: string): Promise<any> {
    const entity = this.subEntitiesByRootId.find(ent => ent.activeAssessmentId === assessmentId);
    if (entity) {
      const framework = entity?.activeAssessment?.standardFrameworkList?.items?.find(
        fw => fw.type === StandardType.RISK_FRAMEWORK && !fw.not_added && !fw.archived
      );
      if (this.allRisksMappedRC && this.allRisksMappedRC[framework.key]) {
        return this.allRisksMappedRC[framework.key];
      } else {
        this.allRisksMappedRC[framework.key] = {};
        this.allRisksMappedRC[framework.key] = await this.frameworkTreeService.getRiskControlsFromS3(
          'mapped-risks',
          framework.key
        );
        return this.allRisksMappedRC[framework.key];
      }
    }
  }

  questionIncludedInRisk(question: any, risk: string, assessmentId: string): boolean {
    const key = question?.todo?.toLowerCase() + question?.name?.toLowerCase();
    const entity = this.subEntitiesByRootId.find(ent => ent.activeAssessmentId === assessmentId);
    if (entity) {
      const framework = entity?.activeAssessment?.standardFrameworkList?.items?.find(
        fw => fw.type === StandardType.RISK_FRAMEWORK && !fw.not_added && !fw.archived
      );
      const obj = this.allRisksMappedRC[framework.key].mappedRisks.find(
        mr => key === (mr.questionTodo + mr.questionTitle)?.toLowerCase()
      );
      if (obj && obj?.riskID?.includes(risk)) {
        return true;
      }
    }
    return false;
  }

  enablingEditMode(value: boolean): void {
    this.editModeEnabled.next(value);
  }

  isRiskDetailsOpen(value: boolean): void {
    this.isRiskDetailOpen.next(value);
  }

  saveRiskDataTrigger(): void {
    this.saveCurrentRiskData.next(true);
  }

  openRiskDetails(risk): void {
    this.riskDetailOpener.next(risk);
  }

  closeRiskDetails(value: boolean): void {
    this.riskDetailCloser.next(value);
  }

  isShowAllRisks(risk): void {
    this.showAllRisks.next(risk);
  }

  async createNewRisk(risk: CreateRiskInput, showToaster: boolean = false): Promise<any> {
    try {
      const dbRisk = await this.customApi.CreateRisk(risk);
      if (showToaster) {
        // intentionally add this text. so client don't confuse with risk update or create.
        // because for normal case we show risk updated text, so for creating we also use same text.
        this.toastr.success('Risk Updated!');
      }
      return dbRisk;
    } catch (error) {
      this.toastr.error('Cannot create risk');
      console.log('Create New Risk Error -->', error);
    }
  }

  async deductAICredit(entityId: string, aIData: any): Promise<void> {
    try {
      delete aIData?.__typename;
      aIData.usedCredits = aIData.usedCredits + 1;
      await this.customApi.UpdateEntity({ id: entityId, AI: aIData });
    } catch (error) {
      console.log('Deduct AI Credit Error -->', error);
    }
  }

  async createDefaultFilter(filter: CreateSavedFilterInput) {
    try {
      const savedFilter = await this.customApi.CreateSavedFilter(filter);
      return savedFilter;
    } catch (error) {
      console.log('Can not get Saved Filters -->', error);
    }
  }

  async getDefaultFilter(entityId: any, userId: any) {
    try {
      const savedFilter = await this.customApi.SavedFiltersByEntityIdAndUserId(entityId, userId);
      return savedFilter;
    } catch (error) {
      console.log('Can not get Saved Filters -->', error);
    }
  }

  async updateDefaultFilter(filter: UpdateSavedFilterInput) {
    try {
      const savedFilter = await this.customApi.UpdateSavedFilter(filter, { entityId: { eq: filter.entityId } });
      return savedFilter;
    } catch (error) {
      console.log('Can not update Saved Filters -->', error);
    }
  }

  async assignRiskOwner(
    riskOwnerAssignment: CreateRiskOwnerAssignmentInput,
    showToaster: boolean = false
  ): Promise<any> {
    try {
      const dbRiskAssignment = await this.customApi.CreateRiskOwnerAssignment(riskOwnerAssignment);
      if (showToaster) {
        // intentionally add this text. so client don't confuse with risk update or create.
        // because for normal case we show risk updated text, so for creating we also use same text.
        this.toastr.success('Risk Owner Assigned Successfully!');
      }
      return dbRiskAssignment;
    } catch (error) {
      this.toastr.error('Cannot create risk owner assignment');
      console.log('Create New Risk Owner Assignment Error -->', error);
    }
  }

  async deleteRiskOwner(
    deleteAssignment: DeleteRiskOwnerAssignmentInput,
    subEntityId: string,
    showToaster: boolean = false
  ): Promise<any> {
    try {
      const dbRiskAssignment = await this.customApi.DeleteRiskOwnerAssignment(deleteAssignment, {
        subEntityId: { eq: subEntityId },
      });
      if (showToaster) {
        // intentionally add this text. so client don't confuse with risk update or create.
        // because for normal case we show risk updated text, so for creating we also use same text.
        this.toastr.success('Risk Owner Deleted Successfully!');
      }
      return dbRiskAssignment;
    } catch (error) {
      this.toastr.error('Cannot Delete owner assignment');
      console.log('Delete Risk Owner Assignment Error -->', error);
    }
  }

  async getRisksByUserId(UserId: string): Promise<any> {
    try {
      const riskByOwners = await this.customApi.RiskAssignmentByUserId(UserId);
      return riskByOwners;
    } catch (error) {
      this.toastr.error('Cannot get risk related to risk Owners');
      console.log('Cannot get risk related to risk Owners Error -->', error);
    }
  }

  async saveFinancialImpact(riskId, content, showToast = true): Promise<void> {
    const index = this.allRisks.findIndex(rsk => rsk.id === riskId);
    content.contentsData.forEach(item => {
      delete item.infoOpen;
    });
    if (index > -1) {
      this.allRisks[index].primaryLoss = content.primaryLoss;
      this.allRisks[index].financialImpact = content.contentsData;
      this.allRisks[index].financialToggle = content.financialToggle;
      await this.updateRisk(this.allRisks[index], showToast, false);
      const updateLocal = {
        riskId,
        content,
        typeFinancial: true,
      };
      this.updateCachedRiskProp.next(updateLocal);
    }
  }
  async updateRiskDeadline(riskId: string, deadline: number, assessmentId: string): Promise<void> {
    try {
      await this.customApi.UpdateRisk(
        { id: riskId, deadline, updatedAt: Date.now() },
        {
          assessmentId: { eq: assessmentId },
        }
      );
    } catch (e) {
      console.log('err:  ', e);
    }
  }

  async getGroupsList(entityId: string): Promise<any> {
    try {
      const groups = await this.customApi.GroupsByRootId(entityId);
      return groups?.items?.length ? groups.items : [];
    } catch (e) {
      console.log('Cannot get the groups ', e);
      return [];
    }
  }

  async updateRisk(risk, showToast = true, triggerUpdate = true): Promise<void> {
    // removing __typename property from Score History of risk
    if (risk.scoreHistory && risk.scoreHistory.length) {
      risk.scoreHistory.forEach(scoreObj => delete scoreObj.__typename);
    }

    // removing __typename property from financialImpact of risk
    if (risk && risk.financialImpact && risk.financialImpact.length) {
      risk.financialImpact.forEach(fi => {
        if (fi.__typename) {
          delete fi.__typename;
        }
      });
    }

    // removing __typename property from Manual Risk Control Names
    if (risk && risk.riskControlNames && risk.riskControlNames.length) {
      risk.riskControlNames.forEach(name => delete name.__typename);
    }
    // removing __typename property from risk task list
    const onlyManualTasks = [];
    if (risk && risk.riskTasks && risk.riskTasks.length) {
      risk.riskTasks.forEach(tsk => {
        delete tsk.__typename;
        if (!tsk.autoTasks) {
          delete tsk.autoTasks;
          onlyManualTasks.push(tsk);
        }
        delete tsk.autoTasks;
      });
    }
    const localRisk: UpdateRiskInput = {
      id: risk.id,
      idTitle: risk.idTitle,
      riskTitle: risk.riskTitle,
      riskDescription: risk.riskDescription,
      relatedAssets: risk.relatedAssets,
      assessmentId: risk.assessmentId,
      riskImpact: risk.riskImpact.toUpperCase(),
      isEscalated: risk.isEscalated,
      riskProbability: risk.riskProbability.toUpperCase(),
      residualImpact: risk.residualImpact,
      residualProbability: risk.residualProbability,
      inherentRisk: risk.inherentRisk,
      residualRisk: risk.residualRisk,
      riskStatus: risk.riskStatus,
      riskStatusUpdatedBy: risk.riskStatusUpdatedBy,
      notes: risk.notes,
      costToRemediate: risk.costToRemediate,
      costToRemediateType: risk.costToRemediateType,
      primaryLoss: risk.primaryLoss,
      financialImpact: risk.financialImpact,
      riskPriority: risk.riskPriority,
      disable: risk.disable,
      hidden: risk.hidden ? risk.hidden : false,
      financialToggle: risk.financialToggle,
      controlIds: risk.controlIds && risk.controlIds.length ? risk.controlIds : [],
      scoreHistory: risk.scoreHistory && risk.scoreHistory.length ? risk.scoreHistory : [],
      riskTags: risk.riskTags && risk.riskTags.length ? risk.riskTags : null,
      riskTasks: onlyManualTasks && onlyManualTasks.length ? onlyManualTasks : null,
      riskControlNames: risk.riskControlNames && risk.riskControlNames.length ? risk.riskControlNames : null,
      riskTasksIds: risk.riskTasksIds && risk.riskTasksIds.length ? risk.riskTasksIds : null,
      sensitivity: risk.sensitivity,
      effectiveness: risk.effectiveness,
      riskOwnerIds: risk.riskOwnerIds,
      updatedAt: Date.now(),
    };
    const inherent = (
      (UtilsService.getLabelValueNum(localRisk.riskImpact) + UtilsService.getLabelValueNum(localRisk.riskProbability)) /
      2
    ).toFixed(1);
    localRisk.inherentRisk = parseFloat(inherent);
    try {
      await this.customApi.UpdateRisk(localRisk, {
        assessmentId: { eq: localRisk?.assessmentId },
      });
      if (showToast) {
        this.toastr.success('Risk Updated!');
      }
      if (triggerUpdate) {
        this.updateAllRiskList.next({ type: 'updated', risk });
      }
    } catch (e) {
      console.log('Error ---> ', e);
      if (e?.status === 400) {
        this.toastr.error('Risk not found!!!');
      } else {
        this.toastr.error('Could not update the risk', e);
      }
    }
  }

  async deleteRisk(risk): Promise<void> {
    try {
      const deleteObj: DeleteRiskInput = {
        id: risk.id,
      };
      await this.customApi.DeleteRisk(deleteObj, { assessmentId: { eq: risk?.assessmentId } });
      this.allRisks = this.allRisks.filter(cr => cr.id !== risk.id);
      this.hiddenRisks = this.hiddenRisks.filter(cr => cr.id !== risk.id);
    } catch (e) {
      console.log(e);
      this.toastr.error('Could not delete risk');
    }
  }

  async updateArchiveRiskProperty(risk, isArchived = false): Promise<void> {
    try {
      await this.customApi.UpdateRisk(
        {
          id: risk.id,
          isArchived,
          updatedAt: Date.now(),
        },
        {
          assessmentId: { eq: risk?.assessmentId },
        }
      );
    } catch (e) {
      console.log(e);
      this.toastr.error(`Could not ${isArchived ? 'archive' : 're-activate'} risk}`);
    }
  }

  updateRiskTagsList(tags) {
    this.updateTagsList.next(tags);
  }

  async escalateRisk(risk): Promise<boolean> {
    try {
      await this.customApi.UpdateRisk(
        {
          id: risk.id,
          isEscalated: true,
          riskImpact: risk.riskImpact.toUpperCase(),
          riskProbability: risk.riskProbability.toUpperCase(),
          riskStatus: RiskStatusEnum.ESCALATED,
          updatedAt: Date.now(),
        },
        {
          assessmentId: { eq: risk?.assessmentId },
        }
      );
      this.toastr.success('Risk Escalated Successfully!');
      return true;
    } catch (e) {
      console.log(e);
      this.toastr.error('Could not escalate risk');
      return false;
    }
  }

  async deEscalateRisk(risk): Promise<void> {
    try {
      await this.customApi.UpdateRisk(
        {
          id: risk.id,
          isEscalated: false,
          riskStatus: RiskStatusEnum.OPEN,
          updatedAt: Date.now(),
        },
        {
          assessmentId: { eq: risk?.assessmentId },
        }
      );
    } catch (e) {
      console.log(e);
      this.toastr.error('Could not de-escalate risk');
    }
  }

  async disableRisk(risk, disable = false): Promise<void> {
    try {
      const selectedRisk = this.allRisks.find(rs => rs.id === risk.id);
      selectedRisk.disable = !disable;
      await this.updateRisk(selectedRisk, true);
      this.enablingEditMode(false);
      if (!disable) {
        this.toastr.success('Risk is Disabled!');
      } else {
        this.toastr.success('Risk is Enabled!');
      }
    } catch (e) {
      if (!disable) {
        this.toastr.error('Could not disable the risk');
      } else {
        this.toastr.error('Could not enable the risk');
      }
    }
  }

  /**
   *
   */
  /**
   * gets current assigned risk framework
   * @returns riskFrameWorks
   * @param assessmentId
   */

  async getCurrentRiskFrameWork(assessmentId: string): Promise<any> {
    const filter = {
      type: {
        eq: StandardType.RISK_FRAMEWORK,
      },
    };
    const riskFrameWorks = await this.customApi.StandardFrameworksByAssessmentId(assessmentId, null, filter);
    const filteredRisks = riskFrameWorks?.items ? riskFrameWorks?.items?.filter(fw => !fw.archived) : [];
    return filteredRisks?.length ? filteredRisks[0] : {};
  }

  getQuestionsByControlId(id: string): any {
    const control = this.controlsList[id];
    if (control && control.questions) {
      return control.questions;
    } else {
      return {};
    }
  }

  getControlNameById(id: string): string {
    const control = this.controlsList[id];
    if (control && control.name) {
      return control.name + ' : ' + control.label + '    ';
    } else {
      return '';
    }
  }

  getControlName(id: string): any {
    const control = this.controlsList[id];
    if (control && control.name) {
      return {
        id: control.name,
        name: control.label,
      };
    } else {
      return {};
    }
  }

  async getTagsListFromDb(): Promise<any[]> {
    try {
      const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
      if (!entityId) {
        return [];
      }
      const limit = 300;
      let nextToken;
      let totalTags = [];
      do {
        const { items: tags, nextToken: rnextToken }: TagsByEntityIdQuery = await this.customApi.TagsByEntityId(
          entityId,
          null,
          null,
          limit,
          nextToken
        );
        nextToken = rnextToken;
        totalTags = totalTags.concat(tags);
      } while (nextToken);
      this.createTagsCategories(totalTags);
      this.cachedTagsList = totalTags.length ? totalTags : [];
      return this.cachedTagsList;
    } catch (e) {
      console.log('Error - getTagsListFromDb: ', e);
    }
  }
  createTagsCategories(tagsList) {
    tagsList.map(tag => {
      if (tag.referenceId === '_Global') {
        if (!this.categorizedTags[tag.name]) {
          this.categorizedTags[tag.name] = tag;
        }
      } else {
        if (!this.categorizedTags[`${tag.referenceId}#${tag.name}`]) {
          this.categorizedTags[`${tag.referenceId}#${tag.name}`] = tag;
        }
      }
    });
  }

  // Creating tags in risk-register service as we don't have any component and service of tags.
  async addNewTagsinDb(
    newTagsList: any,
    targetId?: string,
    assessmentFrameworkKey?: string,
    referenceId?: string
  ): Promise<any> {
    const entityId = UtilsService.getRouteParam(this.route.root.snapshot, 'entityId');
    if (entityId) {
      const promise = [];
      const newlyCreatedTags = [];
      newTagsList = UtilsService.isDefined(newTagsList) ? newTagsList : [];
      if (newTagsList?.length && !UtilsService.isEmpty(targetId)) {
        newTagsList.forEach(tag => {
          const tagObj = {
            name: tag && tag?.title ? tag.title.toLowerCase() : tag.toLowerCase(),
            entityId,
            domain: this.domain.toLowerCase(),
            targetId,
            assessmentFrameworkKey,
            referenceId,
            questionRefId: targetId,
          };

          const isExist = this.categorizedTags[`${tag.referenceId}#${tagObj.name}`] ? true : false;
          if (!isExist) {
            newlyCreatedTags.push(tagObj);
            this.categorizedTags[`${tag.referenceId}#${tagObj.name}`] = tagObj;
            promise.push(this.customApi.CreateTags(tagObj));
          }
        });
      } else {
        newTagsList.forEach(tag => {
          const tagObj = {
            name: tag && tag?.title ? tag.title.toLowerCase() : tag.toLowerCase(),
            entityId,
            domain: this.domain.toLowerCase(),
            targetId: '_Global',
            assessmentFrameworkKey: '_Global',
            referenceId: '_Global',
          };
          const isExist = this.categorizedTags[`${tagObj.name}`] ? true : false;
          if (!isExist) {
            newlyCreatedTags.push(tagObj);
            this.categorizedTags[`${tagObj.name}`] = tagObj;
            promise.push(this.customApi.CreateTags(tagObj));
          }
        });
      }
      this.cachedTagsList = [...this.cachedTagsList, ...newlyCreatedTags];

      return await Promise.all(promise);
    }
  }

  // delete tag by id

  async deleteTagFromQuestion(id): Promise<void> {
    try {
      await this.customApi.DeleteTags({ id }, { entityId: { eq: this.rootEntityId } });
    } catch (e) {
      console.log('Error - deleteTagFromQuestion: ', e);
    }
  }

  // update tagList when cache list is updated.
  updateTagOptionList(cachedRiskList: any, tagOptionList: any): any {
    cachedRiskList?.forEach(tagObj => {
      tagObj?.riskTags?.forEach(tag => {
        const idx = tagOptionList.findIndex(tgObj => {
          return tgObj?.title && tag && tgObj.title.toLowerCase() === tag.toLowerCase();
        });
        if (idx < 0) {
          tagOptionList.push({ title: tag?.toLowerCase(), selected: false });
        }
      });
    });
    this.addNewTagsinDb(tagOptionList);
    return tagOptionList;
  }
  async getControlsListByAssessmentId(assessmentId: string, populateAllControlsList: boolean = false): Promise<void> {
    try {
      const riskFrameWork = await this.getCurrentRiskFrameWork(assessmentId);
      // commenting this code as now we need to get controls of every risk framework to display in adding new risks
      // } else {
      //   frameName = riskFrameWorks?.items[0]?.key;
      //   // frameName = FrameworkTreeService.getFrameworkKey();
      // }

      this.rawControlsList = await this.frameworkTreeService.getControlsFromAssessment(
        assessmentId,
        riskFrameWork // framework
      );
      // adding description of questions against their controls
      let controlName = '';
      let index = 0;
      // remove below commented code in future
      // this.rawControlsList = await this.frameworkTreeService.getAssessmentControlsFromS3(assessmentId);
      if (this.rawControlsList && this.rawControlsList?.controls) {
        this.rawControlsList?.controls?.forEach(control => {
          if (control.name.toLowerCase() === controlName.toLowerCase()) {
            index = index + 1;
          } else {
            index = 0;
            controlName = control.name;
          }

          this.newRiskControls.push({
            id: control.id,
            name: control.name,
            label: control.label,
            value: false,
            description: control?.questions && control.questions[index] && control.questions[index]?.description,
          });
          if (populateAllControlsList) {
            if (
              UtilsService.isDefined(this.allControlsList) &&
              UtilsService.isDefined(this.allControlsList[assessmentId])
            ) {
              this.allControlsList[assessmentId][control.id] = control;
            } else {
              this.allControlsList[assessmentId] = {};
              this.allControlsList[assessmentId][control.id] = control;
            }
          } else if (this.controlsList) {
            this.controlsList[control.id] = control;
          }
        });

        // creating a mapper for controls of risk framework so that on selecting different sub-entities
        // time complexity remains constant
        if (!this.controlsAgainstSubEntity) {
          this.controlsAgainstSubEntity = {};
          if (!this.controlsAgainstSubEntity[assessmentId]) {
            this.controlsAgainstSubEntity[assessmentId] = {};
            this.controlsAgainstSubEntity[assessmentId] = this.newRiskControls;
          }
        } else {
          if (!this.controlsAgainstSubEntity[assessmentId]) {
            this.controlsAgainstSubEntity[assessmentId] = {};
            this.controlsAgainstSubEntity[assessmentId] = this.newRiskControls;
          }
        }
        this.newRiskControls = [];
      }
    } catch (err) {
      this.toastr.error('Error - getControlsListByAssessmentId: ', err);
    }
  }

  async getControlsList(entity: any, populateAllControlsList: boolean = false): Promise<void> {
    try {
      const riskFrameWork = entity?.activeAssessment?.standardFrameworkList?.items?.find(
        item => item.type === StandardType.RISK_FRAMEWORK && !item.archived
      );
      const assessmentId = entity.activeAssessmentId;
      // commenting this code as now we need to get controls of every risk framework to display in adding new risks
      // } else {
      //   frameName = riskFrameWorks?.items[0]?.key;
      //   // frameName = FrameworkTreeService.getFrameworkKey();
      // }

      this.rawControlsList = await this.frameworkTreeService.getControlsFromAssessment(
        assessmentId,
        riskFrameWork // framework
      );
      // adding description of questions against their controls
      let controlName = '';
      let index = 0;
      // remove below commented code in future
      // this.rawControlsList = await this.frameworkTreeService.getAssessmentControlsFromS3(assessmentId);
      if (this.rawControlsList && this.rawControlsList?.controls) {
        this.rawControlsList?.controls?.forEach(control => {
          if (control.name.toLowerCase() === controlName.toLowerCase()) {
            index = index + 1;
          } else {
            index = 0;
            controlName = control.name;
          }

          this.newRiskControls.push({
            id: control.id,
            name: control.name,
            label: control.label,
            value: false,
            description: control?.questions && control.questions[index] && control.questions[index]?.description,
          });
          if (populateAllControlsList) {
            if (
              UtilsService.isDefined(this.allControlsList) &&
              UtilsService.isDefined(this.allControlsList[assessmentId])
            ) {
              this.allControlsList[assessmentId][control.id] = control;
            } else {
              this.allControlsList[assessmentId] = {};
              this.allControlsList[assessmentId][control.id] = control;
            }
          } else if (this.controlsList) {
            this.controlsList[control.id] = control;
          }
        });

        // creating a mapper for controls of risk framework so that on selecting different sub-entities
        // time complexity remains constant
        if (!this.controlsAgainstSubEntity) {
          this.controlsAgainstSubEntity = {};
          if (!this.controlsAgainstSubEntity[assessmentId]) {
            this.controlsAgainstSubEntity[assessmentId] = {};
            this.controlsAgainstSubEntity[assessmentId] = this.newRiskControls;
          }
        } else {
          if (!this.controlsAgainstSubEntity[assessmentId]) {
            this.controlsAgainstSubEntity[assessmentId] = {};
            this.controlsAgainstSubEntity[assessmentId] = this.newRiskControls;
          }
        }
        this.newRiskControls = [];
      }
    } catch (err) {
      this.toastr.error('Error - getControlsList: ', err);
    }
  }

  setControlsList(activeAssessmentId: string): void {
    try {
      this.controlsList = {};
      this.controlsList = this.allControlsList[activeAssessmentId];
    } catch (err) {
      this.toastr.error('Error - setControlsList:', err);
    }
  }

  getControlsForNewRisk(): any {
    return this.newRiskControls;
  }

  async getRisksList(assessmentId: string, isMorethanSeven = false): Promise<any> {
    let lastEvaluatedKey = null;
    const risks = [];
    do {
      const { items, nextToken } = await this.customApi.RiskByAssessmentId(
        assessmentId,
        null,
        null,
        isMorethanSeven ? 40 : null,
        lastEvaluatedKey
      );
      risks.push(...items);
      this.allRisks = [...items, ...this.allRisks];
      lastEvaluatedKey = nextToken;
    } while (lastEvaluatedKey);
    this.allRisks.forEach((risk, index) => {
      if (risk.hidden) {
        this.hiddenRisks.push(risk);
        // remove hidden value from allRisks
        this.allRisks.splice(index, 1);
      }
    });
    return risks;
  }

  async createManualCustomTask(task: CreateCustomTaskInput): Promise<CreateCustomTaskInput> {
    return await this.customApi.CreateCustomTask(task);
  }

  async updateRiskInstanceForTask(
    riskId: string,
    taskList: RiskTaskObjectInput[],
    taskIdsList: string[],
    isRemove: boolean
  ): Promise<void> {
    const index = this.allRisks.findIndex(rsk => rsk.id === riskId);
    if (index > -1) {
      if (isRemove) {
        if (taskList && taskList.length) {
          this.allRisks[index].riskTasks = JSON.parse(JSON.stringify(taskList));
          this.allRisks[index].riskTasksIds = JSON.parse(JSON.stringify(taskIdsList));
          // re-check scores
          if (this.allRisks[index].type === RiskTypeEnum.HARD_MANUAL) {
            const isMitigated = this.manualTasksStatusCheck(this.allRisks[index].riskTasks);
            this.allRisks[index].riskStatus = isMitigated ? RiskStatusEnum.MITIGATED : RiskStatusEnum.OPEN;
          }
          if (this.allRisks[index].type === RiskTypeEnum.MANUAL) {
            const rsk = this.allRisks[index];
            this.mitigateRiskBaseOnScoreHistory(rsk);
            this.allRisks[index] = JSON.parse(JSON.stringify(rsk));
          }
        } else {
          this.allRisks[index].riskTasks = null;
          this.allRisks[index].riskTasksIds = null;
        }
      } else {
        this.allRisks[index].riskTasks = JSON.parse(JSON.stringify(taskList));
        this.allRisks[index].riskTasksIds = JSON.parse(JSON.stringify(taskIdsList));
        this.allRisks[index].riskStatus = RiskStatusEnum.OPEN;
      }
      await this.updateRisk(this.allRisks[index], false, false);
      const content = {
        tasks: this.allRisks[index].riskTasks,
        tasksIds: this.allRisks[index].riskTasksIds,
      };
      const updateLocal = {
        riskId,
        content,
        typeFinancial: false,
      };
      this.updateCachedRiskProp.next(updateLocal);
    }
  }

  // ------------------------------------------------- RISK QUESTION UPDATE FUNCTIONS ------------------------------------------------------

  // ----------------------------------------- Remediation Question Updates Functions ---------------------------------------

  /**
   * @param currentUser is type of user object ( The Current User logged into the system )
   * @param questionsData is Type of object that contains keys= assessmentIds and values as list of Questions objects
   * This function Basically process the List of Questions against a assessment Id and Update the Risk against each Question
   */
  async deduceAnswerRiskRemediation(currentUser: any, questionsData: any, riskAction?: RiskAction): Promise<void> {
    // here we process the Data on
    const keys = Object.keys(questionsData);
    this.currentUser = currentUser;
    for (const key of keys) {
      if (!this.allControlsList[key]) {
        // here key is a assessment Id (key=assessmentId)
        await this.getControlsListByAssessmentId(key);
      } else {
        this.setControlsList(key);
      }
      const res = await this.customApi.RiskByAssessmentId(key);
      let dbRisks = [];
      if (res && res.items && res.items.length) {
        dbRisks = res.items;
      }
      // move the Risk->Questions list here
      const riskQuestionsData = [];
      // questionsData[key] is a list of questions against a assessmentId ( as key=assessmentId )
      for (const question of questionsData[key]) {
        const manualRisks = dbRisks.filter(risk => risk.type === RiskTypeEnum.MANUAL);
        let questionRisks = await this.getRisksOfAQuestion(question);
        if (manualRisks && manualRisks.length && this.getManualRisksOfAQuestion(manualRisks, question)) {
          questionRisks = [...questionRisks, ...this.getManualRisksOfAQuestion(manualRisks, question)];
        }
        // Process all the Risks
        const isPassed = this.getPassedValue(question);
        questionRisks.map(risk => {
          if (riskQuestionsData.length) {
            const index = riskQuestionsData.findIndex(item => {
              if (item.risk.RiskID.trim().toLowerCase() === risk.RiskID.trim().toLowerCase()) {
                return item;
              }
            });
            if (index >= 0) {
              riskQuestionsData[index].questions.push({ ...question, isPassed, action: riskAction });
            } else {
              riskQuestionsData.push({ risk, questions: [{ ...question, isPassed, action: riskAction }] });
            }
          } else {
            riskQuestionsData.push({ risk, questions: [{ ...question, isPassed, action: riskAction }] });
          }
        });
      }
      await this.addRemoveRiskRemediationQuestions(riskQuestionsData, key, dbRisks);
    }
  }

  /**
   *
   * @param riskQuestions The risk that have the attached questions
   * @param assessment assessment ID
   * @param dbRisks The existing risk in Dynamo DB
   * This functionality updates questions coming from Remediation
   */
  async addRemoveRiskRemediationQuestions(riskQuestions: any, assessment: string, dbRisks: any): Promise<void> {
    // here we Received
    let isUpdate = false;
    const updateRisks = [];
    const createRisks = [];
    // loop the RiskQuestions ... Risk 1,2,3...
    riskQuestions.map(riskQuestion => {
      let cacheRisk;
      const rsk = riskQuestion.risk;
      // loop through each question in RiskQuestion ...  Rn->Q1,Q2,Q3....
      riskQuestion.questions.map(question => {
        // if not passed then add the risk affiliated with that question
        // first find if those risks already exists
        if (cacheRisk) {
          cacheRisk.hidden = false;
          // if already controls list exist , simply add it.
          if (cacheRisk.controlIds && cacheRisk.controlIds.length) {
            // if the control ID is already in risk , then don't update. else add it.
            if (!cacheRisk.controlIds.includes(question.parentId)) {
              cacheRisk.controlIds.push(question.parentId);
            }
          } else {
            // if it is empty, just add control id
            cacheRisk.controlIds = [];
            cacheRisk.controlIds.push(question.parentId);
          }
          // adjusting score history and calculating residual risk
          cacheRisk.scoreHistory = this.updateScoreHistory(false, question, cacheRisk.scoreHistory, question.action);
          // check score history, if all scores 10 then mitigate risk
          this.mitigateRiskBaseOnScoreHistory(cacheRisk);

          const calculation = this.calculateResidual(cacheRisk.scoreHistory, cacheRisk);

          cacheRisk.effectiveness = calculation.effectiveness;
          cacheRisk.residualImpact = UtilsService.getImpactEnumValue(calculation.residualImpact);
          cacheRisk.residualProbability = UtilsService.getProbabilityEnumValue(calculation.residualProbability);
          cacheRisk.residualRisk = calculation.residualRisk;
        } else if (dbRisks && dbRisks.length) {
          if (rsk.type === RiskTypeEnum.MANUAL) {
            cacheRisk = dbRisks.find(rsk1 => {
              // finding the risk , if it doesn't exist , by default we won't add it.
              if (rsk1.id === rsk.id && rsk1.idTitle.toLowerCase() === rsk.RiskID.toLowerCase()) {
                // making sure if the control id array has something in it.
                isUpdate = true;
                return rsk1;
              }
            });
          } else {
            cacheRisk = dbRisks.find(rsk1 => {
              // finding the risk , if it doesn't exist , by default we won't add it.
              if (
                rsk1.idTitle.toLowerCase() === rsk.RiskID.toLowerCase() &&
                rsk1.riskCategory &&
                rsk.riskCategory &&
                rsk1.riskCategory.toLowerCase() === rsk.riskCategory.toLowerCase()
              ) {
                // making sure if the control id array has something in it.
                isUpdate = true;
                return rsk1;
              }
            });
          }
          if (cacheRisk) {
            cacheRisk.hidden = false;
            // if already controls list exist , simply add it.
            if (cacheRisk.controlIds && cacheRisk.controlIds.length) {
              // if the control ID is already in risk , then don't update. else add it.
              if (!cacheRisk.controlIds.includes(question.parentId)) {
                cacheRisk.controlIds.push(question.parentId);
              }
            } else {
              // if it is empty, just add control id
              cacheRisk.controlIds = [];
              cacheRisk.controlIds.push(question.parentId);
            }
            // adjusting score history and calculating residual risk
            cacheRisk.scoreHistory = this.updateScoreHistory(false, question, cacheRisk.scoreHistory, question.action);
            // check score history, if all scores 10 then mitigate risk

            this.mitigateRiskBaseOnScoreHistory(cacheRisk);

            const calculation = this.calculateResidual(cacheRisk.scoreHistory, cacheRisk);

            cacheRisk.effectiveness = calculation.effectiveness;
            cacheRisk.residualImpact = UtilsService.getImpactEnumValue(calculation.residualImpact);
            cacheRisk.residualProbability = UtilsService.getProbabilityEnumValue(calculation.residualProbability);
            cacheRisk.residualRisk = calculation.residualRisk;
          } else {
            cacheRisk = this.createNewRiskObject(rsk, question, assessment);
            isUpdate = false;
          }
        } else {
          // this will add a current Risk in the if the Risk is not in dbRisks
          cacheRisk = this.createNewRiskObject(rsk, question, assessment);
          isUpdate = false;
        }
      });

      if (isUpdate) {
        updateRisks.push(cacheRisk);
      } else {
        createRisks.push(cacheRisk);
      }
    });

    if (updateRisks && updateRisks.length) {
      const promises = [];
      updateRisks.forEach(risk => {
        promises.push(this.updateRisk(risk, true, false));
      });
      await Promise.all(promises);
    }

    if (createRisks && createRisks.length) {
      const promises = [];
      createRisks.forEach(newRisk => {
        promises.push(this.createNewRisk(newRisk));
      });
      await Promise.all(promises);
    }

    if (UtilsService.isBnB || UtilsService.isBnBCyberSite) {
      const updatingRisks = [...createRisks, ...updateRisks];
      if (updatingRisks && updatingRisks.length) {
        await this.entityService.updateResidualScore(updatingRisks[0].entityId, true, null, this.rskQuesCount);
      }
    }
  }

  // ----------------------------------- Collection Question Updates Functions --------------------------------------------

  manualRiskUpdateCollection(question, risks, updatedRisks, isRemoveScoreHistory = true) {
    risks
      .filter(risk => risk.type === RiskTypeEnum.MANUAL)
      .forEach(manualRisk => {
        let rskObject = null;
        if (
          manualRisk.controlIds &&
          manualRisk.controlIds.length &&
          manualRisk.controlIds.includes(question.parentId)
        ) {
          manualRisk.scoreHistory = this.updateScoreHistory(isRemoveScoreHistory, question, manualRisk.scoreHistory);

          const calculation = this.calculateResidual(manualRisk.scoreHistory, manualRisk);

          manualRisk.effectiveness = calculation.effectiveness;
          manualRisk.residualImpact = UtilsService.getImpactEnumValue(calculation.residualImpact);
          manualRisk.residualProbability = UtilsService.getProbabilityEnumValue(calculation.residualProbability);
          manualRisk.residualRisk = calculation.residualRisk;
          manualRisk.hidden = !(manualRisk.scoreHistory && manualRisk.scoreHistory.length);
          rskObject = manualRisk;
        }

        if (rskObject) {
          updatedRisks.push(rskObject);
        }
      });
  }

  /**
   * @param currentUser is type of user object ( The Current User logged into the system )
   * @param question is type of question object
   * This function basically update the Risk Against the a Question is Answered
   */
  async deduceAnswerRisk(currentUser: any, question: any, currentUserRole: any): Promise<void> {
    const assessment = question.assessmentIds[0];
    this.currentUser = currentUser;
    const frameName = FrameworkTreeService.getFrameworkKey();
    // add all checks
    // 1)- is NIST CSF
    // 2)- is from 1st Party Collection
    // 3)- check role based / impact of answer - is dependant of isPassed Value.
    // Note :- Removed the role case for Participant - only added check for Vendor Role only
    if (
      question.frameworkName === frameName &&
      this.router.url.includes('first-party') &&
      // currentUserRole.name.toLowerCase() !== RoleEnum.PARTICIPANT.toLowerCase() &&
      currentUserRole.name !== RoleEnum.VENDOR.toLowerCase()
    ) {
      // passed will be cal
      const isPassed = this.getPassedValue(question);
      try {
        // await this.getControlsList(assessment);
        const res = await this.customApi.RiskByAssessmentId(assessment);
        let dbRisks = [];
        if (res && res.items && res.items.length) {
          dbRisks = res.items;
        }
        const question2 = { ...question, isPassed };
        this.addRemoveQuestionRisk(question2, assessment, dbRisks);
      } catch (e) {
        console.log(e);
      }
    }
  }

  /**
   *
   * @param question The question object
   * @param assessment the assessment ID
   * @param dbRisks The existing risks in that assessment table
   * This functionality updates questions coming from Collection
   */
  public async addRemoveQuestionRisk(question: any, assessment: string, dbRisks: any): Promise<void> {
    // initial setup to retrieve affiliated risks
    const riskList = await this.getStaticRisks(question.frameworkName);
    const controls = await this.getMappedRisks(question.frameworkName);

    let getControlWithRisks;
    if (
      UtilsService.isDefined(question) &&
      UtilsService.isDefined(question.controlName) &&
      UtilsService.isDefined(question.controlLabel) &&
      UtilsService.isDefined(question.name)
    ) {
      getControlWithRisks = controls?.mappedRisks.find(con => {
        const conName = con?.controlName ? con.controlName.toString() : '';
        if (
          conName.toLowerCase().trim() === question.controlName.toLowerCase().trim() &&
          con.label.toLowerCase().trim() === question.controlLabel.toLowerCase().trim() &&
          question.name.toLowerCase().trim() === con.questionTitle.toLowerCase().trim()
        ) {
          return con;
        }
      });
    } else {
      return;
    }
    let riskAttached = [];
    // check getControlWithRisks is defined or not first
    if (UtilsService.isDefined(getControlWithRisks)) {
      // getting attached risks
      riskAttached = getControlWithRisks.riskID ? getControlWithRisks.riskID.split(',') : [];
    } else {
      // Just Exist the function there is no risk against this question
      return;
    }

    const riskAffiliated = [];
    riskAttached.forEach(risk => {
      riskAffiliated.push(risk.trim());
    });

    // getting risk detailed object
    const risksData = [];
    riskList?.risks.forEach(rsk => {
      if (riskAffiliated.includes(rsk.RiskID?.trim())) {
        risksData.push(rsk);
      }
    });

    const updateRisks = [];
    const newRisks = [];
    const promises = [];
    // if not passed then add the risk affiliated with that question
    // first find if those risks already exists
    if (dbRisks && dbRisks.length) {
      risksData.forEach(rsk => {
        // finding existing risk if found , else create new one.
        const rskObject = dbRisks.find(rsk1 => {
          if (
            rsk1.idTitle.toLowerCase() === rsk.RiskID.toLowerCase() &&
            rsk1.riskCategory &&
            rsk.riskCategory &&
            rsk1.riskCategory.toLowerCase() === rsk.riskCategory.toLowerCase()
          ) {
            // add control ID and make it non - hidden.
            // if already controls list exist , simply add it.
            if (rsk1.controlIds && rsk1.controlIds.length) {
              // if the control ID is already in risk , then don't update. else add it.
              if (!rsk1.controlIds.includes(question.parentId)) {
                rsk1.controlIds.push(question.parentId);
              }
            } else {
              // if it is empty, just add control id
              rsk1.controlIds = [];
              // calculate residual -------------------------------------------------------------------------------------
              rsk1.controlIds.push(question.parentId);
            }
            rsk1.scoreHistory = this.updateScoreHistory(false, question, rsk1.scoreHistory);

            const calculation = this.calculateResidual(rsk1.scoreHistory, rsk1);

            rsk1.effectiveness = calculation.effectiveness;
            rsk1.residualImpact = UtilsService.getImpactEnumValue(calculation.residualImpact);
            rsk1.residualProbability = UtilsService.getProbabilityEnumValue(calculation.residualProbability);
            rsk1.residualRisk = calculation.residualRisk;

            rsk1.hidden = false;

            return rsk1;
          }
        });
        // check if existing risk needs updating.
        if (rskObject) {
          updateRisks.push(rskObject);

          // check score history, if all scores 10 then mitigate risk or if it is Semi-Auto Risk having
          // only Manual Tasks , then mitigate it
          this.mitigateRiskBaseOnScoreHistory(rskObject);
        } else if (!rskObject) {
          // if for some reason the risk does not exist we must add new risk with control id.
          const createdRisk = this.createNewRiskObject(rsk, question, assessment);
          newRisks.push(createdRisk);

          // check score history, if all scores 10 then mitigate risk or if it is Semi-Auto Risk having
          // only Manual Tasks , then mitigate it
          this.mitigateRiskBaseOnScoreHistory(createdRisk);
        }
      });
      this.manualRiskUpdateCollection(question, dbRisks, updateRisks, false);
      if (updateRisks && updateRisks.length) {
        // --------------------------------------------------------------------------------API CALL
        updateRisks.forEach(risk => {
          promises.push(this.updateRisk(risk, true, false));
        });
      }
      if (newRisks && newRisks.length) {
        // ---------------------------------------------------------------------------------API CALL
        newRisks.forEach(newRisk => {
          promises.push(this.createNewRisk(newRisk, true));
        });
      }
      if (UtilsService.isBnB || UtilsService.isBnBCyberSite) {
        await this.entityService.updateResidualScore(question.rootId, true, null, this.rskQuesCount);
      }
    } else {
      const newRisksObj = [];
      risksData.forEach(rsk => {
        const newRiskObject = this.createNewRiskObject(rsk, question, assessment);
        newRisksObj.push(newRiskObject);
        this.mitigateRiskBaseOnScoreHistory(newRiskObject);
      });
      // if they don't exist , add them
      // -----------------------------------------------------------------------------------API CALL
      newRisksObj.forEach(newRisk => {
        promises.push(this.createNewRisk(newRisk, true));
      });

      if (UtilsService.isBnB || UtilsService.isBnBCyberSite) {
        await this.entityService.updateResidualScore(question.rootId, true, null, this.rskQuesCount);
      }
    }
    if (promises && promises.length) {
      await Promise.all(promises);
    }
  }

  // --------------------------------------------- Helper functions -------------------------------------------------

  /**
   *
   * @param rsk The risk containing it's details
   * @param question The question related to that risk
   * @param assessment the Assessment ID
   * @returns The complete score object to store in DB
   */
  createNewRiskObject(rsk: any, question: any, assessment: string): any {
    const scoreList: RiskQuestionScoreInput[] = [this.createScoreHistory(question, question.action)];
    this.mitigateRiskBaseOnScoreHistory(rsk);
    const newRisk = {
      id: `${assessment}#${rsk.RiskID.trim()}`,
      riskTitle: rsk.riskTitle.trim(),
      relatedAssets: rsk.relatedAssets ? rsk.relatedAssets.split(', ') : [],
      entityId: question.rootId,
      subEntityId: this.controlsList[question.parentId].entityId,
      idTitle: rsk.RiskID.trim(),
      assessmentId: assessment,
      type: RiskTypeEnum.AUTOMATIC,
      riskImpact:
        rsk.riskImpact && rsk.riskImpact.toLowerCase() === 'critical' ? 'CRITICAL' : rsk.riskImpact.toUpperCase(),
      riskProbability:
        rsk.riskProbability && rsk.riskProbability.toLowerCase() === 'critical'
          ? 'CRITICAL'
          : rsk.riskProbability.toUpperCase(),
      riskPriority: UtilsService.getRandomEnumVal(RiskPriorityEnum),
      disable: false,
      residualRisk: 0,
      residualImpact: rsk.riskImpact,
      residualProbability: rsk.riskProbability,
      inherentRisk: rsk.inherentRisk,
      riskStatus: rsk.riskStatus ? rsk.riskStatus.toUpperCase() : rsk.riskStatus,
      costToRemediateType: 'LOW',
      controlIds: [this.controlsList[question.parentId].id],
      riskDescription: rsk.riskDescription,
      notes: rsk.notes,
      riskCategory: rsk.riskCategory,
      scoreHistory: scoreList,
      sensitivity: 1,
      effectiveness: 0,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    };
    const inherent =
      (UtilsService.getLabelValueNum(newRisk.riskImpact) + UtilsService.getLabelValueNum(newRisk.riskProbability)) / 2;
    newRisk.inherentRisk = parseFloat(inherent.toFixed(1));

    const calculation = this.calculateResidual(scoreList, newRisk);

    newRisk.effectiveness = calculation.effectiveness;
    newRisk.residualImpact = UtilsService.getImpactEnumValue(calculation.residualImpact);
    newRisk.residualProbability = UtilsService.getProbabilityEnumValue(calculation.residualProbability);
    newRisk.residualRisk = calculation.residualRisk;

    return newRisk;
  }

  /**
   * @param value the value is number between 0-10
   * @param isInherent Does the list contain Critical Keyword
   * @returns The String value equivalent of the ranged value 0-10
   */
  turnIntToStr(value: number, isInherent: boolean): string {
    const newVal = value * 10;
    const strVal = UtilsService.getLabelValue(newVal, isInherent ? 'impact' : 'probability');
    return UtilsService.capitalizeFirstLetter(strVal);
  }

  /**
   *
   * @param controlId The control ID to search for
   * @param scrHist The scoreHistoryObject
   * @returns True if controlID removal is required or False if not
   */
  removeControlName(controlId: string, scrHist: any): boolean {
    if (scrHist && scrHist.length) {
      let count = 0;
      scrHist.forEach(score => {
        if (score.controlId === controlId) {
          count = count + 1;
        }
      });
      // the count determines if there is a question regarding that control in list.
      if (count > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /**
   *
   * @param question the question of the recent update
   * @returns the object containing scoreHistoryObject
   */
  createScoreHistory(question: any, riskAction: any): RiskQuestionScoreInput {
    return {
      controlId: question.parentId || question.controlId,
      questionOrder: question.order,
      remediationStatus: riskAction,
      score: question.isPassed,
      left: question.left,
      right: question.right,
    };
  }

  updateScoreHistory(
    removed: boolean,
    question: any,
    scoreHist: RiskQuestionScoreInput[],
    riskAction?: RiskAction
  ): RiskQuestionScoreInput[] {
    let updatedHistory: RiskQuestionScoreInput[] = [];
    if (removed) {
      // removing the instance of that score history object
      if (scoreHist && scoreHist.length) {
        const index = this.findQuestionInScoreHist(question, scoreHist);
        if (index > -1) {
          // the instance is found and simply removing it
          scoreHist.splice(index, 1);
          // if there is anything left , return the scoreHist or return []
          if (scoreHist.length) {
            updatedHistory = JSON.parse(JSON.stringify(scoreHist));
            return updatedHistory;
          } else {
            return updatedHistory;
          }
        }
      } else {
        return updatedHistory;
      }
    } else {
      const riskStatus = UtilsService.isDefined(riskAction) ? riskAction : 'NA';
      // adding the new score hist object
      if (scoreHist && scoreHist.length) {
        const index = this.findQuestionInScoreHist(question, scoreHist);
        if (index > -1) {
          // if found , find the instance and update its score
          scoreHist[index].score = question.isPassed;
          // if riskAction is defined then set remediationStatus = riskAction, if not then 'NA'
          scoreHist[index].remediationStatus = riskStatus;
          updatedHistory = JSON.parse(JSON.stringify(scoreHist));
          return updatedHistory;
        } else {
          // if there is not instance , then simply add into existing score hist
          scoreHist.push(this.createScoreHistory(question, riskStatus));
          updatedHistory = JSON.parse(JSON.stringify(scoreHist));
          return updatedHistory;
        }
      } else {
        // score hist is empty , add the only 1 here and return it.
        updatedHistory.push(this.createScoreHistory(question, riskStatus));
        return updatedHistory;
      }
    }
  }

  /**
   *
   * @param scoreHist The score History List object
   * @returns the residual risk calculation
   */
  calculateResidual(scoreHist: RiskQuestionScoreInput[], riskObj: any): any {
    let effectiveness = 0;
    let residualRisk = 0;
    let residualImpact = 0;
    let residualProbability = 0;

    let inherentScore =
      (UtilsService.getLabelValueNum(riskObj.riskImpact) + UtilsService.getLabelValueNum(riskObj.riskProbability)) / 2;
    inherentScore = parseFloat(inherentScore.toFixed(2));

    if (scoreHist && scoreHist.length) {
      // if there are score objects , calculate the score
      let impactWithWeight = 0;
      let probabilityWithWeight = 0;
      let totalWeight = 0;

      scoreHist.forEach(({ score, weight, impact, probability }) => {
        // *Removing the N/A Questions
        if (!score) {
          return;
        }

        //* Not Calculating Total Impact and probability for NO answers
        if (score !== 1) {
          impactWithWeight += weight * score * (impact ? 1 : 0);
          probabilityWithWeight += weight * score * (probability ? 1 : 0);
        }

        if (weight) {
          totalWeight += weight;
        }
      });

      if (totalWeight || totalWeight === 0) {
        const finalImpactwithWeight = totalWeight !== 0 ? impactWithWeight / totalWeight : 0;
        const finalProbabilitywithWeight = totalWeight !== 0 ? probabilityWithWeight / totalWeight : 0;

        residualImpact =
          UtilsService.getLabelValueNum(riskObj.riskImpact) * (1 - (riskObj.sensitivity * finalImpactwithWeight) / 10);
        residualProbability =
          UtilsService.getLabelValueNum(riskObj.riskProbability) *
          (1 - (riskObj.sensitivity * finalProbabilitywithWeight) / 10);
        residualRisk = (residualImpact + residualProbability) / 2;
      }
    } else {
      // if there is none - simply score would be 10
      residualRisk = inherentScore;
      residualRisk = UtilsService.getLabelValueNum(riskObj.riskImpact);
      residualProbability = UtilsService.getLabelValueNum(riskObj.riskProbability);
    }

    //* New Effectiveness
    effectiveness = ((inherentScore - residualRisk) / inherentScore) * 10;

    // boundary cases
    residualRisk = UtilsService.fixBoundaries(residualRisk);
    effectiveness = UtilsService.fixBoundaries(effectiveness);
    residualImpact = UtilsService.fixBoundaries(residualImpact);
    residualProbability = UtilsService.fixBoundaries(residualProbability);

    // Parse Float and for one digit
    residualRisk = parseFloat(residualRisk.toFixed(2));
    effectiveness = parseFloat(effectiveness.toFixed(1));
    residualImpact = parseFloat(residualImpact.toFixed(2));
    residualProbability = parseFloat(residualProbability.toFixed(2));

    return {
      residualRisk,
      effectiveness,
      residualImpact,
      residualProbability,
    };
  }
  /**
   *
   * @param question the question object
   * @param scoreHist the score history object of the risk
   * @returns the index position of the question in score history
   */
  findQuestionInScoreHist(question: any, scoreHist: RiskQuestionScoreInput[]): number {
    const index = scoreHist.findIndex(
      score =>
        score.controlId === question.parentId &&
        score.questionOrder === question.order &&
        score.left === question.left &&
        score.right === question.right
    );
    return index;
  }

  /**
   *
   * @param question The question object that contains the answer score
   * @returns The score value of that question
   */
  getPassedValue(question: any): number {
    // if question object contains settings then it means the payload is from collection
    if (question.settings) {
      if (question.settings.isCollaborative) {
        if (question.answers.length > 1) {
          // get the Answer by latest date
          const latestAnswer = question.answers.reduce((a, b) => (a.createdAt > b.createdAt ? a : b));
          question.answers = [latestAnswer];
        }
      } else {
        // get Latest Answer on the Current UserID
        const latestAnswer = question.answers.find(item => item.userId === this.currentUser.id);
        question.answers = [latestAnswer];
      }
      if (question.answers && question.answers[0] && UtilsService.isDefined(question.answers[0].answer)) {
        // if question's answered id (Not applicable or answer=0) . we return isPassed =10
        if (question.answers[0].answer === 0) {
          return 10;
        } else if (question.answers[0].answer < 0) {
          return 0;
        }
        return question.answers[0].answer;
      }
    } else {
      // the payload is from remediation
      // if question's answered id (Not applicable or answer=0) . we return isPassed =10
      if (question.score === 0) {
        return 10;
      } else if (question.score < 0) {
        return 0;
      }
      return question.score;
    }
    return 0;
  }

  /**
   * @param question is type Question object
   * @returns is type of list of Risks against a question
   */
  async getRisksOfAQuestion(question: any): Promise<any> {
    const riskList = await this.getStaticRisks(question.frameworkName);
    const controls = await this.getMappedRisks(question.frameworkName);
    // getting controls with mapped risks
    const controlName = question.controlName
      ? question.controlName
      : question.controlsConnected
      ? question.controlsConnected[0].name
      : '';
    const controlLabel = question.controlLabel
      ? question.controlLabel
      : question.controlsConnected
      ? question.controlsConnected[0].label
      : '';
    const getControlWithRisks = controls?.mappedRisks.find(con => {
      return con.controlName === controlName && con.label === controlLabel && question.title === con.questionTitle;
    });
    // getting attached risks
    let riskAttached = null;
    if (UtilsService.isDefined(getControlWithRisks)) {
      // getting attached risks
      riskAttached = getControlWithRisks.riskID ? getControlWithRisks.riskID.split(',') : [];
    } else {
      // Just Exist the function there is no risk against this question
      return;
    }
    const riskAffiliated = [];
    riskAttached.forEach(risk => {
      riskAffiliated.push(risk.trim());
    });
    // getting risk detailed object
    const risksData = [];
    riskList?.risks.forEach(rsk => {
      if (riskAffiliated.includes(rsk.RiskID.trim())) {
        risksData.push(rsk);
      }
    });

    return risksData;
  }
  getManualRisksOfAQuestion(risks = [], question) {
    const risksData = [];
    risks.forEach(manualRisk => {
      if (manualRisk.controlIds && manualRisk.controlIds.length && manualRisk.controlIds.includes(question.controlId)) {
        risksData.push({
          id: manualRisk.id,
          RiskID: manualRisk.idTitle,
          inherentRisk: manualRisk.inherentRisk,
          relatedAssets: manualRisk.relatedAssets ? manualRisk.relatedAssets.join(',') : '',
          riskCategory: manualRisk.riskCategory,
          riskDescription: manualRisk.riskDescription,
          riskImpact: manualRisk.riskImpact,
          riskProbability: manualRisk.riskProbability,
          riskStatus: manualRisk.riskStatus,
          riskTitle: manualRisk.riskTitle,
          type: manualRisk.type,
        });
      }
    });
    return risksData.length ? risksData : null;
  }

  mitigateRiskBaseOnScoreHistory(risk): void {
    if (risk && risk.scoreHistory && !!risk.scoreHistory.length) {
      const scores = risk.scoreHistory.map(sh => sh.score);
      const status = risk.scoreHistory.map(sh => sh.remediationStatus);

      // need to check the total count of questions ,
      // even including those questions as well which haven't been answered.
      let totalQC = this.getRiskTotalQuestionCount(risk.idTitle);
      if (!totalQC) {
        totalQC = risk.scoreHistory.length;
      }
      const scoreTenCount = scores.reduce((m, a) => {
        if (a === 10) {
          m += 1;
        }
        return m;
      }, 0);
      // commenting this for now
      // const scoreOneCount = scores.reduce((m, a) => {
      //   if (a === 1) {
      //     m += 1;
      //   }
      //   return m;
      // }, 0);
      const acceptedCount = status.reduce((m, a) => {
        if (a === RiskAction.ACCEPTED) {
          m += 1;
        }
        return m;
      }, 0);
      // commenting this condition for now : (scoreOneCount === risk.scoreHistory.length && acceptedCount === risk.scoreHistory.length)
      // finding 1 score count, if score 1 count equal to scoreHistory length, it means
      // all relevant questions has been accepted
      if (acceptedCount === totalQC) {
        // if all of the tasks are accepted then set the status to accepted
        console.log('risk accepted');
        risk.riskStatus = RiskStatusEnum.ACCEPTED;
      } else if (scoreTenCount === totalQC) {
        // finding 10 score count, if score 10 count equal to scoreHistory length, it means
        // all relevant questions has been answered Yes and all of them are not ignored, change the status to mitigate
        console.log('risk mitigating');
        risk.riskStatus = RiskStatusEnum.MITIGATED;
      } else {
        console.log('risk pending');
        risk.riskStatus = RiskStatusEnum.OPEN;
      }
    }

    // Self Note , not using the totalQC variable for SEMI and MANUAL risks.
    // cause we are checking manual tasks here...in which their total count is dynamic.

    // If a Risk has Manual Tasks ( Only Semi-Auto and Manual Risks have them )
    if (risk && risk.type && risk.type !== RiskTypeEnum.AUTOMATIC) {
      if (risk.riskTasks && risk.riskTasks.length) {
        let closedCount = 0;
        risk.riskTasks.forEach(task => {
          if (task.status === TaskStatusEnum.CLOSED) {
            closedCount++;
          }
        });
        // if one of these conditions is True , only the the Manual Tasks affect the risk status
        // 1st if the Risk does not have score history , meaning no auto tasks are created.
        // 2nd if the Risk does have a score history but length is 0 , still same case for 1st condition
        // 3rd if the Risk does have score history and has actual values , we must check if the status is being
        // mitigated , only the Manual Tasks status's are counted.
        if (
          !risk.scoreHistory ||
          (risk.scoreHistory && !risk.scoreHistory.length) ||
          (risk.scoreHistory.length && risk.riskStatus === RiskStatusEnum.MITIGATED)
        ) {
          risk.riskStatus = closedCount === risk.riskTasks.length ? RiskStatusEnum.MITIGATED : RiskStatusEnum.OPEN;
        }
      }
    }
  }

  // Note
  // Create Answer for Question if User never answer it, execute in risk mitigate
  async createAnswerForQuestionOnRiskBasis(question, userAssignment, userAnswer) {
    const frameName = FrameworkTreeService.getFrameworkKey();
    const createAnswer: CreateAnswerInput = {
      id: uuid(),
      assessmentId: userAssignment.assessmentId,
      assignmentID: userAssignment.id,
      userId: userAssignment.userId,
      answer: userAnswer,
      questionId: question.id,
      date: Date.now(),
      // for now its hard coded, but we need to save framework name in risk as well.
      frameworkName: frameName,
      riskAction: RiskAction.MANAGED,
      isActionTaken: true,
      left: question.left,
      right: question.right,
    };
    await this.customApi.CreateAnswer(createAnswer);
  }

  /**
   * Following are the functions to cater the Auto Linking of Manual Tasks in
   * Semi-Auto and Manual Risks.
   */

  /**
   * The function updates the manual tasks status's in the Risk Table
   * @param taskObjList The Task Obj with Task ID and Assessment ID
   * @param entityId The root Entity ID
   * @returns
   */
  async updateRiskManualTaskStatus(taskObjList: any, entityId: string): Promise<void> {
    const filterArr = [];

    // first we will build the Filter Query to get all the risks containing the Task Ids
    Object.keys(taskObjList).forEach(tk => {
      filterArr.push({
        assessmentId: {
          eq: taskObjList[tk].assessmentId,
        },
        riskTasksIds: {
          contains: tk,
        },
      });
    });
    const filterInput: ModelRiskFilterInput = {
      or: filterArr,
    };
    let riskListFound = null;
    try {
      const cacheList = await this.customApi.RiskByEntityId(entityId, null, filterInput, 1000, null);
      riskListFound = cacheList.items;
      let completeCounter = 0;
      const updateRisks = [];
      if (riskListFound && riskListFound.length) {
        // if the Risk is found , update the Task Status
        riskListFound.forEach(rsk => {
          completeCounter = 0;
          if (rsk.riskTasks && rsk.riskTasks.length) {
            rsk.riskTasks.forEach(task => {
              if (taskObjList[task.id]) {
                task.status = TaskStatusEnum.CLOSED;
              }
              if (task.status === TaskStatusEnum.CLOSED) {
                completeCounter++;
              }
            });

            // if all Task status is Updated to Mitigated , then Change Risk Status as well or vice versa
            if (completeCounter === rsk.riskTasks.length && rsk.type === RiskTypeEnum.HARD_MANUAL) {
              rsk.riskStatus = RiskStatusEnum.MITIGATED;
            }
            if (completeCounter === rsk.riskTasks.length && rsk.type === RiskTypeEnum.MANUAL) {
              this.mitigateRiskBaseOnScoreHistory(rsk);
            }
            updateRisks.push(rsk);
          }
        });

        // Updating the Risk Instance in DB
        if (updateRisks && updateRisks.length) {
          const promises = [];
          updateRisks.forEach(risk => {
            promises.push(this.updateRisk(risk, false, false));
          });
          await Promise.all(promises);
        }
      }
    } catch (e) {
      console.log(e);
      return;
    }
  }

  manualTasksStatusCheck(taskList: any): boolean {
    let counter = 0;
    taskList.forEach(tsk => {
      if (tsk.status === TaskStatusEnum.CLOSED) {
        counter++;
      }
    });
    return counter === taskList.length;
  }

  /**
   * To Update the Task Status in CustomTask Table
   * @param taskList The task list containing Task ID and Task Status
   */
  async updateTaskInRemediation(taskList: any[]): Promise<void> {
    await Promise.all(
      taskList.map(async tsk => {
        await this.customApi.UpdateCustomTask({
          id: tsk.id,
          status: tsk.status as TaskStatusEnum,
          assessmentId: tsk?.assessmentId,
        });
      })
    );
  }

  async setSubEntitiesBaseOnRoot(): Promise<void> {
    if (!this.rootEntityId) {
      return;
    }
    try {
      this.subEntities = await this.entityService.listChildEntitysByParentId(this.rootEntityId);
      this.nistSubEntities = this.subEntities;
    } catch (e) {
      console.log('Error - setSubEntitiesBaseOnRoot: ', e);
    }

    // Commenting this code, because client wants to see all subentities here as it is displaying in the dropdowns.

    // // filter only sub-entities which has NIST_CSF
    // this.nistSubEntities = this.subEntities.filter(subEntity => {
    //   if (subEntity.activeAssessment) {
    //     const frameKey = FrameworkTreeService.getFrameworkKey();
    //     const frameworkSubEntitiesList = subEntity.activeAssessment.standardFrameworkList.items;
    //     const nist = frameworkSubEntitiesList.find(frame => frame.key === frameKey);
    //     return nist;
    //   }
    //   return false;
    // });
  }

  async getSubEntitiesByOnRootId(): Promise<void> {
    if (!this.rootEntityId) {
      return;
    }
    try {
      this.subEntitiesByRootId = await this.entityService.listEntitiesByParentId(this.rootEntityId);
      this.subEntities = this.subEntitiesByRootId;
      this.nistSubEntities = this.subEntities;
    } catch (e) {
      console.log('Error - getSubEntitiesByOnRootId: ', e);
    }
  }

  formattedRiskTitlesBaseOnTypes(riskList): IRiskTypes {
    return riskList?.reduce((prev: any, current: any) => {
      if (prev[current?.type]) {
        prev[current.type].push(current?.idTitle);
      } else {
        prev[current?.type] = [];
        prev[current?.type].push(current?.idTitle);
      }
      return prev;
    }, {});
  }

  getRiskIdTitle(riskIdTitlesMap, type: string, stringPart = ''): string {
    // stringPart can have custom string like for bulk upload where we will pass
    // RB for risk bulk
    if (riskIdTitlesMap && type) {
      let idTitle;
      switch (type.toLowerCase()) {
        case RiskTypeEnum.AUTOMATIC.toLowerCase(): {
          stringPart ||= 'RA';
          break;
        }
        case RiskTypeEnum.MANUAL.toLowerCase(): {
          stringPart ||= 'RS';
          break;
        }
        case RiskTypeEnum.HARD_MANUAL.toLowerCase(): {
          stringPart ||= 'RM';
          break;
        }
      }
      let count = riskIdTitlesMap[type] ? riskIdTitlesMap[type].length : 0;
      idTitle = `${stringPart + count}`;
      if (riskIdTitlesMap[type]) {
        while (riskIdTitlesMap[type].includes(idTitle)) {
          count++;
          idTitle = `${stringPart + count}`;
        }
        riskIdTitlesMap[type].push(idTitle);
      } else {
        idTitle = `${stringPart + 0}`;
        riskIdTitlesMap[type] = [idTitle];
      }
      return idTitle;
    }

    return '';
  }

  getIsArchiveMode(): boolean {
    return this.isArchiveMode;
  }

  // find any attribute which is empty or not equivalent to default values for some attribute
  // are corrupted risk, use in bulk upload
  isCorruptedRisk(risk): boolean {
    if (!risk) {
      return false;
    }
    return (
      Object.values(risk).some(r => !r) ||
      !UtilsService.toLowerCaseAll(Object.keys(RiskTableConstant.ASSET_ITEMS)).includes(
        risk[RiskColumnMappersEnum.affectedAssets][0]?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(ProbabilityEnum)).includes(
        risk[RiskColumnMappersEnum.probability]?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(RiskConfidenceEnum)).includes(
        risk[RiskColumnMappersEnum.priority]?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(ImpactEnum)).includes(
        risk[RiskColumnMappersEnum.impact]?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(ImpactEnum)).includes(
        UtilsService.getLabelValueBaseOnNum(risk[RiskColumnMappersEnum.inherent])?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(ProbabilityEnum)).includes(
        UtilsService.getLabelValueBaseOnNum(risk[RiskColumnMappersEnum.residual], 2)?.toLowerCase()
      ) ||
      !UtilsService.toLowerCaseAll(Object.values(StatusEnum)).includes(
        risk[RiskColumnMappersEnum.status]?.toLowerCase()
      )
    );
  }

  async getRiskComments(riskId: string, assessmentId: string): Promise<CommentsByRiskIdQuery[]> {
    try {
      let results = [];
      let nextToken = null;
      const filter = {
        assessmentId: {
          eq: assessmentId,
        },
      };
      do {
        const result = await this.customApi.CommentsByRiskId(riskId, null, filter, 10000, nextToken);
        results = results.concat(result.items);
        nextToken = result.nextToken;
      } while (nextToken);
      return results;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getRiskOwners(riskId: string): Promise<any[]> {
    try {
      let results: any = [];
      let nextToken = null;
      do {
        const result = await this.customApi.RiskAssignmentByRiskId(riskId, null, null, 10000, nextToken);
        results = results.concat(result.items);
        nextToken = result.nextToken;
      } while (nextToken);
      return results;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async createComment(comment: CreateCommentInput): Promise<GetCommentQuery> {
    return await this.customApi.CreateComment(comment);
  }

  async updateComment(comment: UpdateCommentInput): Promise<GetCommentQuery> {
    return await this.customApi.UpdateComment(comment);
  }

  async deleteComment(comment: DeleteCommentInput, assessmentId: string): Promise<GetCommentQuery> {
    return await this.customApi.DeleteComment(comment, { assessmentId: { eq: assessmentId } });
  }

  createLogsAfterRiskUpdated(currentUser: any, oldRisk: any, newRisk: any): void {
    if (oldRisk.riskDescription !== newRisk.riskDescription) {
      this.addLog(
        newRisk?.id,
        newRisk?.assessmentId,
        getLogMessage(LogsKeyEnum.DESCRIPTION_CHANGED_RISK, currentUser?.name)
      );
    }
    if (oldRisk.riskStatus !== newRisk.riskStatus) {
      this.addLog(
        newRisk?.id,
        newRisk?.assessmentId,
        getLogMessage(LogsKeyEnum.STATUS_CHANGED_RISK, currentUser?.name, newRisk.riskStatus.toLowerCase())
      );
    }
    if (
      !this.compareTags(
        newRisk.riskTags?.length ? newRisk.riskTags : [],
        oldRisk.riskTags?.length ? oldRisk.riskTags : []
      )
    ) {
      this.addLog(newRisk?.id, newRisk?.assessmentId, getLogMessage(LogsKeyEnum.TAGS_ADDED_RISK, currentUser?.name));
    }
    if (
      !this.compareTags(
        newRisk.relatedAssets?.length ? newRisk.relatedAssets : [],
        oldRisk.relatedAssets?.length ? oldRisk.relatedAssets : []
      )
    ) {
      this.addLog(newRisk?.id, newRisk?.assessmentId, getLogMessage(LogsKeyEnum.ASSETS_ADDED_RISK, currentUser?.name));
    }
  }

  compareTags(a: [string], b: [string]): boolean {
    const first = a.map(item => item.toUpperCase());
    const second = b.map(item => item.toUpperCase());
    return first.length === second.length && first.every(element => second.includes(element));
  }

  // to save a log related to Risk targetId pattern is 'assessmentId#riskId'
  // type may contain useful keywords like RISK#<idTitle>
  async addLog(riskId: string, assessmentId: string, message: string): Promise<CreateLogsInput> {
    const targetId = assessmentId + '#' + riskId;
    const type = LogsTypeEnum.RISK + '#' + riskId.split('#')[1];

    const log = {
      id: uuid(),
      message,
      targetId,
      type,
      assessmentId,
    };
    return await this.customApi.CreateLogs(log);
  }

  async addLineChartData(
    riskID: string,
    assessmentID: string,
    inherentValue: RiskImpactEnum,
    residualValue: RiskImpactEnum
  ): Promise<CreateRiskLineChartMutation> {
    try {
      const chartData = {
        assessmentId: assessmentID,
        riskId: riskID,
        residual: residualValue,
        inherent: inherentValue,
      };
      return await this.customApi.CreateRiskLineChart(chartData);
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  async getLineChartData(filter = null): Promise<any> {
    try {
      const limit = 300;
      let nextToken;
      let lineChartData = [];
      let responseitems = 0;
      do {
        const { items: linechart, nextToken: rnextToken } = await this.customApi.ListRiskLineCharts(
          filter,
          limit,
          nextToken
        );
        nextToken = rnextToken;
        lineChartData = lineChartData.concat(linechart);
        responseitems = linechart.length;
      } while (nextToken && responseitems);
      this.riskGraphLineData = lineChartData?.length ? [...lineChartData] : [];
      return lineChartData;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  async getLogsByTargetId(targetId: string): Promise<LogsByTargetIdQuery> {
    try {
      const logs: LogsByTargetIdQuery = await this.customApi.LogsByTargetId(targetId);
      return logs;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }

  async getTagsByEntityId(entityId: string, filter = null): Promise<any> {
    try {
      const limit = 300;
      let nextToken;
      let totalTags = [];
      do {
        const { items: tags, nextToken: rnextToken }: TagsByEntityIdQuery = await this.customApi.TagsByEntityId(
          entityId,
          null,
          filter,
          limit,
          nextToken
        );
        nextToken = rnextToken;
        totalTags = totalTags.concat(tags);
      } while (nextToken);
      return totalTags;
    } catch (err) {
      console.log('Error: ', err);
      return Promise.reject(err);
    }
  }
}
