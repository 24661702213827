import { CollectionService } from 'app/collection/collection.service';
import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbDropdownConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EntityTypeEnum, GetEntityQuery, GetUserQuery } from '../API.service';
import { NGXLogger } from 'ngx-logger';
import { GeneratorComponent } from 'app/generator/generator.component';
import { Location } from '@angular/common';
import { EntityService, GetEntityQueryExtended } from 'app/shared/entity.service';
import { BoardService } from 'app/board/board.service';
import { UtilsService } from 'app/shared/utils.service';
import { DomainFrameworksModalComponent } from 'app/shared/domain-frameworks-modal/domain-frameworks-modal.component';
import { UserService } from 'app/shared/user.service';
import { AppLevelRoleEnum } from 'app/shared/enums/appLevelRoles.enum';
import { UsersSettingsService } from 'app/users-settings/users-settings.service';
import { Subscription } from 'rxjs';

interface IEntityDropdown {
  id?: string;
  name?: any;
  image?: string;
}
@Component({
  selector: 'cygov-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbDropdownConfig],
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  notificationCount = 3;
  entityData: IEntityDropdown[] = [];
  selectedRootEntity: IEntityDropdown;
  isBoard = false;
  modalRef: NgbModalRef;
  user: GetUserQuery;
  entityList: GetEntityQueryExtended[] = [];
  subEntity: GetEntityQuery = null;
  complianceQualification: string;
  showEntitySummary = false;
  isAdmin = false;
  isWelcome = false;
  loading = true;
  currentRoute: string;
  isBnBCyberSite = UtilsService.isBnBCyberSite;
  isBnb = UtilsService.isBnB;
  bnbLogoPath = './assets/images/brown-brown-logo.png';
  mainLogoName = UtilsService.companyConfig.mainLogoName;
  isLogoDisabled: boolean = false;
  subscriptionList: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private entityService: EntityService,
    private boardService: BoardService,
    private router: Router,
    private modalService: NgbModal,
    private _location: Location,
    private toastr: ToastrService,
    config: NgbDropdownConfig,
    private logger: NGXLogger,
    private userService: UserService,
    private usersSettingsService: UsersSettingsService,
    private collectionService: CollectionService
  ) {
    config.placement = 'bottom-right';
  }

  ngAfterViewInit() {
    this.isBoard = this.router.url.includes('board');
    this.currentRoute = this.router.url;
  }

  async ngOnInit(): Promise<void> {
    try {
      this.subscriptionList.push(
        this.usersSettingsService.getIsAddNewUSerModalOpened().subscribe(res => {
          this.isLogoDisabled = res;
        })
      );

      this.isWelcome = this.authService.isFirstTimeUser();
      this.authService.welcomeGuideStatus.subscribe(change => {
        if (change) {
          this.isWelcome = false;
        }
      });
      this.router.events.subscribe(url => {
        if (url instanceof NavigationEnd) {
          this.currentRoute = this.router.url;
          this.isBoard = this.router.url.includes('board');
        }
      });
      this.isAdmin = this.userService.hasPermissionHierarchy(AppLevelRoleEnum.ADMIN);
      this.user = await this.userService.getCurrentUser();
      if (this.isAdmin) {
        // this.entityList = await this.getRootEntityList();
        this.entityData = this.initEntityData();
        this.boardService.getCurrentEntityId().subscribe(entityId => {
          if (entityId && entityId !== 'null') {
            this.selectedRootEntity = this.entityData.find(data => data.id === entityId);
          }
        });
      }
      this.loading = false;
      this.authService.setHeaderLoaderStatus(this.loading);
    } catch (e) {
      this.logger.error(e);
      this.toastr.error('HeaderComponent Error');
      this.loading = false;
    }
  }

  initEntityData(): IEntityDropdown[] {
    return this.entityList.map(entity => ({
      id: entity.id,
      name: entity.name,
      image: entity.logoUrl,
    }));
  }

  setEntity(): void {
    this.boardService.setCurrentEntityId(this.selectedRootEntity.id);
    this.router.navigate(['/']).then(() => this.router.navigate(this.boardService.generateUrlWithBaseUrl('wizard')));
  }

  async getRootEntityList(): Promise<GetEntityQueryExtended[]> {
    return await this.entityService.listEntitysByEntityType(EntityTypeEnum.ROOT_ENTITY, null, null, true);
  }

  signOut(): void {
    this.userService
      .logOut()
      .then(() => {
        this.toastr.success('Logged out');
        this.entityService.isUserLoggedIn = false;
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.logger.error(err);
        this.toastr.error('Log out failed');
      });
    // nullifying object because in case of logging out it is keeping the data of previous user
    this.collectionService.singleTypeQuestionActiveUsers = {};
  }

  async goHomeByRole(): Promise<void> {
    if (!this.isLogoDisabled) {
      await this.userService.navigateByRole();
    }
  }

  navigateTo(route: string, queryString: any = null): void {
    if (queryString) {
      this.router.navigate([route], { queryParams: queryString });
    } else {
      this.router.navigate([route]);
    }
  }

  openGeneratorModal(): void {
    const modalRef = this.modalService.open(GeneratorComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'generator-modal',
      backdropClass: 'generator-backdrop',
    });

    modalRef.result.then(
      () => {},
      () => {
        this._location.back();
      }
    );
  }

  openFrameworksSettings(): void {
    const modalRef = this.modalService.open(DomainFrameworksModalComponent, {
      centered: true,
      size: 'sm',
      windowClass: 'confirmation-modal',
    });
    modalRef.componentInstance.modalResult.subscribe((result: boolean) => {
      if (result) {
        modalRef.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach(listener => listener.unsubscribe());
  }
}
