import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
  ViewChild,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Animations } from './animation';

@Component({
  selector: 'cygov-custom-drop-down',
  templateUrl: './custom-drop-down.component.html',
  styleUrls: ['./custom-drop-down.component.scss'],
  animations: [Animations.colorChanger, Animations.heightChanger],
})
export class CustomDropDownComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isNetSkope = false;
  @Input() itemList = []; // Items array that will be listed in dropdown
  @Input() cachedItemList = []; // A copy of original array for search purposes
  @Input() preSelectedItems = []; // Array that will have items that are already selected :: Edit Mode
  @Input() bindLabel: string; // Label will be the column/property that will be display in the list from the ItemList
  @Input() title: string = 'Change'; // PlaceHolder when no item is selected
  @Input() bindValue: any;
  @Input() showCheckbox: boolean = false; // Boolean to show checkbox or not
  @Input() showSearch: boolean = false; // Boolean to show Search Box or not
  @Input() showMore: boolean = false; // Boolean to show Show More button
  @Input() showCross: boolean = false; // Boolean to show cross icon with selected field
  @Input() selectedColor: string = '#29A9EB'; // Color of the items that are selected
  @Input() itemsColor: string = '#05091C'; // Color of the dropdown area
  @Input() closed: string = '#1C1F29';
  @Input() widthArea: string = '16.61vw'; // Width of DropDown
  @Input() closedColor = '#03050a';
  @Input() openedColor = '#21242f';
  @Input() preSelectedItem = '';
  @Input() closeOutSideBox = false;
  @Input() singleSelect = false;
  @Input() isSubEntitySelection = true;
  @Input() isDropDownOpen = false;
  @Input() searchIconSvgName = 'search-icon';
  @Output() returnValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() ngModelValueChange = new EventEmitter<any>();
  @Output() toggleDropDownEvent = new EventEmitter<boolean>();

  selectedItems: any[] = [];
  @ViewChild('customDropDownArea') customDropArea: ElementRef;
  selectedItem = 'On Schedule';
  mode = 'ebony';
  fullHeightArea = '';
  clickListener = null;
  queryTextfromInput: string = '';
  constructor(private renderer: Renderer2) {
    this.clickListener = this.renderer.listen('window', 'click', (e: Event) => {
      /**
       * A listener to check if the click is outside of the custom drop down
       * it will close the drop down and save it.
       */
      if (
        this.customDropArea &&
        e.target !== this.customDropArea.nativeElement &&
        !this.customDropArea.nativeElement.contains(e.target)
      ) {
        if (this.isDropDownOpen) {
          this.openDrop((this.isDropDownOpen = !this.isDropDownOpen));
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Only check PreSelected Items for the first time, otherwise ignore this
    if (changes.preSelectedItems && !changes.preSelectedItems.firstChange) {
      return;
    }
    if (this.itemList && this.itemList.length) {
      this.cachedItemList = this.itemList = this.itemList.map(item => ({ ...item, selected: false }));
    }
    if (this.preSelectedItems && this.preSelectedItems.length) {
      this.selectedItems = [];
      this.itemList = this.itemList.map(item => {
        if (this.preSelectedItems.includes(item.name)) {
          item.selected = true;
          this.selectedItems.push(item);
        }
        return item;
      });
    }
  }

  ngOnInit(): void {
    const height = 3.25 * (this.itemList?.length - 1);
    this.fullHeightArea = height + 'vh';
    if (this.preSelectedItem !== '') {
      const index = this.itemList.indexOf(this.preSelectedItem);
      if (index > -1) {
        this.selectedItem = this.preSelectedItem;
        const cacheList = JSON.parse(JSON.stringify(this.itemList));
        const selected = cacheList.splice(index, 1);
        cacheList.sort().reverse();
        cacheList.unshift(selected[0]);
        setTimeout(() => {
          this.itemList = JSON.parse(JSON.stringify(cacheList));
        }, 0);
      }
    }
  }

  /**
   * Function to Open/Close Dropdown
   */
  toggleDropDown(): void {
    this.isDropDownOpen = !this.isDropDownOpen;
    this.toggleDropDownEvent.emit(this.isDropDownOpen);
  }

  /**
   * Function to search in the list
   */
  searchList(): void {
    this.itemList = this.cachedItemList.filter(item =>
      item[this.bindLabel].toLowerCase().includes(this.queryTextfromInput.toLowerCase())
    );
  }

  /**
   * Function to Check/Uncheck an item from dropdown
   * @param item - Current clicked item
   */
  selectItem(item: any): void {
    if (this.singleSelect) {
      this.selectedItems = [];
      this.cachedItemList = this.cachedItemList.map(elem => ({
        ...elem,
        selected: elem.id !== item.id ? false : !item.selected,
      }));
      this.itemList = this.itemList.map(elem => ({ ...elem, selected: elem.id !== item.id ? false : !item.selected }));
      item.selected = !item.selected;
      if (item.selected) {
        this.selectedItems.push(item);
      }
      this.toggleDropDown();
    } else {
      item.selected = !item.selected;
      if (item.selected) {
        this.selectedItems.push(item);
      } else {
        const index = this.selectedItems.indexOf(item);
        this.selectedItems.splice(index, 1);
      }
    }

    this.ngModelValueChange.emit(this.selectedItems);
  }

  /**
   * Uncheck item :: On Clicking cross icon
   * @param event - Current Element :: To stop event bubbling
   * @param item - Item that is bind to this cross button
   * @param index - Index of the current item from selected array
   */
  removeItem(event: any, item: any, index: number): void {
    item.selected = false;
    event.stopPropagation();
    this.selectedItems.splice(index, 1);
    this.ngModelValueChange.emit(this.selectedItems);
  }

  openDrop(modeView: boolean): void {
    this.mode = modeView ? 'charade' : 'ebony';
  }
  optionSelected(item: string, index: number): void {
    this.returnValue.emit(item);
    const cacheList = JSON.parse(JSON.stringify(this.itemList));
    const selected = cacheList.splice(index, 1);
    cacheList.sort().reverse();
    cacheList.unshift(selected[0]);
    this.openDrop((this.isDropDownOpen = !this.isDropDownOpen));
    setTimeout(() => {
      this.selectedItem = item;
      this.itemList = JSON.parse(JSON.stringify(cacheList));
    }, 400);
  }

  ngOnDestroy(): void {
    this.clickListener();
  }
}
