import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import FrameworkNode from 'models/framework-node.model';
import { ImpactTextEnum } from './enums/impact.enum';
import { AnswerEnum, AnswerEnumExtended, BnbAnswerEnum, FRAMEWORKS_ANSWERS } from './enums/answer.enum';
import { SortDirectionEnum } from './enums/sort-direction.enum';
import { parseDomain, fromUrl } from 'parse-domain';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ProbabilityEnum, ImpactEnum, RiskMapEnum } from 'app/shared/enums/risk-map.enums';
import {
  RoleEnum,
  GetEntityQuery,
  ImpactEnum as APIImpactEnum,
  RiskProbabilityEnum,
  RiskImpactEnum,
} from 'app/API.service';
import validator from 'validator';

@Injectable()
export class UtilsService {
  static get isDBU(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // https://dbu.cegrc.com/
    return subDomains.includes('dbu');
  }

  static get isMoelis(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // https://moelis.cegrc.com/
    return subDomains.includes('moelis');
  }

  static get isAdnoc(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // https://adnoc.cegrc.com/
    return subDomains.includes('adnoc');
  }

  static get isInstar(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // https://instar.cegrc.com/
    return subDomains.includes('instar');
  }

  static get isJTGlobal(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // jtglobal.cermp.com OR jtglobal.cygovdev.com
    return subDomains.includes('jtglobal');
  }

  static get isCyberArk(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { domain, subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // ca.cegrc.com OR cyberark.cygovdev.com OR ca-test.cermp.com
    return (
      (subDomains.includes('ca') && domain === 'cegrc') ||
      subDomains.includes('cyberark') ||
      subDomains.includes('ca-test')
    );
  }

  static get isENBD(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { domain, subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // daman.enbdcscm.com OR emiratesnbd.cermp.com OR emiratesnbd.cygovdev.com
    return (subDomains.includes('daman') && domain === 'enbdcscm') || subDomains.includes('emiratesnbd');
  }

  static get isOklahomaUniversity(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.includes('ouhsc') || subDomains.includes('ou');
  }

  static get isSSMHealth(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.includes('ssmhealth');
  }

  static get isWssu(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.includes('wssu');
  }

  static get isHowden(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.includes('howden');
  }

  static get isInsuranceApp(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // insurance.cegrc.com
    return subDomains.includes('insurance');
  }

  static get isBnBCyberSite(): boolean {
    // isBeecher
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // cyber-in-site.cygovdev.com OR cyber-in-site.bbrown.com OR test-cyber-in-site.bbrown.com OR insurance.cegrc.com
    return (
      subDomains.includes('test-cyber-in-site') ||
      subDomains.includes('cyber-in-site') ||
      subDomains.includes('insurance')
    );
  }

  static get isInformatica(): boolean {
    // isInformatica
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // informatica.cegrc.com OR informatica.cygovdev.com/login
    return subDomains.includes('informatica');
  }

  static get isInformaticaUat(): boolean {
    // isInformaticaUat
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // informatica-uat.cegrc.com
    return subDomains.includes('informatica-uat');
  }

  static get isBnB(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { domain, subDomains } = parseDomain(fromUrl(window.location.origin)) as any;
    // cyber-test.bbins.com, cyber.bbins.com, bbins.cygovdev.com, bbins2.cygovdev.com
    return domain === 'bbins' || subDomains.includes('bbins') || subDomains.includes('bbins2');
  }

  static get isMidMarket(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    // midmarket.cegrc.com OR midmarket.cygovdev.com OR cyberapp.bbrown.com (prod)
    return subDomains.includes('midmarket') || subDomains.includes('cyberapp');
  }

  static get isCRB(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.length ? subDomains.includes('crb') || subDomains.includes('crossriver') : false;
  }

  static get isCNM(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.length ? subDomains.includes('cnm') : false;
  }

  static get isNetskope(): boolean {
    // Change it to FALSE for normal app flow and TRUE for Netskope feature Mode.
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.length ? subDomains.includes('netskope') : false;
  }

  static get isITC(): boolean {
    // Change it to FALSE for normal app flow and TRUE for Netskope feature Mode.
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains, domain } = parseDomain(fromUrl(window.location.origin)) as any;

    return (
      domain === 'navigatordevitcsecure' ||
      domain === 'navigatoritcsecure' ||
      subDomains.includes('testuk') ||
      subDomains.includes('stageuk') ||
      subDomains.includes('itc-demo') ||
      (domain === 'cygovdev' && subDomains.includes('itc'))
    );
  }

  static get isLiveDomain(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;

    return subDomains.includes('live') || subDomains.includes('test');
  }

  static get isDevDomain(): boolean {
    if (window.location.origin.includes('localhost')) {
      return true;
    }
    const { domain } = parseDomain(fromUrl(window.location.origin)) as any;

    return domain === 'cygovdev';
  }

  static get isQA(): boolean {
    if (window.location.origin.includes('localhost')) {
      return false;
    }

    return window.location.hostname === 'qa.cygovdev.com';
  }

  static get companyConfig(): any {
    const defaultData = {
      title: 'Centraleyes',
      ogTitle: 'Centraleyes',
      ogDescription: 'Centraleyes | Risk Management Reimagined',
      ogImage: './assets/centraleyes-logo.png',
      favicon: './assets/images/favicon-310.png',
      mainLogoName: 'centraleyes-logo',
    };

    const BnBData = { ...defaultData, mainLogoName: 'ce-part-logo' };
    const BnBinSiteData = { ...defaultData, mainLogoName: 'cyber-in-site-logo' };
    const howdenData = { ...defaultData, mainLogoName: 'howden-logo' };
    const SSMHealthData = { ...defaultData, mainLogoName: 'SSM-Health' };
    const instarData = { ...defaultData, mainLogoName: 'instar-logo' };
    const midMarketData = { ...defaultData, title: 'Cyber app', mainLogoName: 'cyber-in-site-logo' };

    const ITCData = {
      title: 'ITC | Navigator',
      ogTitle: 'ITC | Navigator',
      ogDescription: 'ITC | Navigator',
      ogImage: './assets/images/ITC_logo.png',
      favicon: './assets/images/ITC_logo.png',
      mainLogoName: 'ITC_logo',
    };

    switch (true) {
      case UtilsService.isITC:
        return ITCData;
      case UtilsService.isBnB:
        return BnBData;
      case UtilsService.isBnBCyberSite && !UtilsService.isInsuranceApp:
        return BnBinSiteData;
      case UtilsService.isMidMarket:
        return midMarketData;
      case UtilsService.isHowden:
        return howdenData;
      case UtilsService.isSSMHealth:
        return SSMHealthData;
      case UtilsService.isInstar:
        return instarData;
      default:
        return defaultData;
    }
  }

  static get isUSBank(): boolean {
    if (window.location.origin.includes('localhost')) {
      return true;
    }
    const { subDomains } = parseDomain(fromUrl(window.location.origin)) as any;
    // https://usb.cermp.com/
    return subDomains.includes('usb');
  }

  static getStringEnum(enumnum) {
    const fields = [];
    Object.keys(enumnum).forEach(key => {
      if (key !== key.toUpperCase()) {
        fields.push(key);
      }
    });
    return fields;
  }

  // help function to get an array instead of tree
  static getArrayFromNestedObject(object: any) {
    const arr = [];
    const traverseSurvey = sheet => {
      Object.keys(sheet).forEach(key => {
        if (sheet[key].answers) {
          arr.push(sheet[key]);
        } else {
          traverseSurvey(sheet[key]);
        }
      });
    };

    traverseSurvey(object);
    return arr;
  }

  static isHebrew(str): boolean {
    return str.match(/[\u0590-\u05FF]+/g);
  }

  static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static isIdFake(id: string): boolean {
    return id.length === 37 ? true : false;
  }

  static isDefined(variable: any): boolean {
    return !(typeof variable === 'undefined' || variable === null);
  }

  static isEmpty(variable: string): boolean {
    return !this.isDefined(variable) || variable === '' || variable === 'null';
  }

  static averageOfProp<T>(arr: T[], prop: string): number {
    const average = arr.reduce((acc, curr) => acc + curr[prop], 0) / arr.length;
    return average;
  }
  /**
   *
   * @param email string type email address.
   * @returns true if email is in valid format.
   */
  static isValidEmail(email: string): boolean {
    const regexForEmail = /^(\d{10}|\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3}))$/;
    const isValidEmail = regexForEmail.test(email);
    return isValidEmail;
  }

  /**
   * Returns a random number between min (inclusive) and max (exclusive)
   */
  static getRandomNumber(min: number, max: number, toFixed: number): number {
    let randomNum: any = Math.random() * (max - min) + min;
    if (!isNaN(toFixed)) {
      randomNum = randomNum.toFixed(toFixed);
    }
    return Number(randomNum);
  }

  static getRandomElement(arr: any[]) {
    const index = UtilsService.getRandomNumber(0, arr.length - 1, 0);
    return arr[index];
  }

  static getRandomBoolean() {
    return UtilsService.getRandomElement([true, false]);
  }

  /**
   * Sort array by property.
   * @param array
   * @param prop: string
   * @return sorted array
   */
  static sortByProp<T>(arr: T[], prop: string, direction: SortDirectionEnum = SortDirectionEnum.ASCENDING): T[] {
    return arr?.slice()?.sort((a, b) => {
      if (direction === SortDirectionEnum.ASCENDING) {
        return a[prop] < b[prop] ? -1 : 1;
      } else {
        return a[prop] < b[prop] ? 1 : -1;
      }
    });
  }
  /**
   * Sort array by date property.
   * @param array
   * @param prop: string
   * @return sorted array
   */
  static sortByDate<T>(arr: T[], prop: string, direction: SortDirectionEnum = SortDirectionEnum.ASCENDING): T[] {
    return arr.sort((a, b) => {
      if (direction === SortDirectionEnum.ASCENDING) {
        return new Date(a[prop]).getTime() - new Date(b[prop]).getTime();
      } else {
        return new Date(b[prop]).getTime() - new Date(a[prop]).getTime();
      }
    });
  }

  /**
   * Group array to object by property.
   * @param array
   * @param prop: string
   * @return grouped object
   */
  static groupBy<T>(arr: T[], prop: string): Map<string, T[]> {
    const initialMap: Map<string, T[]> = new Map();
    return arr.reduce((memo, x) => {
      if (!memo[x[prop]]) {
        memo[x[prop]] = [];
      }
      memo[x[prop]].push(x);
      return memo;
    }, initialMap);
  }

  /**
   * Create a map of all items of array with an attribute as key
   *
   * @param arr array
   * @param prop attribute
   */
  static mapBy<T>(arr: T[], prop: string): Map<string, T> {
    const initialMap: Map<string, T> = new Map();
    return arr.reduce((map, curr) => {
      map[curr[prop]] = curr;
      return map;
    }, initialMap);
  }

  static copyArrayDeep<T>(arr: T[]): T[] {
    return JSON.parse(JSON.stringify(arr));
  }

  static getUniqueListBy<T>(arr: T[], key: string): T[] {
    return [...new Map(arr.map(item => [item[key].toLowerCase(), item])).values()];
  }

  /**
   * generate the object from entries
   * Temporary until Microsoft doesn't support ES2019 in TS
   * The source code: http://2ality.com/2019/01/object-from-entries.html
   * @param iterable list of pairs key-value
   */
  static fromEntries(iterable): { [propName: string]: string } {
    const result = {};
    for (const [key, value] of iterable) {
      let coercedKey;
      if (typeof key === 'string' || typeof key === 'symbol') {
        coercedKey = key;
      } else {
        coercedKey = String(key);
      }
      Object.defineProperty(result, coercedKey, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
    return result;
  }

  static stopBubbling(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  static getRouteParam(child: ActivatedRouteSnapshot, key: string) {
    if (child) {
      if (child.params[key]) {
        return child.params[key];
      } else if (child.queryParams[key]) {
        return child.queryParams[key];
      } else if (child.fragment) {
        // parse url starting from #
        const splitFragment = child.fragment.split(key);
        if (splitFragment && splitFragment.length >= 2) {
          return splitFragment[1].substring(1);
        }
        return null;
      } else {
        return this.getRouteParam(child.firstChild, key);
      }
    } else {
      return null;
    }
  }

  static msgFromError(e): string {
    let message = 'Internal Server Error';
    if (typeof e === 'string') {
      message = e;
    } else if (e.errors) {
      message = `Errors: \n ${e.errors.map(error => error.message).join('\n')}`;
    } else if (e.status === 0) {
      message = 'Server down. Please connect support.';
    } else if (e.message.indexOf('dialCode') !== -1) {
      message = 'Enter valid country code';
    } else if (!e.message.includes('Internal Server Error')) {
      message = 'Invalid credentials. Kindly login again!';
    }
    return message;
  }

  static getShortDate(date: Date, isUTC = false) {
    const shortDate = isUTC
      ? date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
      : date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    return shortDate;
  }

  static convertDate(date: Date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return (
      (month < 10 ? '0' + month : month.toString()) +
      '/' +
      (day < 10 ? '0' + day : day.toString()) +
      '/' +
      (year < 10 ? '0' + year : year.toString())
    );
  }

  //* converts to DD/MM/YY
  static convertToCustomDateSlashFormat(isoDate: string): string {
    const date = new Date(isoDate);

    // Get day, month, and year
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getUTCFullYear();

    // Format as DD/MM/YYYY
    return `${day}/${month}/${year}`;
  }

  static sum(array, key): number {
    return array.reduce((a, b) => a + (b[key] || 0), 0);
  }

  static filterOutNullElements = node => node != null;

  static filterOutUndefinedElements = node => node !== undefined;

  static calculateNodesScoreSum = (prev, current) => {
    if (prev && current) {
      prev.key = current.key;
      prev.name = current.name;
      prev.scores.total += current.scores?.total ? current.scores.total : 0;
      prev.scores.target += current.scores.target ? current.scores.target : 0;
      prev.scores.collection += current.scores.collection ? current.scores.collection : 0;
      return prev;
    }
  };

  static buildFlatList = (prev, current) => {
    return prev.concat(...current);
  };

  static buildUniqueNamesList = (prev, current) => {
    return prev.indexOf(current?.name) === -1 ? prev.concat(current?.name) : prev;
  };

  static buildFrameworksList = async (frameworks: any[]): Promise<FrameworkNode[]> => {
    let frameworkList: FrameworkNode[] = [];
    if (frameworks && frameworks.length > 0) {
      // Create list for all unique keys of sub nodes i.e identity, recover
      const frameworkNames = frameworks.reduce(UtilsService.buildUniqueNamesList, []);

      // From the flat array of sub nodes, calculate average for scores
      const promises = frameworkNames.map(calculateFrameworkAverageScore, {
        frameworkList: frameworks,
      });
      frameworkList = await Promise.all(promises);
    }
    return frameworkList;
  };

  static detailedAnswerNumToEnum(value: number): string {
    if (!this.isDefined(value) || value === -1) {
      return 'Not Specified';
    }
    if (value === 0) {
      return AnswerEnum.NOT_APPLICABLE;
    } else if (value > 9) {
      return AnswerEnum.YES;
    } else if (value > 7.5) {
      return `${AnswerEnum.PARTIAL} 9`;
    } else if (value > 5) {
      return `${AnswerEnum.PARTIAL} 7.5`;
    } else if (value > 2.5) {
      return `${AnswerEnum.PARTIAL} 5`;
    } else if (value > 1) {
      return `${AnswerEnum.PARTIAL} 2.5`;
    } else {
      return AnswerEnum.NO;
    }
  }

  static answerNumToEnum(value: number): AnswerEnum {
    if (!this.isDefined(value) || value === -1) {
      return null;
    }
    if (value === 0) {
      return AnswerEnum.NOT_APPLICABLE;
    } else if (value > 9) {
      return AnswerEnum.YES;
    } else if (value > 1 && value <= 9) {
      return AnswerEnum.PARTIAL;
    } else {
      return AnswerEnum.NO;
    }
  }

  static getScoreOrKey(value, objectData, partialScore = null) {
    if (typeof partialScore !== null && typeof value === 'string' && value.toUpperCase().includes(AnswerEnum.PARTIAL)) {
      switch (value) {
        case AnswerEnumExtended.PARTIAL_25:
        case AnswerEnumExtended.PARTIAL_50:
        case AnswerEnumExtended.PARTIAL_75:
        case AnswerEnumExtended.PARTIAL_90:
          return objectData?.PARTIAL?.extended?.[value]?.score;
        default:
          return partialScore;
      }
    }

    const keys = Object.keys(objectData);

    const foundByName = keys.find(
      key =>
        typeof value !== 'number' &&
        objectData[key].name.toUpperCase().split(' ').join('_') === value?.toUpperCase().split(' ').join('_')
    );
    if (foundByName) {
      return objectData[foundByName].score;
    }

    if (typeof value === 'number') {
      const foundByScore = keys.find(key => objectData[key].score === UtilsService.answerValueToStandardValue(value));
      if (foundByScore) {
        return objectData[foundByScore].name;
      }

      if (objectData.PARTIAL && objectData.PARTIAL.extended) {
        const partialKeys = Object.keys(objectData.PARTIAL.extended);
        const foundByPartialScore = partialKeys.find(
          key => objectData.PARTIAL.extended[key].score === UtilsService.answerValueToStandardValue(value)
        );
        if (foundByPartialScore) {
          return 'PARTIAL';
        }
      }
    }

    return null;
  }

  /**
   * Convert Answer's average value to standard values
   * In case of single question we get the average of answers.
   * Assumption: For value greater than 1 and less than 2.5 we are considering it as 2.5.
   * @param value average answers value
   * @return {number} standarded answers value
   */
  static answerValueToStandardValue(value: number): number {
    if (value > 0 && value <= 1) {
      return 1;
    } else if (value > 1 && value < 4) {
      return 2.5;
    } else if (value >= 4 && value < 6) {
      return 5;
    } else if (value >= 6 && value < 9) {
      return 7.5;
    } else if (value >= 9 && value < 10) {
      return 9;
    } else if (value >= 10) {
      return 10;
    } else {
      return value;
    }
  }

  static answerNumToEnumNew(value: number): AnswerEnum {
    if (!this.isDefined(value) || value === -1) {
      return null;
    }
    if (value === 0) {
      return AnswerEnum.NOT_APPLICABLE;
    } else if (value > 9) {
      return AnswerEnum.YES;
    } else if (value > 1 && value <= 9) {
      return AnswerEnum.PARTIAL;
    } else {
      return AnswerEnum.NO;
    }
  }

  /**
   * Function to get answers in from of options for BNB case
   * @param value - score
   * @returns - The option against given value
   */
  static bnbAnswerNumToEnum(value: number): BnbAnswerEnum {
    if (!this.isDefined(value) || value === -1) {
      return null;
    }
    if (value >= 0 && value <= 1) {
      return BnbAnswerEnum.NO;
    } else if (value === 2 || value === 2.5 || value === 5 || value === 7.5 || value === 9) {
      return BnbAnswerEnum.PARTIAL;
    } else if (value === 4) {
      return BnbAnswerEnum.YES_NOT_DOCUMENTED;
    } else if (value === 6) {
      return BnbAnswerEnum.YES_DOCUMENTED_1;
    } else if (value === 8) {
      return BnbAnswerEnum.YES_DOCUMENTED_2;
    } else if (value === 10) {
      return BnbAnswerEnum.YES_DOCUMENTED_3;
    }
  }

  /**
   * Function to map old and new BNB answers
   * @param value = selected option
   * @returns - possible score against that option
   */
  static bnbAnswersRangeForEnum(value: string): number[] {
    if (value.toLowerCase().includes('yes')) {
      return [4, 6, 8, 10];
    } else if (value.toLowerCase().includes('no')) {
      return [0, 1];
    } else if (value.toLowerCase().includes('partial')) {
      return [2, 2.5, 5, 7.5, 9];
    } else {
      return [];
    }
  }

  /**
   * Function to get the score against the given option selected for BNB
   * @param value - option selected
   * @returns - score against that option
   */
  static bnbAnswerEnumToNum(value: string): number {
    switch (value) {
      case BnbAnswerEnum.NO:
        return 0;
      case BnbAnswerEnum.PARTIAL:
        return 2;
      case BnbAnswerEnum.YES_NOT_DOCUMENTED:
        return 4;
      case BnbAnswerEnum.YES_DOCUMENTED_1:
        return 6;
      case BnbAnswerEnum.YES_DOCUMENTED_2:
        return 8;
      case BnbAnswerEnum.YES_DOCUMENTED_3:
        return 10;
    }
  }

  static answerEnumToNum(value: string, partialValue?: number): number {
    value = value?.split(' ').join('_');
    switch (value) {
      case AnswerEnum.NOT_APPLICABLE:
        return 0;
      case AnswerEnum.YES:
        return 10;
      case AnswerEnum.PARTIAL:
        return this.partialAnswersMapping(partialValue);
      case AnswerEnumExtended.PARTIAL_25:
        return 2.5;
      case AnswerEnumExtended.PARTIAL_50:
        return 5;
      case AnswerEnumExtended.PARTIAL_75:
        return 7.5;
      case AnswerEnumExtended.PARTIAL_90:
        return 9;
      case AnswerEnum.NO:
        return 1;
    }
  }

  static removeStringAfterHash(inputString) {
    const index = inputString.indexOf('#');
    if (index !== -1) {
      return inputString.substring(0, index);
    }
    return inputString;
  }

  static getFrameworkAnswers(frameworkName: string): any {
    let keyName = null;
    const keys = Object.keys(FRAMEWORKS_ANSWERS);
    const name = this.removeStringAfterHash(frameworkName);
    for (const key of keys) {
      if (key === name) {
        keyName = key;
      }
    }
    if (keyName) {
      return { ...FRAMEWORKS_ANSWERS[keyName] };
    }
    if (UtilsService.isBnB) {
      return { ...FRAMEWORKS_ANSWERS.BNB_FRAMEWORKS };
    }
    if (UtilsService.isBnBCyberSite || UtilsService.isMidMarket) {
      const frameworkAnsewers = { ...FRAMEWORKS_ANSWERS.OTHERS_FRAMEWORKS };
      delete frameworkAnsewers.PARTIAL;
      return frameworkAnsewers;
    }
    return { ...FRAMEWORKS_ANSWERS.OTHERS_FRAMEWORKS };
  }

  static formatAnswerValue(answer): string {
    if (answer) {
      return answer.toUpperCase().split(' ').join('_');
    }
  }

  static assignObjectArrayProperty(prev, current, property): void {
    if (prev[property]) {
      prev[property].push(...current[property]);
      prev[property] = [...new Set(prev[property])];
    } else {
      prev[property] = current[property];
    }
  }

  static initImpact(question): string {
    return question.impact ? question.impact : ImpactTextEnum.LOW;
  }
  static getIndexFromArray(questions, id: string): number {
    return questions.findIndex(question => {
      return question.id === id;
    });
  }

  static checkNewEntity(newSubList, existingSubList): GetEntityQuery {
    if (UtilsService.isDefined(newSubList)) {
      if (UtilsService.isDefined(existingSubList)) {
        if (existingSubList.length < newSubList.length) {
          const newlyCreatedEntity = newSubList.filter(
            ({ name: newSubName }) =>
              !existingSubList.some(({ name: existingSubName }) => existingSubName === newSubName)
          );

          return this.isDefined(newlyCreatedEntity) && newlyCreatedEntity.length !== 0 ? newlyCreatedEntity[0] : null;
        }
      }
    }
    return null;
  }
  static getCurrentDatePlus60Days(): NgbDateStruct {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);

    futureDate.setDate(currentDate.getDate() + 60); // Add 60 days to the current date

    return {
      year: futureDate.getFullYear(),
      month: futureDate.getMonth() + 1,
      day: futureDate.getDate(),
    };
  }
  static getDateInNgbDateStructFormat(date): NgbDateStruct {
    if (typeof date === 'string' || typeof date === 'number') {
      date = new Date(date);
      return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    } else if (typeof date === 'object') {
      return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      };
    } else {
      return {
        day: 0,
        month: 0,
        year: 0,
      };
    }
  }
  static formatDateFromNgbDateStruct(dateStruct: NgbDateStruct): NgbDateStruct {
    const day = dateStruct.day;
    const month = dateStruct.month;
    const year = dateStruct.year % 100;

    return {
      day,
      month,
      year,
    };
  }
  static ngbDateStructToDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  // Convert date string in DD.MM.YY format to Date object
  static parseDateString(dateString: string): Date {
    const [day, month, year] = dateString.split('.').map(Number);
    const fullYear = year < 100 ? year + 2000 : year;
    return new Date(fullYear, month - 1, day); // Month is 0-indexed
  }

  // Add days to a date string in DD.MM.YY format and return the new date in DD.MM.YY format
  static addDaysToDate(dateString: string, daysToAdd: number): string {
    const date = this.parseDateString(dateString);
    date.setDate(date.getDate() + daysToAdd);
    const newDay = String(date.getDate()).padStart(2, '0');
    const newMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const newYear = String(date.getFullYear()).slice(-2);
    return `${newDay}.${newMonth}.${newYear}`;
  }
  static addDaysToDateInt(nextStartingDate, numberOfDays) {
    // Destructure the day, month, and year from the nextStartingDate object
    const { day, month, year } = nextStartingDate;

    // Create a Date object from the provided day, month, and year
    const date = new Date(year + 2000, month - 1, day); // Assuming the year is 20xx

    // Add the specified number of days
    date.setDate(date.getDate() + numberOfDays);

    // Convert the date to an AWS timestamp (Unix timestamp in milliseconds)
    const nextReviewDate = date.getTime();

    return nextReviewDate;
  }
  /**
   * This returns true if two object are equal(contains same keys)
   * @param o1 object 1
   * @param o2 object 2
   */
  static isObjectEqual(o1: any, o2: any) {
    for (const p in o1) {
      if (Object.prototype.hasOwnProperty.call(o1, p)) {
        if (o1[p] !== o2[p]) {
          return false;
        }
      }
    }
    for (const p in o2) {
      if (Object.prototype.hasOwnProperty.call(o2, p)) {
        if (o1[p] !== o2[p]) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * return days in a months
   * @param month month number
   * @param year year in number
   */
  static getMonthDays = (month: number, year: number): number => new Date(year, month, 0).getDate();

  static getDateFromNgbDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month, date.day);
  }
  static getDateFromYearMonthDate(year: number, month: number, day: number): Date {
    return new Date(year, month, day);
  }
  static findIndexBasedOnKey(data, key, value): number {
    return data.findIndex(element => element[key] === value);
  }

  static capitalizeText(text: string): string {
    return text
      .split(' ')
      .map(t => t.charAt(0).toUpperCase() + t.slice(1))
      .join(' ');
  }

  static getLabelValue(value: number, labelType: string): string {
    if (value <= 25) {
      return ProbabilityEnum.LOW;
    } else if (value <= 50) {
      return ProbabilityEnum.MEDIUM;
    } else if (value <= 75) {
      return ProbabilityEnum.HIGH;
    } else if (value <= 100) {
      if (labelType === RiskMapEnum.IMPACT.toLowerCase()) {
        return ImpactEnum.CRITICAL;
      } else {
        return ProbabilityEnum.CRITICAL;
      }
    }
    return '';
  }
  static getLabelValueNum(value: string): number {
    const val = value.toLowerCase();
    switch (val) {
      case 'low':
        return 2.5;
      case 'medium':
        return 5;
      case 'high':
        return 7.5;
      case 'critical':
        return 10;
      default:
        return 0;
    }
  }

  static getProbabilityEnumValue(value: number): RiskProbabilityEnum {
    switch (true) {
      case value <= 2.5:
        return RiskProbabilityEnum.LOW;
      case value <= 5:
        return RiskProbabilityEnum.MEDIUM;
      case value <= 7.5:
        return RiskProbabilityEnum.HIGH;
      case value <= 10:
        return RiskProbabilityEnum.CRITICAL;
    }
  }

  static getImpactEnumValue(value: number): RiskImpactEnum {
    switch (true) {
      case value <= 2.5:
        return RiskImpactEnum.LOW;
      case value <= 5:
        return RiskImpactEnum.MEDIUM;
      case value <= 7.5:
        return RiskImpactEnum.HIGH;
      case value <= 10:
        return RiskImpactEnum.CRITICAL;
    }
  }

  static getLabelValueBaseOnNum(num: number, seriesType = 1): string {
    switch (num) {
      case 2.5:
        return 'low';
      case 5:
        return 'medium';
      case 7.5:
        return 'high';
      case 10: {
        if (seriesType === 1) {
          return 'critical';
        }
        if (seriesType === 2) {
          return 'critical';
        }
        break;
      }
      default: {
        return '';
      }
    }
  }

  static getRoleMapped(role = 'ADMIN', allRoles = []) {
    const roleMapper = {
      ADMIN: [...allRoles],
      MSSP: [...allRoles].filter(i => i.toLowerCase() !== RoleEnum.ADMIN.toLowerCase()),
      'Entity Leader': [...allRoles].filter(
        i => i.toLowerCase() !== RoleEnum.ADMIN.toLowerCase() && i.toLowerCase() !== RoleEnum.MSSP.toLowerCase()
      ),
      'Sub Entity Leader': [...allRoles].filter(
        i =>
          i.toLowerCase() !== RoleEnum.ADMIN.toLowerCase() &&
          i.toLowerCase() !== RoleEnum.MSSP.toLowerCase() &&
          i.toLowerCase() !== 'entity leader'
      ),
      PARTICIPANT: [...allRoles].filter(i => i.toLowerCase() === RoleEnum.PARTICIPANT),
    };
    return roleMapper[role];
  }
  static getRandomEnumVal(enumList: any): any {
    const rand = Math.floor(Math.random() * Object.keys(enumList).length);
    return enumList[Object.keys(enumList)[rand]];
  }

  // filter uniq value from array
  static uniqueByKey(arr, key) {
    const flag = {};
    const unique = [];
    arr.forEach(elem => {
      if (!flag[elem[key]]) {
        flag[elem[key]] = true;
        unique.push(elem);
      }
    });
    console.log('Duplicates', flag);
    return unique;
  }

  /**
   *
   * @param pillarScore is type of number and contains the score of pillar
   * @param tierIndex  is the Index of tier and its values will be ( Tiers 1,2,3,4)
   * @returns type of number that contains the total calculated Score
   */
  static calculatePillarsScoreByTier(pillarScore: any, tierIndex: number): number {
    const dividerValuesPerLevel = [2.5, 5, 7.5, 10];
    const totalScore = pillarScore ? pillarScore.toFixed(1) : 0;
    const score =
      totalScore <= dividerValuesPerLevel[tierIndex - 1]
        ? +((totalScore / dividerValuesPerLevel[tierIndex - 1]) * 10).toFixed(1)
        : 10;
    return score;
  }

  static fixBoundaries(value: number): number {
    value = value < 0 ? 0 : value > 10 ? 10 : value;
    return value;
  }

  static calculateMaturityScore(total) {
    if (!this.isDefined(total)) {
      return 1;
    }
    total = +total.toFixed(1);
    const range = [
      //* not applicable answers have maturity of 0
      {
        range: '0.0',
        value: 0,
      },
      {
        range: '1.0',
        value: 1,
      },
      {
        range: '2.5',
        value: 2,
      },
      {
        range: '5.0',
        value: 3,
      },
      {
        range: '7.5',
        value: 4,
      },
      {
        range: '10',
        value: 5,
      },
    ];

    const maturity = range.find(item => total <= item.range);
    if (maturity) {
      return maturity.value;
    }
    return 1;
  }

  static partialAnswersMapping(value: number, sliderToVal = true): number {
    let sliderVal = 0;
    if (!sliderToVal) {
      // here we're handling values except 2.5, 5, 7.5 as well, because sometimes answer value
      // falls in between in the third party integrations case.
      if (value === 2.5 || (value > 2.5 && value < 4)) {
        sliderVal = 1;
      } else if (value === 5 || (value >= 4 && value < 6)) {
        sliderVal = 2;
      } else if (value === 7.5 || (value >= 6 && value < 9)) {
        sliderVal = 3;
      } else if (value === 9 || (value >= 9 && value < 10)) {
        sliderVal = 4;
      } else {
        sliderVal = -1;
      }
    } else {
      switch (value) {
        case 1:
          sliderVal = 2.5;
          break;
        case 2:
          sliderVal = 5;
          break;
        case 3:
          sliderVal = 7.5;
          break;
        case 4:
          sliderVal = 9;
          break;
        default:
          sliderVal = -1;
          break;
      }
    }
    return sliderVal;
  }
  /**
   * This is just a quick fix as the issue resides in Create Framework Lambda
   * We are storing Control Labels in Question Labels, this is only a fix for
   * the Hi-Trust Framework
   * @param orderNum The order number of the question
   * @returns Name of the label according to the question order number
   */
  static hiTrustLabelName(orderNum: number): string {
    let numName = orderNum % 10;
    numName = numName === 0 ? 10 : numName;
    numName = numName > 5 ? numName - 5 : numName;
    switch (numName) {
      case 5:
        return 'Policy';
      case 1:
        return 'Process';
      case 2:
        return 'Implemented';
      case 3:
        return 'Measured';
      case 4:
        return 'Managed';
      default:
        return 'Policy';
    }
  }

  // list should be array of value not objects
  static hasEmptyValue(list: any[]): boolean {
    return list.indexOf('') !== -1 || list.indexOf(null) !== -1 || list.indexOf(undefined) !== -1;
  }

  static toLowerCaseAll(list: string[]): string[] {
    return list.join(',').toLowerCase().split(',');
  }

  // removes empty spaces from string array
  static removeEmptySpacesFromList(list: string[]): string[] {
    return list.map(element => {
      return element.trim();
    });
  }

  static getTimeDifference(fromDate: any, toDate?: any): number {
    return (Math.abs(new Date(fromDate).getTime() - new Date(toDate).getTime()) / (1000 * 60)) % 60;
  }

  static getSanitizedDomain(dom: string): string {
    const { domain, topLevelDomains } = parseDomain(fromUrl(dom)) as any;
    let sanitizedDomain = '';
    if (topLevelDomains?.length) {
      sanitizedDomain = `${domain}.${topLevelDomains.join('.')}`;
    } else {
      sanitizedDomain = `${domain}.${topLevelDomains}`;
    }
    return sanitizedDomain;
  }

  // validating domains
  static validateDomain(domain: string): boolean {
    let isDomainValid = true;
    const sanitizedDomain = this.getSanitizedDomain(domain);
    if (sanitizedDomain.includes('undefined') || !validator.isURL(domain, { require_valid_protocol: false })) {
      isDomainValid = false;
    }
    return isDomainValid;
  }

  static removeSpecialCharactersFromString(item: string): string {
    let res = '';
    // eslint-disable-next-line no-useless-escape
    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    for (let i = 0; i < item.length; i++) {
      if (!regex.test(item.charAt(i))) {
        res = res.concat(item.charAt(i));
      }
    }
    return res;
  }

  static getImpactEnum(value: number): APIImpactEnum {
    if (value >= 0 && value < 25) {
      return APIImpactEnum.LOW;
    } else if (value >= 25 && value < 50) {
      return APIImpactEnum.MEDIUM;
    } else if (value >= 50 && value < 75) {
      return APIImpactEnum.HIGH;
    } else if (value >= 75 && value <= 100) {
      return APIImpactEnum.CRITICAL;
    }
    return null;
  }

  static noSpacesTest(str: string): boolean {
    const noSpaces = RegExp(/^[\s]*$/); // regular expression to check for consecutive empty spaces
    return noSpaces.test(str);
  }

  static dateIsValid(d: string): boolean {
    const date = new Date(d);
    return date instanceof Date && !isNaN(date.getTime());
  }

  static getEnumKey(enumClass: any, value: string | number): string {
    return Object.keys(enumClass)[Object.values(enumClass).indexOf(value)];
  }
  /**
   *  calculate the score and avg out.
    @param {} data1 an object that contains {isActive:boolean , score:float ,weight:Int }
    @param {} data2 an object that contains {isActive:boolean , score:float ,weight:Int}
   * @returns
   */
  static calculateWeightedAvg(data1: any, data2: any): number {
    try {
      if (data1 && data2) {
        let totalAccumulative = 0;
        let accumulativeSum1 = data1.isActive ? data1.score : 0;
        let accumulativeSum2 = data2.isActive ? data2.score : 0;
        let divider = (data1.isActive ? 1 : 0) + (data2.isActive ? 1 : 0);
        // Weights are only applied when we have
        if (data1?.isActive && data2?.isActive && data1?.weight && data2?.weight) {
          accumulativeSum1 = accumulativeSum1 * data1?.weight;
          accumulativeSum2 = accumulativeSum2 * data2?.weight;
          divider = 100;
        }
        totalAccumulative = accumulativeSum1 + accumulativeSum2;
        return divider > 0 ? totalAccumulative / divider : 10;
      }
    } catch (e) {
      console.log('Error : ', e);
    }
    return 0;
  }

  /**
   * For BNB only - converts the total
   * score to be calculated out of 5
   */
  static totalScoreOf5(score): any {
    if (!UtilsService.isDefined(score)) {
      return (0.0).toFixed(1);
    }

    let numb = parseFloat(score);
    const isString = typeof score === 'string';
    if (UtilsService.isBnB) {
      numb = numb / 2;
    }
    return isString ? numb.toFixed(1) : numb === 0 ? (0.0).toFixed(1) : parseFloat(numb.toFixed(1));
  }

  //* function converts NA filter to N/A used in remediation filters
  static updateNAFilter(arr: any): any {
    return arr.map(value => (value === 'NA' ? 'N/A' : value));
  }
  static getFullFrameworkKey(frameKey: string, level: string): string {
    const treeName = frameKey.split(' ').join('_') + '_' + level.split(' ').join('_');
    return treeName;
  }

  /**
   * *Function splits and returns framework name
   * @param frameworkName framework name
   * @returns framework name without hash #
   */
  static extractFrameworkName(frameworkName: string): string | null {
    if (!frameworkName) {
      return null;
    }

    const [name] = frameworkName.split('#');
    return name || null;
  }
}

export function calculateFrameworkAverageScore(subNodeName: string): FrameworkNode {
  const subNodes = this.frameworkList.filter(subNode => subNode?.name === subNodeName);
  const singleNode: FrameworkNode = subNodes.reduce(UtilsService.calculateNodesScoreSum, {
    key: '',
    name: '',
    scores: { total: 0.0, target: 0.0, collection: 0.0 },
  });
  if (singleNode) {
    singleNode.scores.total = singleNode.scores.total / subNodes.length;
    singleNode.scores.target = singleNode.scores.target / subNodes.length;
    singleNode.scores.collection = singleNode.scores.collection / subNodes.length;
    return singleNode;
  }
}
