<div class="calendar-form-wrapper"
  [ngClass]="{ 'disabled': isCalendarDisabled && !disableFullOpacity, 'disable-full-opacity': disableFullOpacity }">
  <span *ngIf="dontDisplayInputArea && showDateLabel" class="due-date">
    {{ selectedDate ? (getDateinDateFormat(selectedDate) | date) : placeHolder }}
  </span>
  <form class="form-inline calendar-form"
    [ngClass]="{ 'w-100': toggleButton && !dontDisplayInputArea }">
    <div class="form-group" [ngClass]="{'w-100': toggleButton}">
      <div class="input-group start-date timeline-datepicker-width"
        [ngClass]="{'show-navigation':toggleButton || showCustomNavigation, 'w-100' : toggleButton}">
        <span *ngIf="!toggleButton" class="selected-date lato-14-n-vw">
          <span *ngIf="displayName">{{displayName }}:</span>
          <span class="date">
            {{ dateToShow ? (getDateinDateFormat(dateToShow) | date:'MMM yyyy' | uppercase): '' }}
          </span>
          <span *ngIf="showCustomNavigation" class="select-year-month">
            <cygov-select [clearable]="false" class="month" [items]="monthsList"
              (ngModelValueChange)="customNavigateCalendar($event,true)"
              [placeholder]="currentSelectedMonth" [customArrow]="true"
              [(ngModelValue)]="currentSelectedMonth" [searchable]='false'
              [disabled]="disableCustomNavigation">
            </cygov-select>


            <cygov-select class="year" [clearable]="false" [items]="yearsList"
              (ngModelValueChange)="customNavigateCalendar($event,false)"
              (ngModelValueChange)="currentSelectedYear=$event" [placeholder]="currentSelectedYear"
              [(ngModelValue)]="currentSelectedYear" [customArrow]="true" [searchable]='false'
              [disabled]="disableCustomNavigation">
            </cygov-select>
          </span>
        </span>


        <input class="form-control"
          [ngClass]="{'display-input-area': toggleButton && !dontDisplayInputArea }" type="text"
          id="{{dateType}}" name="{{dateType}}" [dayTemplate]="iniDateCustomDay"
          [disabled]="isCalendarDisabled || disableFullOpacity" placeholder="{{placeHolder}}"
          (dateSelect)="dateUpdated($event)" ngbDatepicker #dateInput="ngbDatepicker"
          [outsideDays]="'hidden'" [autoClose]="toggleButton" [(ngModel)]="selectedDate"
          [minDate]="inputMinDate" [maxDate]="inputMaxDate"
          (navigate)="calendarNavigateTrigger($event, dateToShow)" [readonly]="isReadOnly"
          [navigation]="navigation" [firstDayOfWeek]="firstDayOfWeek" />
        <div *ngIf="toggleButton" class="input-group-append" (click)="dateInput.toggle()">
          <button class="btn btn-outline-secondary calendar" type="button"></button>
        </div>
      </div>
    </div>
  </form>
  <ng-template #iniDateCustomDay let-date let-currentMonth="currentMonth" let-selected="selected"
    let-disabled="disabled" let-focused="focused">
    <div [ngClass]="{'blue-text': isDataFound(date)}" class="custom-day" [class.focused]=" focused"
      [class.bg-primary]=" selected" [ngStyle]="getDivStyles(date.day)"
      [class.text-muted]="disabled">
      {{ date.day }}
    </div>
  </ng-template>
</div>