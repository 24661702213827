<div class="main-body-freq" [ngClass]="{'informatica-main-body' : isInformatica}">
    <div class="upper-section">

        <!-------------------------------------RISK FRAMEWORK SELECTION AREA------------------------------------->
        <div class="left-section">
            <div class="title-name">
                Framework
            </div>
            <div class="framework-listings">
                <ng-container *ngFor="let risk of riskList;let idx=index">
                    <div class="risk-container lato-14-n-vw"
                        [ngClass]="{'selected-container' : assessmentData.frameworkName === risk, 'disable-risksca': (risk !== 'VENDOR RISK ENBD' && isBulkUpload && isENBD)}"
                        (click)="updateAssessmentData('framework', risk,true)">
                        <div class="framework-name-text text-ellipsis" ngbTooltip="{{ risk }}">
                            {{risk}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <!----------------------------------------LEVEL SELECTION AREA------------------------------------------>
        <div *ngIf="!isENBD || !isEnbdVendorRisk" class="right-section">
            <div class="title-name">
                Level
            </div>
            <div [ngClass]="isInformatica?'informatica-option-listings':'level-option-listings'"
                 class="level-option-listings">
                <div  [ngClass]="levelOptions.length === 1?'disable-option':''"  class="round-checkbox" *ngFor="let level of levelOptions;let idz=index">
                    <input [id]="'label-' + level" type="radio" [name]="'label-' + level" [value]="level"
                        [checked]="assessmentData?.level?.toLowerCase() === level?.toLowerCase()" #surveyRadio
                        (change)="updateAssessmentData('level', level)" class="jarl-radio" />
                    <label [for]="'label-' + level" class="lato-14-n-vw label target">{{level}}</label>
                </div>
                <div class="lato-12-b-vw" style="color: #c5c9d1;" *ngIf="!levelOptions?.length">please select any
                    framework</div>
            </div>
        </div>
        <div *ngIf="isENBD && isEnbdVendorRisk" class="enbd-right-section">
            <div class="domain-header">
                <div class="title-name">
                    Domains
                </div>
                <div class="outer-circle" (click)="openDomainPopUp()">
                    <div class="calculator-svg lato-16-n-vw" [ngbTooltip]="'Advanced'"
                        tooltipClass="related-assets-tooltip" placement="top" cygovSvgIcon [svgName]="'mini-plus'">
                    </div>
                </div>
            </div>
            <div class="horizontal-seperator"></div>
            <div class="category-parent">
                <div *ngFor="let domain of enbdDomainList" class="category-list">
                    <!-- Using domain in case data is comming from DB and at that time we don't have an array ob objects but
 just an array of strings -->
                    <div class="single-category lato-14-n-vw">{{domain?.name ? domain.name :
                        domain}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="horiz-separator"></div>
    <div class="lower-section">

        <!----------------------------------------CALENDAR DATE SELECTION AREA------------------------------------------>
        <div class="left-section" [ngClass]="{'left-section-informatica' : isInformatica}">

            <div class="title-name">
                Duration
            </div>
            <div class="calenders">
                <div class="start-calender">
                    <cygov-ngb-calendar [(selectedDate)]="selectedStartDate" [displayName]="'Start Date'"
                        [dateToShow]="selectedStartDate" (selectedDateChange)="dateChanged($event, ASSESSMENT_DATE)"
                        [isFromVendor]="true" [dateType]="'startDate'" [toggleButton]="false" [saveSelectedDate]="true"
                        [openDefault]="true" (calendarRef)="this.startCalendar = $event" [inputMinDate]="tempStartDate"
                        [inputMaxDate]="selectedEndDate">
                    </cygov-ngb-calendar>
                </div>
                <div class="end-calender">
                    <cygov-ngb-calendar [(selectedDate)]="selectedEndDate" [displayName]="'End Date'"
                        [dateToShow]="selectedEndDate" (selectedDateChange)="dateChanged($event, DEADLINE_DATE)"
                        [inputMinDate]="selectedStartDate" [dateType]="'endDate'" [toggleButton]="false"
                        [openDefault]="true" (calendarRef)="this.endCalendar = $event" [openDefault]="true">
                    </cygov-ngb-calendar>
                </div>
            </div>
        </div>


        <!----------------------------------------FREQUENCY SELECTION AREA------------------------------------------>
        <div class="right-section" [ngClass]="{'informatica-right-section': isInformatica}">
            <div class="inner-up">
                <div class="title-name">
                    Frequency
                </div>
                <div *ngIf="!showAdvanceSettings && !isInformatica" class="drop-down-selection">
                    <cygov-select [items]="monthList" placeholder="Duration" [searchable]="false" [customArrow]="true"
                        [clearable]="false" (ngModelValueChange)="selectFrequency($event)"
                        [ngModelValue]="assessmentData.frequencyName">
                    </cygov-select>

                </div>
                <div class="re-assess-duration" *ngIf="showAdvanceSettings || isInformatica">
                    <div class="lato-14-n-vw re-assess-text">
                        Re-assess every
                    </div>
                    <div class="decrementor" cygovSvgIcon [svgName]="'arrow-down-type1'" (click)="decrementor()"></div>
                    <!-- <div class="lato-14-n-vw frequency-count ">{{assessmentData?.frequencyDays}}
                    </div> -->
                    <input type="number" (focusout)="focusOutHandlerInput($event)" [(ngModel)]="assessmentData.frequencyDays"
                        class="lato-14-n-vw frequency-count"  [max]="maxFrequencyDays" [min]="1" (keyup)="focusOutHandlerInput($event)">
                    <div class="incrementor" cygovSvgIcon [svgName]="'arrow-up-type1'"
                        (click)="incrementorFrequencyDate()">
                    </div>
                    <div class="lato-14-n-vw days-text">
                        days
                    </div>
                    <div class="lato-18-n-vw frequency-circle" *ngIf="showAdvanceSettings"
                        (click)="showAdvanceSettings = false;selectFrequency(assessmentData.frequencyName = 'Annually')">
                        x</div>
                </div>
                <div *ngFor="let checker of frequencyOpt;let idc=index" class="check-selection">
                    <div class="selection-box" (click)="checkboxSelection(checker)">
                        <div *ngIf="checker.checked" class="selected-tick" cygovSvgIcon [svgName]="'right-tick'"></div>
                    </div>
                    <div class="lato-14-n-vw txt-white">
                        {{checker.name}}
                    </div>
                </div>
                <div class="re-assess-duration" *ngIf="showAdvanceSettings || isInformatica">
                    <div class="lato-14-n-vw re-assess-text">
                        start
                    </div>
                    <div class="decrementor" cygovSvgIcon [svgName]="'arrow-down-type1'"
                        (click)="decrementorReviewDate()"></div>
                    <input type="number" (focusout)="focusOutHandler($event)" [(ngModel)]="assessmentData.nextFrequencyDate"
                        class="lato-14-n-vw frequency-count" [max]="assessmentData.frequencyDays" [min]="1" (keyup)="focusOutHandler($event)"/>
                    <div class="incrementor" cygovSvgIcon [svgName]="'arrow-up-type1'"
                        (click)="incrementorReviewDate()">
                    </div>
                    <div class="lato-14-n-vw days-text">
                        days before frequency and date anniversary
                    </div>
                </div>
            </div>
            <div class="horizs-seprator" *ngIf="isInformatica"></div>
            <div class="inner-low-parent">
                <div class="inner-low">
                    <div class="title-name">
                        {{isInformatica ? 'Mandatory Artifact' : 'Artifacts' }} <div class="info-icon" cygovSvgIcon
                            [svgName]="'info-board-icon'" (click)="showArtifactsToolTip=false">
                        </div>
                    </div>
                    <div class="artifacts-option-list">
                        <div class="round-checkbox" *ngFor="let artOpt of artifactOptions;let idz=index">
                            <input [id]="'label-' + artOpt.label" type="radio" [name]="'label-' + artOpt.label"
                                [value]="artOpt.value" [checked]="assessmentData.artifactOption === artOpt.value"
                                #surveyRadio (change)="updateAssessmentData('artifactOption', artOpt.value)"
                                class="jarl-radio" />
                            <label [for]="'label-' + artOpt.label" class="lato-14-n-vw label target">{{artOpt.label |
                                capitalizeFirst}}</label>
                        </div>
                    </div>
                </div>


                <!-- <div class="inner-low ">
                    <div class="title-name comments-heading">
                        Comments <div class="info-icon" cygovSvgIcon [svgName]="'info-board-icon'"
                            (click)="showArtifactsToolTip=false">
                        </div>
                    </div>
                    <div class="artifacts-option-list">
                        <div class="round-checkbox"
                            *ngFor="let commentOpt of commentOptions;let idz=index">
                            <input [id]="'clabel-' + commentOpt.label" type="radio"
                                [name]="'clabel-' + commentOpt.label" [value]="commentOpt.value"
                                [checked]="assessmentData.commentOption === commentOpt.value"
                                #surveyRadio
                                (change)="updateAssessmentData('commentOption', commentOpt?.value)"
                                class="jarl-radio" />
                            <label [for]="'clabel-' + commentOpt.label"
                                class="lato-14-n-vw label target">{{commentOpt?.label |
                                capitalizeFirst}}</label>
                        </div>
                    </div>
                </div> -->

                <div class="inner-low-tooltip" [ngStyle]="showArtifactsToolTip?{display:'none'}:null">
                    <div class="close-icon" (click)="showArtifactsToolTip=true" cygovSvgIcon [svgName]="'x-icon-type2'">
                    </div>
                    <div class="text-row">
                        <span class="lato-12-b-vw txt-white">Suggested - </span> Recommended
                        questions
                        to
                        include
                        mandatory artifacts
                    </div>
                    <!-- <div class="text-row">
                        <span class="lato-12-b-vw txt-white">Mandatory - </span> All questions to
                        have
                        mandatory
                        artifacts
                    </div> -->
                    <div class="text-row">
                        <span class="lato-12-b-vw txt-white">Optional - </span> No questions will
                        have
                        mandatory
                        artifacts
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>